
















































































import Vue from "vue";

import AddBuyoutSuccess from "./AddBuyoutSuccess.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import EasyCounter from "@/components/common/EasyCounter.vue";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";

import { createRequest } from "../../services/api-connector";
import eventsRegister from "@/services/events-register";
import {
  getMediaLink,
  getOrderRequestData,
  Order,
  logEventFA,
  openExternal,
} from "../../utils/utils";
import { mapGetters, mapMutations } from "vuex";

const requestData = (orderId: number) => ({
  ClassList: { Class_id: 24640, TypeLoad: 0, needLinkObject: 1 },
  Filter: { Property_id: -1, Operator_id: "=", Value: orderId },
  PropertyList: [
    { Property_id: -600 },
    { Property_id: -235 },
    { Property_id: 256 },
    { Property_id: 275 },
    { Property_id: 433 },
    { Property_id: 444 },
    { Property_id: 445 },
    { Property_id: 446 },
    { Property_id: 451 },
    { Property_id: 474 },
    { Property_id: 605 },
    { Property_id: 520 },
  ],
});

export default Vue.extend({
  name: "AddBuyoutShared",
  components: {
    AddBuyoutSuccess,
    EasyInput,
    EasyCounter,
    EasyBottomSheet,
  },
  data() {
    return {
      isCreated: false,
      isLoading: false,
      isOpened: false,
      isBottomSheetOpened: false,
      order: {
        name: "",
        source: null,
        photo: null,
        count: 1,
        country: null,
        size: null,
        color: null,
        model: null,
        type: null,
      } as Order,
    };
  },
  mounted() {
    this.$watch(
      "sharedProductId",
      (newId: number) => {
        if (!newId) return false;
        this.loadSharedOrder(newId);
      },
      { deep: true, immediate: true }
    );
  },
  computed: {
    ...mapGetters("user", ["userId"]),
    ...mapGetters("root", ["sharedProductId"]),
    orderPhoto(): string {
      return getMediaLink(this.order.photo);
    },
    isScrollLocked(): boolean {
      if (document.body.classList.contains("web")) return false;
      return this.isOpened;
    },
  },
  methods: {
    ...mapMutations("root", ["setSharedProductId"]),
    onClose() {
      this.isOpened = false;
      this.setSharedProductId(null);
    },
    openLink() {
      this.order.source && openExternal(this.order.source);
    },
    loadSharedOrder(orderId: number) {
      if (!orderId) return false;

      createRequest("objList", requestData(orderId), (res: any) => {
        if (!res.N1024640__1) {
          this.onError("Товар не найден или устарел!");
          return false;
        }
        const sharedOrder = res.N1024640__1[0];
        this.order = {
          ...this.order,
          name: sharedOrder.pr474,
          source: sharedOrder.pr451,
          photo: sharedOrder.pr_600,
          count: sharedOrder.pr256,
          country: sharedOrder.pr275,
          color: sharedOrder.pr445,
          size: sharedOrder.pr446,
          model: sharedOrder.pr444,
          type: sharedOrder.pr520,
        };
        logEventFA("view_product_link", {
          group_id: sharedOrder.pr474,
          customer_id: this.userId,
        });
        this.isOpened = true;
      });
    },
    onError(message: string) {
      this.$root.$emit(eventsRegister.addMessage, {
        type: "error",
        message,
      });
    },
    toggleBottomSheet(val: boolean) {
      this.isBottomSheetOpened = val;
    },

    onSubmit() {
      this.toggleBottomSheet(false);
      this.createOrder();
      logEventFA("want_product", {
        group_id: this.order.name,
        customer_id: this.userId,
      });
    },

    reset() {
      this.order = {
        name: null,
        source: null,
        photo: null,
        count: 1,
        country: 287,
        size: null,
        color: null,
        model: null,
        type: null,
      };
      this.isOpened = false;
      this.isCreated = false;
      this.setSharedProductId(null);
    },

    createOrder() {
      this.isLoading = true;
      const data = getOrderRequestData({
        ...this.order,
        mainImg: this.order.photo,
        customerId: this.userId,
        isLink: this.order.type === 11599,
      });

      createRequest(
        "objEdit",
        data,
        () => {
          this.$root.$emit(eventsRegister.updateParcels);
          this.isCreated = true;
          this.isLoading = false;
          //   this.sendSuccessEvents();
        },
        () => (this.isLoading = false)
      );
    },
  },
});
