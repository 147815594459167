<template>
  <transition name="fade">
    <div v-if="isOpened" class="easy-notify-popup">
      <div class="easy-notify-popup__overlay"></div>
      <div class="easy-push-popup__wrapper">
        <div class="easy-notify-popup__content">
          <img
            v-if="image"
            class="easy-notify-popup__img"
            :src="require(`@/assets/img/${image || 'notify-popup-base.svg'}`)"
          />
          <div class="easy-notify-popup__title">{{ title }}</div>
          <div class="easy-notify-popup__text">{{ message }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NotificationPopup",
  data() {
    return {
      isOpened: false,
      title: "Спасибо",
      message: "Завдяки Вам ми стаємо краще!",
      image: "notify-popup-base.svg",
    };
  },
  created() {
    this.$root.$on("notify-popup", (msg) => this.initPopup(msg));
  },
  methods: {
    initPopup({ title, message, image = "" }) {
      this.isOpened = true;
      this.title = title;
      this.message = message;
      this.image = image;

      console.log("INIT POPU");

      setTimeout(() => this.closePopup(), 1000);
    },
    closePopup() {
      this.isOpened = false;
      this.title = "";
      this.message = "";
      this.image = "";
    },
  },
};
</script>

<style lang="scss">
.easy-notify-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(40, 40, 40, 0.8);
}
.easy-notify-popup__overlay {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.easy-notify-popup__wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.easy-notify-popup__content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 18px;
  padding-top: 26px;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  max-width: 216px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;

  &:after {
    display: block;
    content: "";
    height: 3px;
    width: 100%;
    background: #91c258;
    position: absolute;
    left: 0px;
    bottom: 0px;
    animation: finalline 2s ease-in;
    animation-fill-mode: forwards;
    transform-origin: 0 0;
  }
}
.easy-notify-popup__title {
  font-size: 16px;
  color: #282828;
  line-height: 1.3;
  font-family: "Helvetica Neue Medium";
  margin-bottom: 10px;
}
.easy-notify-popup__text {
  font-size: 14px;
  color: #696969;
  line-height: 1.3;
  max-width: 140px;
  margin: 0 auto;
}
.easy-notify-popup__img {
  margin-bottom: 20px;
}

@keyframes finalline {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
</style>
