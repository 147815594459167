<template>
  <div
    id="appContainer"
    class="appContainer"
    @keyup.enter="hideKeyboard"
    v-bind:class="{
      lockScroll: isPopupActive,
    }"
  >
    <div class="mainContent" v-bind:class="`${$route.name}`.toLowerCase()">
      <div v-if="isInitDataError" class="errorPage">
        <h2>{{ $t("somethingWentWrong") }}</h2>
        <p>{{ $t("pleaseRefreshPage") }}.</p>

        <div class="alignCenter">
          <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded">
            LogOut
          </button>
        </div>
      </div>
      <preloader-screen v-else-if="isBaseDataLoading" />
      <onboarding v-else-if="!isAppGuideActive" />
      <div v-else>
        <AppLayout><router-view /></AppLayout>
      </div>
    </div>
    <shared />
  </div>
</template>

<script>
import Vue from "vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { setAppState } from "@/services/data-mutator";
import appState from "@/store/appstate";
import {
  createRequest,
  getRequestAuthHeader,
  setVueRoot,
} from "@/services/api-connector";
import eventsRegister from "@/services/events-register";
import { HubConnectionBuilder, HttpTransportType } from "@aspnet/signalr";
import PreloaderScreen from "@/components/PreloaderScreen.vue";
import Shared from "@/components/Shared.vue";
import Onboarding from "@/views/Onboarding/Onboarding.vue";
import { getParameterByName } from "@/utils/utils";

export default Vue.extend({
  name: "HomePage",
  components: {
    PreloaderScreen,
    Shared,
    Onboarding,
  },
  data() {
    return {
      appState: appState,

      isInitDataError: false,

      refferalCode: window.localStorage.getItem("refferalCode") || "",

      messageTimer: 0,

      isBaseDataLoading: true,

      isAfterSignUp: false,
    };
  },
  async created() {
    await this.loadAppData();
    this.checkDataFromURL();
    this.getFirebaseToken();

    this.setBaseApp();
    document.addEventListener(
      "deviceready",
      () => {
        document.addEventListener(
          "resume",
          () => {
            // isAppActive = true;
            if (this.isLoggedIn) this.updateLastUpdates();
          },
          false
        );

        // Backbutton event (Close popup or prevPage or exit app)
        document.addEventListener("backbutton", () => {
          // TODO?: close popupSlide on backbutton
          if (this.isPopupActive) this.$root.$emit(eventsRegister.closePopup);
          else if (this.$route.name !== "Root") this.$router.back();
        });

        this.getFirebaseToken();

        const { platformId } = window.cordova;
        if (platformId === "ios") {
          this.checkNotificationPermissions();
        }
        this.checkDynamicLinks();
        // this.checkPayments();
        this.setupOpenwith();
      },
      false
    );

    // enable only on dev
    if (this.isLoggedIn) {
      this.connectWS(); // connect to websockets events api
    }
    // Update appData global event
    this.$root.$on(eventsRegister.updateUserData, this.updateUserData);
    this.$root.$on(eventsRegister.updateParcels, (callback = () => true) =>
      this.updateParcels(callback)
    );
    this.$root.$on("updateParcelsArchive", () => {
      this.updateParcelsArchive();
    });
    this.$root.$on(eventsRegister.updateSavedCards, (callback = () => true) => {
      this.updateSavedCards(callback);
    });
    this.$root.$on(eventsRegister.updateLastUpdates, this.updateLastUpdates);
    this.$root.$on("updateLastUpdatesArchive", this.updateLastUpdatesArchive);
  },
  mounted() {
    // Hide welcome screen
    document.body.classList.add("appLoaded");

    // Dev service info
    if (this.appState.isDebug) {
      console.log("SPA version: " + this.appVersion);
    }
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "logList", "user", "userId"]),
    ...mapGetters("root", [
      "isPopupActive",
      "appVersion",
      "isAppGuideActive",
      "openMessages",
      "refferalRate",
    ]),
  },
  methods: {
    ...mapMutations("parcels", ["setParcelList"]),
    ...mapMutations("root", [
      "setOnline",
      "setDeliveryRates",
      "setCanMakePayments",
      "addOpenMessage",
      "setPushNotification",
      "setSharedProductId",
      "setSharedShopId",
    ]),
    ...mapMutations("user", [
      "setUser",
      "setMoveMoneyList",
      "setLogList",
      "setCurrencyRates",
      "setRefferalCode",
    ]),
    ...mapActions("root", [
      "getRates",
      "getSettings",
      "loadRatesData",
      "loadRefferalTariffs",
      "getUserDataByIP",
    ]),
    ...mapActions("parcels", [
      "loadInnerPackages",
      "loadParcelList",
      "loadArchiveParcelList",
      "loadSharedParcelList",
    ]),
    ...mapActions("address", ["getAddressList"]),
    ...mapActions("user", [
      "loadUserData",
      "loadLastUpdates",
      "loadArchiveLastUpdates",
    ]),
    ...mapActions("cards", ["loadSavedCards"]),
    async loadAppData() {
      // Load dashboard init data from server and update original data

      await Promise.all([
        this.loadUserData(),
        this.updateParcels(),
        this.updateLastUpdates(),
        this.getAddressList(),
      ]);

      this.isBaseDataLoading = false;
      this.showOpenMessages();
      // await this.loadInnerPackages();
      //@ts-ignore
      await Promise.all([
        this.updateSavedCards(),
        this.loadArchiveParcelList(),
        this.loadSharedParcelList(),
        this.loadInnerPackages(),
        this.getRates(),
        this.getSettings(),
        this.loadRatesData(),
        this.loadRefferalTariffs(),
      ]);
    },
    showOpenMessages(isIntervalShow = false) {
      let counter = 0;
      const openMessage = [];
      for (let i = this.logList.length - 1; i >= 0; i--) {
        if (
          this.logList[i].F === 6 &&
          this.logList[i].o[0].State_id !== 5003 &&
          this.logList[i].o[0].State_id !== null
        ) {
          // message from manager not closed
          if (isIntervalShow && this.openMessages.length)
            return this.openMessages.length;
          openMessage.push(this.logList[i].OBJECT_ID);
          this.$root.$emit(eventsRegister.showPopup, {
            body: {
              objId: this.logList[i].OBJECT_ID,
              type: this.logList[i].T,
            },
            header: "",
            type: "message",
          });
          counter++;
        }
      }
      if (openMessage.length) {
        this.addOpenMessage(openMessage);
      }
      if (counter) {
        this.intervalShowMessages();
      }
      return counter;
    },

    intervalShowMessages() {
      this.messageTimer = setInterval(() => {
        const counter = this.showOpenMessages(true);
        if (counter === 0) {
          clearInterval(this.messageTimer);
          this.messageTimer = 0;
        }
      }, 600000); // repeat show 10 min
    },
    async updateUserData(callback = () => true) {
      await this.loadUserData();
      callback();
    },
    async updateLastUpdates() {
      await this.loadLastUpdates();
    },
    async updateLastUpdatesArchive() {
      await this.loadArchiveLastUpdates();
      // Load LastUpdates data from server and update original data
      // createRequest(
      //   "lastUpdatesArchive",
      //   null,
      //   (data) => {
      //     // Success callback function
      //     if (appState.isUserAuth) setOneAppData(data);
      //     // Clear all notifications
      //     this.$root.$emit(eventsRegister.clearMessages);
      //   },
      //   () => true
      // );
    },
    async updateParcels(callback = () => true) {
      await this.loadParcelList();
      callback();
    },
    async updateParcelsArchive() {
      await this.loadArchiveParcelList();
    },
    async updateSavedCards(callback = () => true) {
      await this.loadSavedCards();
      callback();
    },
    // SignalR websockets
    async connectWS() {
      // Test messages https://dev-my.utec-l.com:9011/api/receiving/easyget/message/broadcast
      const webSocketConn = new HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_SOCKET_URL, {
          skipNegotiation: true,
          // useDefaultUrl: false,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: getRequestAuthHeader,
        })
        .build();
      // .configureLogging(LogLevel.Information)

      setAppState("webSocketConn", webSocketConn);

      // -11390  Сообщения -10058 - Виды events message
      // 11563   Лог обработки выкупов   -10058 - Виды events shopping
      // 11564   Лог обработки посылок   -10058 - Виды events packages
      // 11565   Лог денежных движений   -10058 - Виды events finance
      // 11763  Начисление рефералу  -10058 - Виды events finance
      // 11764  Начисление аффилиату  -10058 - Виды events finance
      // 11566   локальная доставка
      // 11570   Пополнение баланса
      // 11571   Появление фотографии
      webSocketConn.on("Event", (dataStr) => {
        // {"ID":359,"TypeId":11570,"Data":null}
        // {"ID":358,"TypeId":-11390,"Data":{"Message":"Тестовое сообщение"}}
        // {"ID":358,"TypeId":-11390,"Data":{"Message":"Тестовое сообщение", "Title": "title"}}
        const dataAll = JSON.parse(dataStr);
        const msgType = dataAll["TypeId"];
        // let title = dataAll.Data.Title

        console.log("EVENT FROM SOCKET", dataAll);

        if (msgType === 11778) {
          this.setPushNotification({
            type: "inner",
            title: dataAll.Data.Title,
            body: dataAll.Data.Message,
            ...dataAll.Data.DynamicLink,
          });
        } else if (msgType === -11390) {
          // show system message or app message
          // this.toastLink = ""; // reset prev msgLink
          // this.toastAction = ""; // reset prev msgLink

          // const msgAll = dataAll.Data.Message;
          // if (appState.platform === 'ios') { // show notification in app. iOS only
          // if (isAppActive) {
          //   // show notification in app active app
          //   // check link in message (https://)
          //   // varn: link must be in end of message. TODO: parse link until space symbol ' '
          //   const linkStartIdx = msgAll.indexOf("https:");
          //   if (linkStartIdx >= 0) {
          //     const msgText = msgAll.substring(0, linkStartIdx - 1);
          //     this.toastMsg = msgText;
          //     this.toastLink = msgAll.substring(linkStartIdx);
          //   } else this.toastMsg = msgAll;
          //   // Auto close by timer success messages
          //   clearTimeout(toastMsgTimer);

          //   toastMsgTimer = setTimeout(() => {
          //     this.toastMsg = "";
          //   }, 12000);
          // }
          // } else if (isAppActive && appState.platform === 'android' && window['cordova']) { // show system notification if app active at android
          // // If app NOT active (app in background) - android shows FirebaseNotification
          //     window['cordova'].exec(
          //         (token: string)=>true,
          //         (err: any)=>{ console.error('Error show notification: '+ err) },
          //         "SystemNotification",
          //         "showNotification",
          //         [ msgAll ]
          //     )
          // }
          //         // system notification in background mode work only for android
          //         cordova.plugins.notification.local.schedule({
          //             icon: appState.appPath+'img/logomin.png',
          //             title: 'EasyGet message',
          //             text: msgAll,
          //             foreground: true
          //         })

          // send callback message
          /* 0 - по умолчанию, 1 - доставлено, 2 - прочитано */
          webSocketConn
            .invoke(
              "EventAnswerback",
              JSON.stringify([{ ID: dataAll["ID"], Status: 1 }])
            )
            .catch((err) => {
              return console.error(err.toString());
            });
        } else if (msgType === 11586) {
          // notification with action (show settings)
          // this.toastMsg = "";
          // this.toastLink = ""; // reset prev msgLink
          // this.toastAction = ""; // reset prev msgLink
          // // if (isAppActive || appState.platform === 'ios') { // show notification in app (if app active)
          // // check link in message (https://)
          // this.toastMsg = `${this.$t("notificationChannelsSettings")}`;
          // this.toastAction = "settings";
          // // Auto close by timer success messages
          // clearTimeout(toastMsgTimer);
          // toastMsgTimer = setTimeout(() => {
          //   this.toastMsg = "";
          // }, 12000);
          // } else { // show system notification (if app NOT active)
          //     if (cordova) {
          //         // system notification in background mode work only for android
          //         cordova.plugins.notification.local.schedule({
          //             icon: appState.appPath+'img/logomin.png',
          //             title: 'EasyGet message',
          //             text: lcl.notificationChannelsSettings,
          //             foreground: true
          //         })
          //     } else console.log('Cordova not exist')
          // }
        } else if (msgType === 11763 || msgType === 11764) {
          if (msgType === 11763)
            this.$root.$emit(eventsRegister.showPopup, {
              body: {
                bonusType: 11760,
                value: this.refferalRate,
              },
              header: "",
              type: "bonus",
            });
          if (msgType === 11764)
            this.$root.$emit(eventsRegister.showPopup, {
              body: {
                bonusType: 11761,
                value: this.refferalRate,
              },
              header: "",
              type: "bonus",
            });
        } else if (msgType === 11563) {
          // shopping changed
          // open popupShopping
          if (dataAll["Object_id"])
            this.$root.$emit(eventsRegister.showPopup, {
              body: dataAll["Object_id"],
              header: this.$t("shoppingPu") + " " + dataAll["Object_id"],
              type: "shopping",
            });

          this.updateUserData();
          this.updateLastUpdates();
        } else if (msgType === 11564) {
          // package changed
          // open parcelPopup or CargoPopup
          if (dataAll["Det_id"])
            // open CargoPopup
            this.$root.$emit(eventsRegister.showPopup, {
              body: dataAll["Object_id"],
              header: this.$t("parcelPu") + " " + dataAll["Object_id"],
              type: "parcel",
            });
          else if (dataAll["Object_id"])
            // open parcelPopup
            this.$root.$emit(eventsRegister.showPopup, {
              body: dataAll["Object_id"],
              header: this.$t("parcelPu") + " " + dataAll["Object_id"],
              type: "parcel",
            });

          this.updateUserData();
          this.updateLastUpdates();
        } else if (msgType === 11570) {
          // AddFunds = 11570 Пополнение баланса
          this.updateLastUpdates();
          this.$root.$emit(eventsRegister.balanceChanged);
        } else if (msgType === 11646) {
          // Messsage from manager
          // {"type":1,"target":"Event","arguments":["{\"ID\":18195,\"TypeId\":11646,\"ObjectId\":2545993,\"DetId\":null,\"Data\":{\"Message\":\"Test! Сообщение от менеджера (форма)\"}}"]}
          // Data:
          //     Message: "test"
          //     __proto__: Object
          // DetId: 2828571
          // ID: 20013
          // ObjectId: 2828607
          // TypeId: 11646
          if (dataAll["ObjectId"]) {
            let orderStr = "";
            if (dataAll["ClassId"] === 24640) orderStr = "shopping";
            else if (dataAll["ClassId"] === 24800) orderStr = "packages";

            this.addOpenMessage([dataAll.ObjectId]);
            clearInterval(this.messageTimer);
            this.intervalShowMessages();
            this.$root.$emit(eventsRegister.showPopup, {
              body: { objId: dataAll.ObjectId, type: orderStr },
              header: "",
              type: "message",
            });
            this.updateLastUpdates();
          }
        } else if (msgType === 11756) {
          // test message
          const msg = dataAll.Data.Message || dataAll.Data;
          this.$root.$emit(eventsRegister.addMessage, {
            type: "success",
            message: msg,
          });
        }
        // } else if (msgType === 11565 || msgType === 11570) { // finance

        // {"TypeId": 11568} // успешно
        // {"TypeId": 11569} // не успешно
        // } else if (msgType === 11566) { // local delivery event

        // } else if (msgType === 11571) { // photo changed
      });

      webSocketConn.on("error", (data) => {
        console.error(data);
      });

      let isAuthorized = true;
      // Unauthorized. Token is not valid
      webSocketConn.on("ErrorConnected", (data) => {
        isAuthorized = false;
        console.error(data);
      });

      async function start() {
        try {
          await webSocketConn.start();
          console.log("SignalR Connected.");
        } catch (err) {
          console.log(err);
          setTimeout(start, 5000);
        }
      }

      webSocketConn.onclose(() => {
        // this.connWSState = 'error'
        // reconnect if autorization success
        if (isAuthorized) this.reconnectWS();
      });

      // Start the connection.
      start();
    },
    reconnectWS() {
      // try reconnect webSocket by interval
      if (!this.isLoggedIn) return false; // exit if user logOut
      appState.webSocketConn
        .start()
        .then(() => {
          // this.onWSConnected() // update data on reconect
        })
        .catch(() => {
          setTimeout(() => {
            if (!this.isLoggedIn) return false; // exit if user logOut
            this.reconnectWS();
          }, 1000); // ms
        });
    },
    setBaseApp() {
      setVueRoot(this.$root);
      const filePath = window.location.href;
      const appPath = filePath.replace("index.html", "");
      setAppState("appPath", appPath);
      if (document.body.classList.contains("ios"))
        setAppState("platform", "ios");
    },
    checkDataFromURL() {
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);

      const refferal = params.get("refferal");
      const sharedProductId = params.get("product");
      const sharedShopId = params.get("sharedShopId");

      if (refferal) {
        window.localStorage.setItem("refferalCode", refferal);
        this.setRefferalCode(refferal);
      }
      if (sharedProductId) this.setSharedProductId(sharedProductId);
      if (sharedShopId) {
        this.setSharedShopId(sharedShopId);
        this.$router.push("shops");
      }
    },
    checkDynamicLinks() {
      const onHandleLink = (data) => {
        if (data) {
          const sharedProductId = getParameterByName("product", data.deepLink);
          const sharedShopId = getParameterByName(
            "sharedShopId",
            data.deepLink
          );

          if (sharedProductId) this.setSharedProductId(sharedProductId);
          if (sharedShopId) {
            this.setSharedShopId(sharedShopId);
            this.$router.push("shops");
          }
        } else {
          console.log("App wasn't started from a dynamic link");
        }
      };
      const dynamiclinks = window["cordova"].plugins.firebase.dynamiclinks;
      if (dynamiclinks) {
        dynamiclinks
          .getDynamicLink()
          .then((data) => onHandleLink(data, "from getDynamicLink"));
        dynamiclinks.onDynamicLink((data) =>
          onHandleLink(data, "from onDynamicLink")
        );
      }
    },

    getFirebaseToken() {
      const ls = window["localStorage"];

      const onSuccess = (token) => {
        ls["tokenFCM"] = token;
        createRequest(
          "tokenFCM",
          { Key: token }, //
          () => true, // success callback
          () => true // error callback
        );
      };

      if (window["cordova"]) {
        window.cordova.plugins.firebase.messaging
          .getToken()
          .then(onSuccess)
          .catch((error) => {
            console.error("Failed to get FCM token", error);
          });
        window.cordova.plugins.firebase.messaging.onTokenRefresh(onSuccess);
      }
    },
    checkNotificationPermissions() {
      const isGot = window.localStorage.getItem("pushIsGot");
      if (!isGot) {
        window.cordova.plugins.firebase.messaging
          .requestPermission()
          .then(() => window.localStorage.setItem("pushIsGot", "true"));
      }
    },
    // async checkPayments() {
    //   const isAvailable = await window.cordova.plugins.ApplePayGooglePay?.canMakePayments();
    //   console.log('setCanMakePayments', isAvailable)
    //   this.setCanMakePayments(isAvailable)
    // },
    setupOpenwith() {
      // cordova-plugin-shared
      // Initialize the plugin
      // window.cordova.openwith.init(
      //   () => console.log("init success!"),
      //   (err) => console.log("init failed: " + err)
      // );
      // // Define your file handler
      // window.cordova.openwith.addHandler((intent) => {
      //   if (intent.items.length && intent.items[0].url) {
      //     setAppState("filledProduct", { source: intent.items[0].url });
      //   }
      //   if (
      //     intent.items.length &&
      //     !intent.items[0].url &&
      //     intent.items[0].data
      //   ) {
      //     setAppState("filledProduct", { source: intent.items[0].data });
      //   }
      // });
    },

    hideKeyboard() {
      document.activeElement.blur();
    },
  },
});
</script>

<style></style>
