




import Vue from "vue";

export default Vue.extend({
  name: "PopupBase",
  props: {
    popupbody: [Object, String, Number] as any,
  },
  data() {
    return {
      textBody: "",
    };
  },

  created() {
    if (typeof this.popupbody === "string") this.textBody = this.popupbody;
  },
});
