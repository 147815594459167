<template>
  <div id="shared-componnents">
    <popup :appdata="appdata"
      :appstate="appState" v-on:popupChanged="popupChanged" />

    <update-version-popup
      v-if="lastVersion"
      :lastVersion="lastVersion"
    />

    <add-buyout-shared />
    <notification-popup />
    <share-product-popup />

    <payment :appdata="appdata" :appstate="appState" />

    <transition name="fade">
      <offline-page v-if="!isOnline" />
    </transition>

    <transition name="fade">
      <connection-error v-if="isConnectionError" />
    </transition>

    <dialoq-box />
  </div>
</template>

<script>
import Popup from "@/components/popups/Popup.vue";
import DialoqBox from "@/components/DialoqBox.vue";
import Payment from "@/components/Payment.vue";
import AddBuyoutShared from "@/views/AddBuyout/AddBuyoutShared.vue";
import UpdateVersionPopup from "@/components/UpdateVersionPopup.vue";
import ConnectionError from "@/components/ConnectionError.vue";
import OfflinePage from "@/components/OfflinePage.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
import ShareProductPopup from "@/components/ShareProductPopup.vue";
import { mapGetters } from "vuex";
import appData from "@/store/appdata";
import appState from "@/store/appstate";
import { setAppState } from "@/services/data-mutator";

export default {
  name: "Shared",
  components: {
    Popup,
    DialoqBox,
    Payment,
    AddBuyoutShared,
    UpdateVersionPopup,
    ConnectionError,
    OfflinePage,
    NotificationPopup,
    ShareProductPopup
  },
  computed: {
    ...mapGetters("root", ["isOnline", "isConnectionError"]),
    ...mapGetters("user", ["lastVersion"]),
  },
  data() {
    return {
      appdata: appData,
      appState,
    };
  },
  methods: {
    popupChanged(isPopupShow) {
      setAppState("isScrollLocked", isPopupShow);
    },
  },
};
</script>

<style>
</style>