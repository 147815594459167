/* eslint-disable */
// Form data validators
// const re = /\S+@\S+\.\S+/ // simple email
export const regexMailUTF8 = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
export const regexPhone = /\(?([0-9]{2})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/ // 11 222 3333

export function isFilled(val: any): boolean {
    if (val === '' || val === -1) return false
    return true
}
export function isFilledIntPositive(val: any): boolean {
    if (val === '' || val < 1 || val === 0) return false
    return true
}
export function isInt(val: any): boolean {
    if ( /^[0-9]+$/.test(val) ) return true
    return false
}
export function isIntOrFloat(val: any): boolean {
    if ( /^[0-9,.]+$/.test(val) ) return true
    return false
}
export function isPhoneCorrect(val: any): boolean {
    if ( val.match(regexPhone) ) return true
    return false
}
export function isTextCyrillic(text: any): boolean {
    let textCorrectChars = text.match(/[А-я,Ё,ё,\s]/g) // Cirilic or space chars
    if (textCorrectChars && text.length === textCorrectChars.length) return true
    return false
}