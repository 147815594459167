<template>
  <transition name="fade">
    <div v-if="isOpened" class="share-product-popup">
      <div class="share-product-popup__overlay"></div>
      <div class="share-product-popup__wrapper">
        <!-- FIRST VARIANT -->
        <div
          v-if="sharedGoods <= 2"
          class="share-product-popup__content"
        >
          <div class="share-product-popup__img">
            <lottie :options="defaultOptions" :height="153" :width="144" />
          </div>
          <div class="share-product-popup__title">
            {{ $t("shareFirstPopupTitle") }}
          </div>
          <div class="share-product-popup__text text-top">
            {{ $t("shareFirstPopupText") }}
          </div>
          <div class="share-product-popup__product">
            <div class="share-product-popup__product-img">
              <img :src="parcelData.photo" />
            </div>
            <div class="share-product-popup__product-name">
              {{ parcelData.name }}
            </div>
          </div>
          <div class="share-product-popup__text">
            {{ $t("shareFirstPopupTextPlus") }}
          </div>
          <div class="share-product-popup__agreement">
            <div class="share-product-popup__agreement-checkbox">
              <input
                id="share-product"
                type="checkbox"
                v-model="isWholeAgree"
              />
              <span></span>
              <label
                class="share-product-popup__agreement-label"
                for="share-product"
                >{{ $t("shareFirstPopupShareAll") }}</label>
            </div>
          </div>
          <button class="btnWideRound" :class="{ isLoading }" @click="onSubmit">
            {{$t('shareBtnAgree')}}
          </button>
          <button @click="onClose" class="share-product-popup__dismiss">
            {{$t('shareBtnDismiss')}}
          </button>
        </div>
        <!-- FIRST VARIANT -->

        <!-- SECCOND VARIANT -->
        <div v-else class="share-product-popup__content">
          <div class="share-product-popup__img">
            <lottie :options="defaultOptions" :height="153" :width="144" />
          </div>
          <div class="share-product-popup__title">{{$t('shareSecondPopupTitle')}}</div>
          <div class="share-product-popup__text text-top">
            {{$t('shareSecondPopupText')}}
          </div>
          <div class="share-product-popup__text second-text">
            {{$t('shareSecondPopupTextPlus')}}
          </div>
          <div class="share-product-popup__preview">
            <img src="@/assets/img/share-ui.png" />
          </div>
          <button @click="onFinal(true)" class="btnWideRound">{{$t('shareBtnAgree')}}</button>
          <button @click="onFinal(false)" class="share-product-popup__dismiss">
            {{$t('shareBtnDismiss')}}
          </button>
        </div>
        <!-- SECCOND VARIANT -->
      </div>
    </div>
  </transition>
</template>

<script>
import { getMediaLink } from "@/utils/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Lottie from "vue-lottie";
import animationData from "@/assets/animation/share.json";

export default {
  name: "ShareProductPopup",
  components: {
    Lottie,
  },
  data() {
    return {
      isOpened: false,
      isWholeAgree: false,
      isLoading: false,
      parcelId: null,
      defaultOptions: { animationData: animationData, loop: false },
    };
  },
  created() {
    this.$root.$on("shared-popup", (id) => this.initPopup(id));
  },
  computed: {
    ...mapGetters("parcels", ["parcelById"]),
    ...mapGetters("user", ["isAgreeShare", "sharedGoods"]),
    parcelData() {
      const parcel = this.parcelById(this.parcelId);
      return {
        name: parcel.pr474 || parcel.pr331 || this.$t("needDescriptionPC"),
        photo: getMediaLink(parcel.pr_600 || parcel.PathToPhoto || ""),
      };
    },
  },
  methods: {
    ...mapActions("user", ["toggleIsShare"]),
    ...mapActions("parcels", ["toggleShareParcel"]),
    ...mapMutations("user", ["addSharedCount"]),
    initPopup(parcelId) {
      if (this.isAgreeShare) return false;
      this.parcelId = parcelId;
      this.isOpened = true;
    },
    closePopup() {
      this.isOpened = false;
    },
    async onSubmit() {
      if (this.isLoading) return false;
      this.isLoading = true;
      if (this.isWholeAgree) {
        await this.toggleIsShare(true);
      }
      await this.toggleShareParcel({
        id: this.parcelId,
        value: true,
      });
      this.addSharedCount();
      this.isLoading = false;
      this.isOpened = false;
    },
    onClose() {
      this.isOpened = false;
      this.toggleShareParcel(false);
    },
    async onFinal(value) {
      if (this.isLoading) return false;
      this.isLoading = true;
      await this.toggleIsShare(value);
      if (!value) window.localStorage.setItem(`isShareRequestBlocked`, "true");
      this.isLoading = false;
      this.isOpened = false;
    },
  },
};
</script>

<style lang="scss">
.share-product-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(40, 40, 40, 0.8);

  &__dismiss {
    padding-top: 14px;
    padding-bottom: 14px;
    margin-top: 14px;
    background: none;
    box-shadow: none;
    border: none;
    outline: none;
    text-align: center;
    font-size: 16px;
    color: #282828;
  }

  &__preview {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 14px;
    margin-bottom: 20px;
    display: flex;
    justify-self: center;

    img {
      display: block;
      max-width: 100%;
    }
  }
}
.share-product-popup__overlay {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.share-product-popup__wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.share-product-popup__content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 18px;
  padding-top: 26px;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  max-width: 343px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.share-product-popup__title {
  font-size: 24px;
  color: #282828;
  line-height: 1.3;
  font-family: "Helvetica Neue Medium";
  margin-bottom: 10px;
}
.share-product-popup__text {
  font-size: 14px;
  color: #696969;
  line-height: 1.3;
  margin: 0 auto;

  &.text-top {
    max-width: 240px;
  }
  &.second-text {
    margin-top: 24px;
  }
}
.share-product-popup__img {
  margin-bottom: 20px;
  margin-top: -112px;
}

.share-product-popup__agreement {
  display: flex;
  justify-content: center;

  &-label {
    flex: 1 auto;
    color: #696969;
    font-size: 14px;
    line-height: 1.3;
  }

  &-checkbox {
    position: relative;
    padding-left: 40px;
    padding-right: 10px;
    padding-top: 24px;
    padding-bottom: 24px;

    input {
      position: absolute;
      z-index: -99;
      left: -9999px;
      visibility: hidden;
    }

    span {
      position: absolute;
      left: 10px;
      top: -2px;
      bottom: 0px;
      margin: auto;
      width: 15px;
      height: 15px;
    }

    span:after {
      position: absolute;
      left: 0px;
      top: -1px;
      border: 2px solid #bfbfbf;
      border-radius: 2px;
      width: 14px;
      height: 14px;
      content: "";
    }

    span:before {
      position: absolute;
      left: 0px;
      top: -1px;
      border-radius: 2px;
      background: #bfbfbf url("~@/assets/img/i-success.svg") center center
        no-repeat;
      background-size: 8px;
      z-index: 1;
      width: 18px;
      height: 18px;
      content: "";
      transition: 0.3s;
      opacity: 0;
    }

    input:checked ~ span:before {
      opacity: 1;
    }
  }
}

.share-product-popup__product {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-img {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-name {
    font-family: "Helvetica Neue Medium";
    color: #282828;
    line-height: 1;
    margin-top: 8px;
  }
}
</style>