<template>
  <section class="offlineBox">
    <svg
      class="svgParcelOffline"
      width="400"
      height="600"
      viewBox="0 0 400 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="scale(1.08) translate(-6,0)">
        <mask
          id="mask5621"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="400"
          height="600"
        >
          <rect width="400" height="600" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask5621)">
          <path
            d="M68.1358 469.57C68.1358 469.57 25.5 444.57 68.1358 438.675C110.772 432.779 58.7242 406.675 2.72418 417.675C-53.2758 428.675 -62.2759 431.175 -93.2759 425.175C-124.276 419.175 -199 417.675 -199 417.675"
            stroke="#F2DDC2"
            stroke-width="3"
          />
          <path
            d="M55.8003 488.918V476.699H67.1008V481.616L77.8999 475.396C78.7535 474.915 79.7084 474.641 80.6876 474.595C81.6668 474.55 82.6431 474.734 83.5379 475.134L138.38 506.71V501.44H149.697V513.823C149.845 514.536 149.385 515.323 148.349 515.913L127.581 527.87C126.731 528.344 125.78 528.613 124.807 528.654C123.833 528.695 122.864 528.508 121.976 528.107L56.7454 490.549C56.4325 490.415 56.1714 490.184 56.0011 489.89C55.8308 489.596 55.7603 489.255 55.8003 488.918Z"
            fill="#E0CBB0"
          />
          <path
            d="M55.8 476.699H67.1005V481.616L77.8997 475.396C78.7532 474.915 79.7081 474.641 80.6873 474.595C81.6665 474.55 82.6428 474.734 83.5376 475.134L124.491 498.719V528.656C123.619 528.694 122.752 528.505 121.976 528.107L56.7451 490.549C56.4308 490.414 56.1686 490.182 55.9982 489.886C55.8277 489.591 55.7582 489.248 55.8 488.91V476.699Z"
            fill="#CEBBA1"
          />
          <path
            d="M121.975 515.544L56.745 477.985C55.3067 477.166 55.4875 475.715 57.1477 474.757L77.9159 462.767C78.7726 462.299 79.7272 462.038 80.7033 462.004C81.6793 461.97 82.6499 462.164 83.5374 462.57L148.751 500.121C150.19 500.94 150.009 502.399 148.349 503.399L127.581 515.315C126.729 515.787 125.779 516.053 124.805 516.093C123.832 516.133 122.863 515.945 121.975 515.544Z"
            fill="#F7EED7"
          />
          <path
            d="M89.7426 481.616C85.1485 484.271 77.6614 484.255 73.0344 481.616C68.4073 478.977 68.3745 474.642 72.9768 471.995C77.5792 469.348 85.0663 469.356 89.6933 471.995C94.3204 474.634 94.345 479.002 89.7426 481.616Z"
            fill="#CEBBA1"
          />
          <path
            d="M92.5781 479.043C91.8738 480.132 90.8964 481.019 89.7427 481.616C85.1485 484.271 77.6614 484.255 73.0344 481.616C71.8446 480.982 70.846 480.043 70.1415 478.895C70.7778 477.522 71.865 476.406 73.2234 475.732C77.7354 473.192 85.0417 473.273 89.5372 475.912C90.8627 476.602 91.9292 477.7 92.5781 479.043Z"
            fill="#F2DDC2"
          />
          <path
            d="M79.6914 479.666H79.7736C79.8684 479.5 80.0079 479.363 80.1763 479.272C80.5416 479.087 80.9457 478.99 81.3557 478.99C81.7656 478.99 82.1698 479.087 82.535 479.272C82.7045 479.358 82.8446 479.492 82.9377 479.657L83.0281 479.698V480.051C82.9949 480.176 82.9342 480.293 82.8503 480.392C82.7665 480.491 82.6616 480.57 82.5432 480.624C82.178 480.81 81.7739 480.907 81.3639 480.907C80.9539 480.907 80.5498 480.81 80.1845 480.624C80.0669 480.567 79.9628 480.486 79.8792 480.386C79.7956 480.285 79.7344 480.168 79.6996 480.043L79.6914 479.666Z"
            fill="#5D6D89"
          />
          <path
            d="M75.6067 476.429C76.0293 476.214 76.4968 476.102 76.971 476.102C77.4452 476.102 77.9127 476.214 78.3353 476.429C78.506 476.478 78.6559 476.582 78.7626 476.724C78.8693 476.865 78.927 477.038 78.927 477.215C78.927 477.393 78.8693 477.565 78.7626 477.707C78.6559 477.849 78.506 477.952 78.3353 478.002C77.9125 478.216 77.4451 478.327 76.971 478.327C76.4969 478.327 76.0295 478.216 75.6067 478.002C74.8588 477.559 74.8506 476.855 75.6067 476.429Z"
            fill="#494238"
          />
          <path
            d="M84.359 481.477C84.7818 481.263 85.2492 481.151 85.7233 481.151C86.1973 481.151 86.6647 481.263 87.0875 481.477C87.6299 481.788 87.7779 482.231 87.5395 482.624C86.7219 482.908 85.884 483.129 85.0329 483.288C84.7999 483.24 84.5755 483.157 84.3672 483.042C84.1994 482.991 84.0524 482.888 83.9477 482.747C83.8429 482.607 83.7859 482.437 83.785 482.262C83.7841 482.087 83.8393 481.917 83.9426 481.775C84.0458 481.634 84.1917 481.529 84.359 481.477Z"
            fill="#494238"
          />
          <path
            d="M79.9053 479.567C79.9312 479.457 79.9816 479.355 80.0527 479.267C80.1239 479.179 80.2139 479.108 80.3162 479.059C80.6391 478.894 80.9969 478.808 81.3599 478.808C81.723 478.808 82.0808 478.894 82.4037 479.059C82.505 479.11 82.5945 479.182 82.6667 479.27C82.7388 479.357 82.792 479.458 82.8228 479.567C82.831 479.63 82.831 479.693 82.8228 479.756C82.7929 479.864 82.741 479.965 82.6703 480.052C82.5996 480.14 82.5117 480.212 82.4119 480.264C82.0884 480.427 81.7308 480.513 81.3682 480.513C81.0055 480.513 80.6479 480.427 80.3244 480.264C80.2206 480.216 80.1289 480.146 80.0563 480.058C79.9837 479.97 79.9321 479.867 79.9053 479.756C79.8931 479.696 79.8931 479.635 79.9053 479.576V479.567Z"
            fill="#B5BDC6"
          />
          <path
            d="M82.8229 479.567C82.8268 479.614 82.8268 479.66 82.8229 479.707C82.8229 479.707 82.8229 479.707 82.8229 479.756C82.831 479.693 82.831 479.63 82.8229 479.567Z"
            fill="#B5BDC6"
          />
          <path
            d="M84.3597 481.477C84.7825 481.263 85.2499 481.151 85.724 481.151C86.198 481.151 86.6654 481.263 87.0882 481.477C87.2784 481.577 87.4375 481.727 87.5485 481.911C87.4822 481.853 87.4107 481.8 87.3348 481.755C86.9316 481.55 86.4851 481.442 86.0321 481.442C85.5792 481.442 85.1327 481.55 84.7295 481.755C84.072 482.132 84.0309 482.722 84.5816 483.14L84.3679 483.042C84.2001 482.991 84.0531 482.888 83.9484 482.747C83.8436 482.607 83.7866 482.437 83.7857 482.262C83.7848 482.087 83.84 481.917 83.9433 481.775C84.0465 481.634 84.1924 481.529 84.3597 481.477Z"
            fill="#CEBBA1"
          />
          <g mix-blend-mode="multiply" opacity="0.5">
            <path
              d="M83.0194 479.748C82.9943 479.876 82.9386 479.997 82.8571 480.099C82.7756 480.202 82.6706 480.284 82.551 480.338C82.1843 480.523 81.7788 480.62 81.3675 480.62C80.9562 480.62 80.5507 480.523 80.184 480.338C80.065 480.282 79.9603 480.199 79.8778 480.097C79.7952 479.995 79.7369 479.875 79.7073 479.748H83.0194ZM80.3237 480.256C80.6472 480.419 81.0048 480.504 81.3675 480.504C81.7302 480.504 82.0877 480.419 82.4112 480.256C82.511 480.204 82.5989 480.132 82.6696 480.044C82.7403 479.957 82.7922 479.856 82.8222 479.748H79.9046C79.9308 479.859 79.9823 479.962 80.0549 480.05C80.1276 480.138 80.2196 480.209 80.3237 480.256Z"
              fill="#7C8189"
            />
          </g>
          <path
            d="M75.6067 476.429C76.0293 476.214 76.4968 476.102 76.971 476.102C77.4452 476.102 77.9127 476.214 78.3353 476.429C78.5112 476.501 78.6594 476.627 78.7584 476.79C78.8574 476.952 78.9021 477.141 78.8859 477.33C78.7909 477.122 78.6289 476.952 78.4257 476.846C78.0445 476.65 77.6218 476.548 77.1929 476.548C76.764 476.548 76.3413 476.65 75.9601 476.846C75.3848 477.174 75.3109 477.666 75.7136 478.059L75.5903 478.002C74.8588 477.559 74.8506 476.855 75.6067 476.429Z"
            fill="#CEBBA1"
          />
          <path
            d="M80.1845 480.305C80.5512 480.49 80.9567 480.587 81.368 480.587C81.7793 480.587 82.1848 480.49 82.5515 480.305C82.6737 480.251 82.7809 480.169 82.864 480.064C82.9471 479.96 83.0034 479.837 83.0281 479.706C83.0084 479.843 82.9544 479.973 82.8711 480.083C82.7878 480.193 82.6778 480.281 82.5515 480.337C82.1848 480.523 81.7793 480.62 81.368 480.62C80.9567 480.62 80.5512 480.523 80.1845 480.337C80.057 480.278 79.9457 480.189 79.8599 480.078C79.7741 479.967 79.7163 479.836 79.6914 479.698C79.7222 479.83 79.7826 479.953 79.868 480.058C79.9534 480.163 80.0617 480.247 80.1845 480.305Z"
            fill="#B5BDC6"
          />
          <g mix-blend-mode="multiply" opacity="0.4">
            <path
              d="M90.3917 481.247L90.5561 481.133L90.3917 481.247Z"
              fill="#C6AD89"
            />
          </g>
          <path
            d="M102.677 495.86C96.1496 495.825 90.875 492.751 90.8954 488.994C90.9157 485.238 96.2234 482.221 102.75 482.256C109.277 482.292 114.552 485.365 114.532 489.122C114.511 492.879 109.203 495.895 102.677 495.86Z"
            fill="#CEBBA1"
          />
          <path
            d="M113.897 491.27C113.211 492.38 112.244 493.29 111.094 493.909C106.492 496.556 99.0129 496.548 94.3777 493.909C93.1887 493.277 92.1901 492.341 91.4847 491.196C92.1265 489.822 93.2155 488.704 94.5749 488.025C99.0869 485.484 106.393 485.566 110.889 488.213C112.188 488.892 113.241 489.961 113.897 491.27Z"
            fill="#F2DDC2"
          />
          <path
            d="M101.043 491.901H101.125C101.215 491.731 101.356 491.594 101.528 491.507C101.893 491.322 102.297 491.225 102.707 491.225C103.117 491.225 103.521 491.322 103.886 491.507C104.057 491.596 104.197 491.733 104.289 491.901H104.38C104.38 491.901 104.38 492.228 104.38 492.253C104.346 492.377 104.286 492.493 104.204 492.592C104.122 492.691 104.019 492.771 103.903 492.827C103.537 493.01 103.133 493.106 102.724 493.106C102.314 493.106 101.91 493.01 101.544 492.827C101.428 492.77 101.324 492.69 101.241 492.591C101.157 492.492 101.096 492.377 101.059 492.253L101.043 491.901Z"
            fill="#5D6D89"
          />
          <path
            d="M96.9582 488.656C97.381 488.442 97.8484 488.331 98.3225 488.331C98.7965 488.331 99.2639 488.442 99.6867 488.656C99.8542 488.708 100.001 488.812 100.105 488.952C100.209 489.093 100.265 489.264 100.265 489.438C100.265 489.613 100.209 489.784 100.105 489.924C100.001 490.065 99.8542 490.169 99.6867 490.221C99.2641 490.436 98.7967 490.547 98.3225 490.547C97.8483 490.547 97.3808 490.436 96.9582 490.221C96.7907 490.169 96.6442 490.065 96.5402 489.924C96.4362 489.784 96.3801 489.613 96.3801 489.438C96.3801 489.264 96.4362 489.093 96.5402 488.952C96.6442 488.812 96.7907 488.708 96.9582 488.656Z"
            fill="#494238"
          />
          <path
            d="M101.051 491.975C101.047 491.926 101.047 491.876 101.051 491.827C101.082 491.701 101.141 491.583 101.224 491.483C101.306 491.382 101.41 491.301 101.528 491.245C101.895 491.06 102.3 490.963 102.711 490.963C103.123 490.963 103.528 491.06 103.895 491.245C104.011 491.301 104.114 491.381 104.196 491.48C104.278 491.579 104.338 491.695 104.372 491.819C104.38 491.89 104.38 491.961 104.372 492.032C104.339 492.156 104.28 492.271 104.2 492.37C104.119 492.469 104.018 492.549 103.903 492.606C103.535 492.794 103.128 492.891 102.715 492.891C102.303 492.891 101.895 492.794 101.528 492.606C101.41 492.553 101.306 492.474 101.225 492.375C101.144 492.275 101.087 492.158 101.059 492.032C101.059 492.032 101.059 491.975 101.051 491.975Z"
            fill="#6B7E99"
          />
          <path
            d="M105.727 493.712C106.15 493.498 106.618 493.386 107.092 493.386C107.566 493.386 108.033 493.498 108.456 493.712C108.998 494.023 109.146 494.474 108.916 494.859C108.097 495.15 107.256 495.374 106.401 495.531C106.168 495.479 105.944 495.394 105.736 495.277C105.568 495.226 105.421 495.123 105.316 494.983C105.211 494.843 105.154 494.673 105.153 494.498C105.153 494.323 105.208 494.152 105.311 494.011C105.414 493.869 105.56 493.765 105.727 493.712Z"
            fill="#494238"
          />
          <path
            d="M101.249 491.794C101.276 491.684 101.328 491.581 101.4 491.493C101.473 491.405 101.564 491.334 101.668 491.286C101.991 491.123 102.349 491.038 102.711 491.038C103.074 491.038 103.432 491.123 103.755 491.286C103.856 491.337 103.946 491.409 104.018 491.496C104.09 491.584 104.143 491.685 104.174 491.794C104.183 491.854 104.183 491.915 104.174 491.975C104.147 492.085 104.095 492.188 104.023 492.276C103.95 492.364 103.859 492.435 103.755 492.483C103.431 492.644 103.074 492.728 102.711 492.728C102.349 492.728 101.992 492.644 101.668 492.483C101.566 492.431 101.475 492.358 101.403 492.269C101.331 492.18 101.278 492.077 101.249 491.966C101.24 491.907 101.24 491.846 101.249 491.786V491.794Z"
            fill="#B5BDC6"
          />
          <path
            d="M104.174 491.802C104.178 491.846 104.178 491.89 104.174 491.933C104.174 491.933 104.174 491.933 104.174 491.983C104.183 491.923 104.183 491.862 104.174 491.802Z"
            fill="#B5BDC6"
          />
          <path
            d="M105.728 493.712C106.15 493.498 106.618 493.386 107.092 493.386C107.566 493.386 108.034 493.498 108.456 493.712C108.649 493.815 108.811 493.968 108.925 494.155C108.859 494.092 108.784 494.039 108.703 493.999C108.301 493.794 107.856 493.688 107.404 493.688C106.953 493.688 106.508 493.794 106.106 493.999C105.448 494.376 105.399 494.974 105.958 495.384C105.881 495.358 105.807 495.325 105.736 495.285C105.565 495.237 105.414 495.134 105.307 494.993C105.2 494.851 105.141 494.679 105.14 494.502C105.139 494.324 105.196 494.152 105.302 494.009C105.408 493.867 105.557 493.763 105.728 493.712Z"
            fill="#CEBBA1"
          />
          <g mix-blend-mode="multiply" opacity="0.5">
            <path
              d="M104.372 491.974C104.348 492.104 104.293 492.227 104.211 492.331C104.129 492.435 104.024 492.518 103.903 492.573C103.535 492.76 103.128 492.858 102.715 492.858C102.303 492.858 101.895 492.76 101.528 492.573C101.408 492.519 101.303 492.437 101.222 492.334C101.14 492.232 101.085 492.111 101.059 491.983L104.372 491.974ZM101.668 492.491C101.992 492.652 102.349 492.736 102.711 492.736C103.074 492.736 103.431 492.652 103.755 492.491C103.859 492.443 103.95 492.372 104.023 492.284C104.095 492.196 104.147 492.093 104.174 491.983H101.257C101.287 492.091 101.339 492.192 101.409 492.279C101.48 492.367 101.568 492.439 101.668 492.491Z"
              fill="#7C8189"
            />
          </g>
          <path
            d="M96.9588 488.656C97.3816 488.442 97.849 488.331 98.3231 488.331C98.7972 488.331 99.2646 488.442 99.6874 488.656C99.8633 488.728 100.012 488.855 100.111 489.017C100.21 489.179 100.254 489.368 100.238 489.557C100.142 489.345 99.9772 489.172 99.7696 489.066C99.3888 488.868 98.966 488.765 98.5368 488.765C98.1076 488.765 97.6848 488.868 97.304 489.066C96.7287 489.393 96.6547 489.885 97.0575 490.278L96.9342 490.221C96.7675 490.167 96.6226 490.06 96.5207 489.918C96.4189 489.776 96.3654 489.604 96.3682 489.429C96.3709 489.254 96.4298 489.085 96.536 488.946C96.6423 488.807 96.7905 488.705 96.9588 488.656Z"
            fill="#CEBBA1"
          />
          <path
            d="M101.528 492.532C101.895 492.72 102.302 492.818 102.715 492.818C103.128 492.818 103.535 492.72 103.903 492.532C104.024 492.474 104.13 492.39 104.212 492.284C104.295 492.179 104.352 492.056 104.38 491.926C104.361 492.064 104.308 492.195 104.224 492.307C104.141 492.419 104.03 492.507 103.903 492.565C103.535 492.752 103.128 492.85 102.715 492.85C102.302 492.85 101.895 492.752 101.528 492.565C101.399 492.509 101.286 492.42 101.202 492.308C101.117 492.196 101.062 492.065 101.043 491.926C101.072 492.057 101.13 492.18 101.214 492.285C101.298 492.39 101.406 492.475 101.528 492.532Z"
            fill="#B5BDC6"
          />
          <path
            d="M104.372 491.983H101.084C101.08 491.936 101.08 491.89 101.084 491.844H104.372C104.376 491.89 104.376 491.937 104.372 491.983Z"
            fill="#5D6D89"
          />
          <g mix-blend-mode="multiply" opacity="0.4">
            <path
              d="M111.743 493.474L111.899 493.368L111.743 493.474Z"
              fill="#C6AD89"
            />
          </g>
          <path
            d="M127.096 514.733L147.346 503.055C148.834 502.235 148.826 500.793 147.346 499.932L83.8991 463.415C83.0583 462.991 82.1293 462.77 81.187 462.77C80.2447 462.77 79.3157 462.991 78.4749 463.415L58.1915 475.06C56.704 475.88 56.7122 477.314 58.1915 478.182L121.639 514.725C122.484 515.154 123.418 515.378 124.366 515.379C125.314 515.381 126.249 515.159 127.096 514.733ZM147.1 500.064C148.464 500.85 148.464 502.104 147.116 502.924L126.833 514.602C126.067 514.983 125.223 515.181 124.367 515.181C123.511 515.181 122.667 514.983 121.902 514.602L58.471 478.084C57.0985 477.265 57.0903 476.011 58.471 475.224L78.705 463.538C79.4702 463.155 80.3145 462.955 81.1706 462.955C82.0267 462.955 82.8709 463.155 83.6361 463.538L147.1 500.064Z"
            fill="#C6AD89"
          />
          <path
            d="M132.38 506.112C127.778 508.767 120.291 508.751 115.664 506.112C111.037 503.473 111.012 499.138 115.614 496.491C120.217 493.843 127.696 493.852 132.323 496.491C136.95 499.129 136.958 503.465 132.38 506.112Z"
            fill="#CEBBA1"
          />
          <path
            d="M135.183 503.505C134.491 504.601 133.525 505.499 132.38 506.111C127.778 508.767 120.291 508.75 115.664 506.111C114.476 505.474 113.478 504.536 112.771 503.39C113.413 502.021 114.498 500.906 115.853 500.227C120.373 497.687 127.679 497.769 132.175 500.407C133.482 501.095 134.536 502.18 135.183 503.505Z"
            fill="#F2DDC2"
          />
          <path
            d="M122.329 504.128H122.411C122.506 503.962 122.645 503.826 122.814 503.735C123.179 503.549 123.583 503.452 123.993 503.452C124.403 503.452 124.807 503.549 125.172 503.735C125.34 503.823 125.479 503.956 125.575 504.12L125.657 504.161C125.657 504.161 125.657 504.497 125.657 504.513C125.625 504.638 125.565 504.754 125.483 504.853C125.401 504.952 125.297 505.032 125.181 505.087C124.815 505.272 124.411 505.369 124.001 505.369C123.591 505.369 123.187 505.272 122.822 505.087C122.703 505.03 122.598 504.948 122.513 504.848C122.428 504.748 122.365 504.631 122.329 504.505V504.128Z"
            fill="#5D6D89"
          />
          <path
            d="M118.236 500.891C118.658 500.676 119.126 500.565 119.6 500.565C120.074 500.565 120.542 500.676 120.964 500.891C121.135 500.941 121.285 501.044 121.392 501.186C121.498 501.328 121.556 501.5 121.556 501.678C121.556 501.855 121.498 502.028 121.392 502.169C121.285 502.311 121.135 502.415 120.964 502.464C120.542 502.678 120.074 502.79 119.6 502.79C119.126 502.79 118.659 502.678 118.236 502.464C118.065 502.415 117.915 502.311 117.809 502.169C117.702 502.028 117.644 501.855 117.644 501.678C117.644 501.5 117.702 501.328 117.809 501.186C117.915 501.044 118.065 500.941 118.236 500.891Z"
            fill="#494238"
          />
          <path
            d="M122.337 504.202C122.329 504.151 122.329 504.098 122.337 504.046C122.365 503.921 122.421 503.803 122.503 503.704C122.584 503.604 122.688 503.525 122.806 503.473C123.172 503.287 123.578 503.19 123.989 503.19C124.4 503.19 124.806 503.287 125.173 503.473C125.29 503.528 125.394 503.61 125.477 503.71C125.559 503.811 125.618 503.928 125.649 504.055C125.666 504.122 125.666 504.192 125.649 504.259C125.619 504.385 125.562 504.502 125.481 504.603C125.4 504.704 125.297 504.785 125.181 504.841C124.814 505.025 124.408 505.121 123.997 505.121C123.586 505.121 123.181 505.025 122.814 504.841C122.698 504.784 122.597 504.702 122.516 504.601C122.435 504.501 122.377 504.384 122.345 504.259L122.337 504.202Z"
            fill="#6B7E99"
          />
          <path
            d="M127.014 505.939C127.436 505.725 127.904 505.613 128.378 505.613C128.852 505.613 129.32 505.725 129.742 505.939C130.285 506.251 130.433 506.701 130.203 507.087C129.382 507.37 128.542 507.592 127.688 507.75C127.455 507.701 127.231 507.618 127.022 507.505C126.854 507.454 126.707 507.35 126.603 507.21C126.498 507.07 126.441 506.9 126.44 506.725C126.439 506.55 126.494 506.379 126.597 506.238C126.701 506.097 126.847 505.992 127.014 505.939Z"
            fill="#494238"
          />
          <path
            d="M122.534 504.03C122.56 503.92 122.611 503.817 122.682 503.729C122.753 503.641 122.843 503.57 122.945 503.522C123.268 503.356 123.626 503.27 123.989 503.27C124.352 503.27 124.71 503.356 125.033 503.522C125.136 503.57 125.228 503.64 125.3 503.728C125.373 503.816 125.425 503.919 125.452 504.03C125.468 504.092 125.468 504.156 125.452 504.218C125.422 504.326 125.37 504.427 125.299 504.515C125.229 504.602 125.141 504.674 125.041 504.726C124.718 504.89 124.36 504.975 123.997 504.975C123.635 504.975 123.277 504.89 122.954 504.726C122.852 504.675 122.763 504.604 122.691 504.516C122.618 504.429 122.565 504.327 122.534 504.218C122.526 504.158 122.526 504.098 122.534 504.038V504.03Z"
            fill="#B5BDC6"
          />
          <path
            d="M125.452 504.03C125.46 504.076 125.46 504.123 125.452 504.169C125.456 504.186 125.456 504.202 125.452 504.219C125.468 504.157 125.468 504.092 125.452 504.03Z"
            fill="#B5BDC6"
          />
          <path
            d="M127.013 505.939C127.436 505.725 127.904 505.613 128.378 505.613C128.852 505.613 129.319 505.725 129.742 505.939C129.934 506.04 130.096 506.19 130.21 506.374C130.141 506.315 130.067 506.263 129.989 506.218C129.585 506.012 129.139 505.905 128.686 505.905C128.233 505.905 127.787 506.012 127.383 506.218C126.726 506.595 126.685 507.185 127.235 507.603L127.022 507.505C126.854 507.454 126.707 507.35 126.602 507.21C126.497 507.07 126.44 506.9 126.439 506.725C126.439 506.55 126.494 506.379 126.597 506.238C126.7 506.097 126.846 505.992 127.013 505.939Z"
            fill="#CEBBA1"
          />
          <g mix-blend-mode="multiply" opacity="0.5">
            <path
              d="M125.649 504.21C125.625 504.34 125.57 504.462 125.489 504.567C125.407 504.671 125.301 504.754 125.181 504.808C124.813 504.992 124.408 505.088 123.997 505.088C123.586 505.088 123.181 504.992 122.814 504.808C122.696 504.751 122.592 504.668 122.51 504.566C122.427 504.464 122.368 504.346 122.337 504.218L125.649 504.21ZM122.953 504.718C123.277 504.882 123.635 504.967 123.997 504.967C124.36 504.967 124.717 504.882 125.041 504.718C125.141 504.666 125.229 504.594 125.299 504.507C125.37 504.419 125.422 504.318 125.452 504.21H122.543C122.572 504.323 122.627 504.428 122.704 504.516C122.781 504.605 122.878 504.674 122.986 504.718H122.953Z"
              fill="#7C8189"
            />
          </g>
          <path
            d="M118.236 500.891C118.659 500.676 119.126 500.565 119.6 500.565C120.074 500.565 120.542 500.676 120.965 500.891C121.141 500.962 121.29 501.089 121.389 501.251C121.489 501.413 121.533 501.603 121.515 501.792C121.422 501.583 121.26 501.412 121.055 501.309C120.674 501.113 120.251 501.01 119.822 501.01C119.393 501.01 118.971 501.113 118.589 501.309C118.022 501.637 117.94 502.128 118.351 502.522L118.22 502.464C118.049 502.413 117.9 502.308 117.795 502.165C117.69 502.022 117.634 501.849 117.636 501.672C117.638 501.494 117.697 501.322 117.805 501.182C117.914 501.041 118.065 500.939 118.236 500.891Z"
            fill="#CEBBA1"
          />
          <path
            d="M122.813 504.767C123.18 504.953 123.586 505.05 123.997 505.05C124.408 505.05 124.814 504.953 125.18 504.767C125.304 504.715 125.413 504.633 125.498 504.529C125.582 504.424 125.64 504.301 125.665 504.169C125.646 504.308 125.591 504.44 125.507 504.552C125.422 504.664 125.309 504.752 125.18 504.808C124.813 504.992 124.408 505.088 123.997 505.088C123.586 505.088 123.181 504.992 122.813 504.808C122.687 504.749 122.577 504.659 122.493 504.548C122.408 504.437 122.352 504.307 122.329 504.169C122.36 504.299 122.419 504.42 122.503 504.523C122.587 504.627 122.693 504.71 122.813 504.767Z"
            fill="#B5BDC6"
          />
          <path
            d="M125.649 504.218H122.362C122.358 504.172 122.358 504.125 122.362 504.079H125.649C125.658 504.125 125.658 504.172 125.649 504.218Z"
            fill="#5D6D89"
          />
          <g mix-blend-mode="multiply" opacity="0.4">
            <path
              d="M133.021 505.709L133.186 505.595L133.021 505.709Z"
              fill="#C6AD89"
            />
          </g>
          <path
            opacity="0.1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60 318.01L186.5 392L313 318.01L186.5 244L60 318.01Z"
            fill="white"
          />
          <path
            opacity="0.06"
            d="M164.042 74.1391C170.298 74.1391 175.369 69.0676 175.369 62.8116C175.369 56.5556 170.298 51.4841 164.042 51.4841C157.786 51.4841 152.714 56.5556 152.714 62.8116C152.714 69.0676 157.786 74.1391 164.042 74.1391Z"
            fill="white"
          />
          <path
            opacity="0.06"
            d="M164.042 77.7963C172.153 77.7963 178.729 71.2208 178.729 63.1095C178.729 54.9981 172.153 48.4226 164.042 48.4226C155.931 48.4226 149.355 54.9981 149.355 63.1095C149.355 71.2208 155.931 77.7963 164.042 77.7963Z"
            fill="white"
          />
          <path
            opacity="0.06"
            d="M188.939 68.7778C192.141 55.0297 183.591 41.2891 169.843 38.0873C156.095 34.8855 142.355 43.435 139.153 57.1831C135.951 70.9311 144.5 84.6718 158.249 87.8736C171.997 91.0754 185.737 82.5259 188.939 68.7778Z"
            fill="white"
          />
          <path
            opacity="0.06"
            d="M73.0419 125.139C79.2979 125.139 84.3694 120.068 84.3694 113.812C84.3694 107.556 79.2979 102.484 73.0419 102.484C66.7859 102.484 61.7144 107.556 61.7144 113.812C61.7144 120.068 66.7859 125.139 73.0419 125.139Z"
            fill="white"
          />
          <path
            opacity="0.06"
            d="M73.0418 128.796C81.1532 128.796 87.7287 122.221 87.7287 114.109C87.7287 105.998 81.1532 99.4226 73.0418 99.4226C64.9305 99.4226 58.355 105.998 58.355 114.109C58.355 122.221 64.9305 128.796 73.0418 128.796Z"
            fill="white"
          />
          <path
            opacity="0.06"
            d="M97.9391 119.778C101.141 106.03 92.5915 92.2891 78.8434 89.0873C65.0953 85.8855 51.3547 94.435 48.1529 108.183C44.951 121.931 53.5005 135.672 67.2486 138.874C80.9967 142.075 94.7373 133.526 97.9391 119.778Z"
            fill="white"
          />
          <path
            d="M161.35 63.6267H165.735L168.093 142.82C168.073 143.231 167.938 143.629 167.704 143.968C167.47 144.307 167.147 144.574 166.77 144.74C165.771 145.246 164.667 145.509 163.547 145.509C162.427 145.509 161.323 145.246 160.324 144.74C159.965 144.578 159.655 144.326 159.423 144.008C159.191 143.691 159.045 143.318 159 142.928L161.35 63.6267Z"
            fill="#2E2E2E"
          />
          <path
            d="M160.489 143.134C160.552 142.012 160.878 140.921 161.44 139.948C162.002 138.975 162.784 138.147 163.724 137.532C164.027 137.328 164.371 137.195 164.733 137.143C165.094 137.092 165.462 137.123 165.81 137.234L165.851 137.16L189.358 150.3C189.669 150.536 189.914 150.848 190.07 151.205C190.227 151.563 190.289 151.955 190.252 152.343C190.189 153.465 189.863 154.557 189.301 155.53C188.74 156.503 187.957 157.33 187.017 157.945C186.663 158.214 186.234 158.367 185.79 158.383C185.346 158.399 184.907 158.278 184.534 158.036L161.507 145.236C161.159 145.015 160.879 144.701 160.699 144.33C160.519 143.958 160.447 143.544 160.489 143.134Z"
            fill="#2E2E2E"
          />
          <path
            d="M159.364 62.4267C159.364 63.3023 159.624 64.1581 160.11 64.8861C160.596 65.6141 161.288 66.1815 162.097 66.5165C162.906 66.8516 163.796 66.9392 164.654 66.7684C165.513 66.5976 166.302 66.176 166.921 65.5569C167.54 64.9378 167.962 64.1491 168.132 63.2904C168.303 62.4317 168.216 61.5416 167.881 60.7327C167.545 59.9238 166.978 59.2325 166.25 58.746C165.522 58.2596 164.666 58 163.791 58C162.617 58 161.491 58.4664 160.661 59.2966C159.83 60.1267 159.364 61.2527 159.364 62.4267Z"
            fill="#282828"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M195.67 300.924V311.902L217.262 322.7L238.854 311.902V300.924L217.262 290.126L195.67 300.924Z"
            fill="#9B5F1F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M217.262 290.126V322.7L238.854 311.902V300.924L217.262 290.126Z"
            fill="#8D551E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M195.669 311.904L217.261 322.701V311.72L195.669 300.922V311.904Z"
            fill="url(#paint45121_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M238.856 311.904L217.261 322.701V311.72L238.856 300.922V311.904Z"
            fill="url(#paint14512_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M238.854 300.923L217.262 311.72L195.667 300.923L217.262 290.126L238.854 300.923Z"
            fill="url(#paint2451_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M143.872 326.952V337.931L165.464 348.728L187.056 337.931V326.952L165.464 316.155L143.872 326.952Z"
            fill="#9B5F1F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M165.464 316.155V348.728L187.056 337.931V326.952L165.464 316.155Z"
            fill="#8D551E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M143.871 337.932L165.463 348.729V337.748L143.871 326.95V337.932Z"
            fill="url(#paint34512_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M187.058 337.932L165.463 348.729V337.748L187.058 326.95V337.932Z"
            fill="url(#paint44512_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M187.055 326.951L165.463 337.748L143.868 326.951L165.463 316.155L187.055 326.951Z"
            fill="url(#paint54512_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M209.58 232.519H225.209V300.909C225.209 300.909 223.588 303.956 217.383 303.929C211.695 303.903 209.58 300.909 209.58 300.909V232.519Z"
            fill="#242424"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M156.826 267.583H172.456V327.286C172.456 327.286 170.834 330.333 164.629 330.306C158.942 330.28 156.826 327.286 156.826 327.286V267.583Z"
            fill="#242424"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M87.0343 174.299V256.988L181.531 304.23L276.027 256.988V174.299L181.531 127.044L87.0343 174.299Z"
            fill="#9B5F1F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M181.531 127.044V304.23L276.027 256.988V174.299L181.531 127.044Z"
            fill="#8D551E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M87.0342 256.98L181.531 304.235V221.546L87.0342 174.291V256.98Z"
            fill="url(#paint451448_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M276.04 256.98L181.531 304.235V221.546L276.04 174.291V256.98Z"
            fill="url(#paint75124_linear)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M224.075 257.42C225.044 254.673 226.978 252.342 229.418 250.75C232.336 248.847 235.291 248.13 237.768 248.744C240.64 249.456 243.476 251.138 243.65 254.408C243.724 255.792 242.727 256.942 241.51 257.606C237.201 259.96 230.115 263.759 226.237 265.294C225.539 265.57 224.706 265.723 224.146 265.225C222.771 264.001 222.94 260.636 224.075 257.42Z"
            fill="#543312"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M233.458 253.187C230.427 256.736 226.39 256.637 224.87 256.487C224.075 256.076 224.86 255.394 226.237 253.534C226.479 253.208 226.754 252.905 227.061 252.641C229.682 250.387 230.598 249.58 233.424 248.773C234.806 248.378 234.531 250.656 233.458 253.187Z"
            fill="#D34214"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M254.536 233.955C250.968 233.733 248.414 228.119 248.83 221.414C249.247 214.71 252.477 209.455 256.045 209.677C259.613 209.899 262.168 215.513 261.751 222.218C261.335 228.922 258.104 234.177 254.536 233.955Z"
            fill="#543312"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M202.774 257.49C198.936 257.49 195.824 251.643 195.824 244.431C195.824 237.219 198.936 231.373 202.774 231.373C206.612 231.373 209.724 237.219 209.724 244.431C209.724 251.643 206.612 257.49 202.774 257.49Z"
            fill="#543312"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M276.032 174.294L181.536 221.545L87.0264 174.294L181.536 127.043L276.032 174.294Z"
            fill="url(#paint84512_linear)"
          />
          <path
            opacity="0.2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M236.316 194.251L141.82 147L129.999 152.906L224.509 200.157L236.316 194.251Z"
            fill="black"
            fill-opacity="0.2"
          />
          <g opacity="0.2">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M235.807 216.243V193L224 198.906L224.039 222.149L235.807 216.243Z"
              fill="black"
              fill-opacity="0.2"
            />
          </g>
          <path
            d="M169.657 156.143C169.413 155.606 169.964 155.059 170.897 154.922C171.825 154.785 172.779 155.11 173.027 155.65L188.886 188.749C189.049 189.106 188.858 189.468 188.438 189.711C188.227 189.832 187.957 189.924 187.646 189.969C186.717 190.107 185.766 189.78 185.516 189.242L169.657 156.143Z"
            fill="#F95757"
          />
          <circle
            r="24.9438"
            transform="matrix(0.866044 -0.499967 0.866044 0.499967 180.669 171.942)"
            stroke="#F95757"
            stroke-width="4"
          />
          <path
            d="M174.826 172.681C173.033 174.489 172.001 176.581 172.021 178.819C172.026 179.375 172.808 179.827 173.771 179.829C174.251 179.831 174.686 179.72 175 179.539C175.313 179.358 175.506 179.107 175.503 178.829C175.493 177.761 175.768 176.732 176.281 175.763L174.826 172.681ZM189.129 179.253L192.565 179.337C193.523 179.361 194.271 178.93 194.229 178.376L194.086 176.391C191.331 176.382 189.114 177.663 189.129 179.253ZM171.958 166.609C166.157 170.027 163.019 174.56 163.135 179.375C163.148 179.931 163.938 180.379 164.901 180.376C165.864 180.373 166.629 179.922 166.618 179.367C166.526 175.394 168.968 171.756 173.06 168.942L171.958 166.609ZM181.827 168.442L182.802 170.506C185.758 169.256 189.398 168.514 193.352 168.526C194.315 168.529 195.088 168.083 195.085 167.526C195.08 166.97 194.298 166.519 193.335 166.516C189.091 166.504 185.147 167.217 181.827 168.442ZM184.321 173.726L185.499 176.217C186.851 174.745 189.505 173.734 192.599 173.743C193.079 173.744 193.514 173.633 193.828 173.452C194.141 173.271 194.334 173.02 194.331 172.743C194.326 172.187 193.544 171.735 192.581 171.732C189.338 171.723 186.414 172.489 184.321 173.726ZM193.966 161.264C188.799 161.275 183.843 162.007 179.433 163.372L180.39 165.399C184.402 164.064 189.045 163.287 194.018 163.276C194.498 163.275 194.932 163.162 195.243 162.979C195.556 162.796 195.742 162.543 195.736 162.268C195.717 161.709 194.927 161.261 193.966 161.264Z"
            fill="#282828"
          />
          <path
            d="M70.3085 114.594H74.7021L77.0603 193.787C77.0493 194.201 76.9226 194.605 76.6945 194.951C76.4664 195.298 76.146 195.574 75.7695 195.748C74.7694 196.25 73.6658 196.511 72.5467 196.511C71.4276 196.511 70.3239 196.25 69.3238 195.748C68.9654 195.586 68.655 195.334 68.423 195.016C68.1909 194.699 68.0452 194.326 67.9999 193.936L70.3085 114.594Z"
            fill="#2E2E2E"
          />
          <path
            d="M69.456 194.134C69.5189 193.012 69.8444 191.921 70.4064 190.948C70.9683 189.975 71.751 189.148 72.6912 188.533C72.9919 188.325 73.3368 188.19 73.6987 188.138C74.0605 188.086 74.4295 188.119 74.7763 188.235L74.8177 188.16L98.3002 201.292C98.6108 201.526 98.856 201.836 99.0125 202.193C99.1689 202.549 99.2313 202.94 99.1938 203.327C99.1309 204.449 98.8053 205.54 98.2434 206.513C97.6814 207.486 96.8988 208.314 95.9585 208.929C95.6065 209.202 95.1775 209.358 94.7321 209.374C94.2866 209.391 93.8473 209.267 93.4762 209.02L70.4489 196.219C70.1078 195.997 69.8347 195.684 69.6596 195.316C69.4844 194.949 69.414 194.54 69.456 194.134Z"
            fill="#2E2E2E"
          />
          <path
            d="M68.3225 113.427C68.3225 114.303 68.5824 115.159 69.0692 115.887C69.5559 116.615 70.2478 117.183 71.0572 117.517C71.8665 117.852 72.757 117.939 73.6159 117.768C74.4749 117.596 75.2636 117.174 75.8824 116.554C76.5012 115.934 76.9221 115.145 77.092 114.285C77.2618 113.426 77.173 112.536 76.8367 111.727C76.5004 110.918 75.9317 110.227 75.2026 109.742C74.4736 109.257 73.6169 108.998 72.741 109C72.1601 109 71.5848 109.115 71.0482 109.337C70.5116 109.56 70.0241 109.886 69.6137 110.297C69.2033 110.708 68.878 111.196 68.6565 111.733C68.4349 112.27 68.3214 112.846 68.3225 113.427Z"
            fill="#282828"
          />
          <g mix-blend-mode="overlay">
            <g mix-blend-mode="overlay">
              <path
                d="M80.2895 91.8227C80.2895 92.0371 80.2259 92.2466 80.1068 92.4249C79.9877 92.6032 79.8184 92.7421 79.6203 92.8241C79.4223 92.9062 79.2043 92.9276 78.9941 92.8858C78.7838 92.844 78.5907 92.7407 78.4391 92.5892C78.2875 92.4376 78.1842 92.2444 78.1424 92.0342C78.1006 91.8239 78.122 91.606 78.2041 91.4079C78.2861 91.2098 78.4251 91.0405 78.6033 90.9214C78.7816 90.8023 78.9911 90.7388 79.2055 90.7388C79.493 90.7388 79.7687 90.853 79.972 91.0562C80.1752 91.2595 80.2895 91.5352 80.2895 91.8227Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M75.6554 98.1196C75.6554 98.334 75.5918 98.5435 75.4727 98.7218C75.3536 98.9 75.1843 99.039 74.9863 99.121C74.7882 99.203 74.5703 99.2245 74.36 99.1827C74.1498 99.1409 73.9566 99.0376 73.805 98.886C73.6534 98.7344 73.5502 98.5413 73.5084 98.331C73.4666 98.1208 73.488 97.9028 73.5701 97.7048C73.6521 97.5067 73.791 97.3374 73.9693 97.2183C74.1475 97.0992 74.3571 97.0356 74.5715 97.0356C74.859 97.0356 75.1347 97.1498 75.3379 97.3531C75.5412 97.5564 75.6554 97.8321 75.6554 98.1196Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M65.8834 95.2815C65.8834 95.4952 65.8202 95.7041 65.7018 95.8819C65.5835 96.0598 65.4151 96.1987 65.218 96.2812C65.0209 96.3637 64.8039 96.3861 64.5941 96.3456C64.3843 96.3051 64.1911 96.2035 64.0389 96.0536C63.8867 95.9037 63.7821 95.7121 63.7385 95.5029C63.6948 95.2938 63.7139 95.0763 63.7934 94.878C63.8729 94.6797 64.0093 94.5093 64.1853 94.3882C64.3614 94.2671 64.5693 94.2008 64.783 94.1976C64.927 94.1942 65.0702 94.2199 65.2042 94.2729C65.3381 94.326 65.46 94.4055 65.5626 94.5066C65.6653 94.6077 65.7466 94.7284 65.8017 94.8615C65.8568 94.9946 65.8846 95.1374 65.8834 95.2815Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M67.5796 104.499C67.5779 104.713 67.513 104.922 67.3929 105.099C67.2728 105.276 67.1029 105.414 66.9047 105.495C66.7065 105.575 66.4889 105.596 66.2791 105.553C66.0694 105.51 65.8771 105.406 65.7263 105.254C65.5755 105.103 65.4731 104.909 65.432 104.699C65.3908 104.489 65.4128 104.272 65.495 104.074C65.5773 103.877 65.7162 103.708 65.8943 103.589C66.0724 103.47 66.2816 103.407 66.4957 103.407C66.6387 103.407 66.7803 103.435 66.9124 103.49C67.0444 103.545 67.1643 103.626 67.265 103.727C67.3658 103.829 67.4455 103.949 67.4995 104.082C67.5534 104.214 67.5807 104.356 67.5796 104.499Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M69.4497 86.6267C69.4497 86.841 69.3862 87.0506 69.267 87.2289C69.1479 87.4071 68.9787 87.546 68.7806 87.6281C68.5825 87.7101 68.3646 87.7316 68.1543 87.6898C67.9441 87.6479 67.7509 87.5447 67.5993 87.3931C67.4477 87.2415 67.3445 87.0484 67.3027 86.8381C67.2609 86.6279 67.2823 86.4099 67.3644 86.2119C67.4464 86.0138 67.5853 85.8445 67.7636 85.7254C67.9418 85.6063 68.1514 85.5427 68.3658 85.5427C68.6533 85.5427 68.929 85.6569 69.1323 85.8602C69.3355 86.0635 69.4497 86.3392 69.4497 86.6267Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M60.6459 80.9506C60.6459 81.165 60.5823 81.3746 60.4632 81.5528C60.3441 81.7311 60.1748 81.87 59.9768 81.9521C59.7787 82.0341 59.5608 82.0556 59.3505 82.0137C59.1402 81.9719 58.9471 81.8687 58.7955 81.7171C58.6439 81.5655 58.5407 81.3724 58.4989 81.1621C58.457 80.9518 58.4785 80.7339 58.5605 80.5358C58.6426 80.3378 58.7815 80.1685 58.9598 80.0494C59.138 79.9303 59.3476 79.8667 59.562 79.8667C59.8494 79.8667 60.1251 79.9809 60.3284 80.1842C60.5317 80.3875 60.6459 80.6632 60.6459 80.9506Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M73.6037 76.8383C73.6037 77.0527 73.5401 77.2623 73.421 77.4405C73.3019 77.6188 73.1326 77.7577 72.9345 77.8397C72.7365 77.9218 72.5185 77.9433 72.3083 77.9014C72.098 77.8596 71.9049 77.7564 71.7533 77.6048C71.6017 77.4532 71.4984 77.2601 71.4566 77.0498C71.4148 76.8395 71.4363 76.6216 71.5183 76.4235C71.6003 76.2255 71.7393 76.0562 71.9175 75.9371C72.0958 75.818 72.3053 75.7544 72.5197 75.7544C72.8065 75.7566 73.081 75.8715 73.2838 76.0743C73.4866 76.2771 73.6015 76.5515 73.6037 76.8383Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M78.4192 87.0319C78.4192 87.2463 78.3556 87.4559 78.2365 87.6341C78.1174 87.8124 77.9481 87.9513 77.7501 88.0334C77.552 88.1154 77.3341 88.1369 77.1238 88.095C76.9135 88.0532 76.7204 87.95 76.5688 87.7984C76.4172 87.6468 76.314 87.4537 76.2722 87.2434C76.2303 87.0331 76.2518 86.8152 76.3338 86.6171C76.4159 86.4191 76.5548 86.2498 76.7331 86.1307C76.9113 86.0116 77.1209 85.948 77.3353 85.948C77.6227 85.948 77.8985 86.0622 78.1017 86.2655C78.305 86.4688 78.4192 86.7445 78.4192 87.0319Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M82.5891 110.548C82.5891 110.762 82.5256 110.972 82.4065 111.15C82.2873 111.328 82.1181 111.467 81.92 111.549C81.7219 111.631 81.504 111.652 81.2937 111.611C81.0835 111.569 80.8903 111.466 80.7387 111.314C80.5872 111.162 80.4839 110.969 80.4421 110.759C80.4003 110.549 80.4217 110.331 80.5038 110.133C80.5858 109.935 80.7247 109.765 80.903 109.646C81.0813 109.527 81.2908 109.464 81.5052 109.464C81.7927 109.464 82.0684 109.578 82.2716 109.781C82.4749 109.984 82.5891 110.26 82.5891 110.548Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M80.3058 68.0839C80.3058 68.2983 80.2422 68.5079 80.1231 68.6861C80.004 68.8644 79.8347 69.0033 79.6367 69.0854C79.4386 69.1674 79.2207 69.1889 79.0104 69.147C78.8001 69.1052 78.607 69.002 78.4554 68.8504C78.3038 68.6988 78.2006 68.5057 78.1588 68.2954C78.1169 68.0851 78.1384 67.8672 78.2204 67.6691C78.3025 67.4711 78.4414 67.3018 78.6197 67.1827C78.7979 67.0636 79.0075 67 79.2219 67C79.5093 67 79.7851 67.1142 79.9883 67.3175C80.1916 67.5208 80.3058 67.7965 80.3058 68.0839Z"
                fill="white"
              />
            </g>
          </g>
          <g mix-blend-mode="overlay">
            <g mix-blend-mode="overlay">
              <path
                d="M170.289 40.8227C170.289 41.0371 170.226 41.2466 170.107 41.4249C169.988 41.6032 169.818 41.7421 169.62 41.8241C169.422 41.9062 169.204 41.9276 168.994 41.8858C168.784 41.844 168.591 41.7407 168.439 41.5892C168.287 41.4376 168.184 41.2444 168.142 41.0342C168.101 40.8239 168.122 40.606 168.204 40.4079C168.286 40.2098 168.425 40.0405 168.603 39.9214C168.782 39.8023 168.991 39.7388 169.206 39.7388C169.493 39.7388 169.769 39.853 169.972 40.0562C170.175 40.2595 170.289 40.5352 170.289 40.8227Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M165.655 47.1196C165.655 47.334 165.592 47.5435 165.473 47.7218C165.354 47.9 165.184 48.039 164.986 48.121C164.788 48.203 164.57 48.2245 164.36 48.1827C164.15 48.1409 163.957 48.0376 163.805 47.886C163.653 47.7344 163.55 47.5413 163.508 47.331C163.467 47.1208 163.488 46.9028 163.57 46.7048C163.652 46.5067 163.791 46.3374 163.969 46.2183C164.148 46.0992 164.357 46.0356 164.571 46.0356C164.859 46.0356 165.135 46.1498 165.338 46.3531C165.541 46.5564 165.655 46.8321 165.655 47.1196Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M155.884 44.2815C155.883 44.4952 155.82 44.7041 155.702 44.8819C155.584 45.0598 155.415 45.1987 155.218 45.2812C155.021 45.3637 154.804 45.3861 154.594 45.3456C154.384 45.3051 154.191 45.2035 154.039 45.0536C153.887 44.9037 153.782 44.7121 153.739 44.5029C153.695 44.2938 153.714 44.0763 153.793 43.878C153.873 43.6797 154.009 43.5093 154.185 43.3882C154.361 43.2671 154.569 43.2008 154.783 43.1976C154.927 43.1942 155.07 43.2199 155.204 43.2729C155.338 43.326 155.46 43.4055 155.563 43.5066C155.665 43.6077 155.747 43.7284 155.802 43.8615C155.857 43.9946 155.885 44.1374 155.884 44.2815Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M157.58 53.4992C157.578 53.7132 157.513 53.922 157.393 54.0991C157.273 54.2763 157.103 54.4139 156.905 54.4947C156.707 54.5755 156.489 54.5958 156.279 54.553C156.069 54.5102 155.877 54.4064 155.726 54.2545C155.576 54.1025 155.473 53.9094 155.432 53.6994C155.391 53.4893 155.413 53.2718 155.495 53.0742C155.577 52.8766 155.716 52.7078 155.894 52.5891C156.072 52.4704 156.282 52.407 156.496 52.407C156.639 52.407 156.78 52.4353 156.912 52.4903C157.044 52.5453 157.164 52.6258 157.265 52.7274C157.366 52.8289 157.445 52.9494 157.499 53.0818C157.553 53.2143 157.581 53.3561 157.58 53.4992Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M159.45 35.6267C159.45 35.841 159.386 36.0506 159.267 36.2289C159.148 36.4071 158.979 36.546 158.781 36.6281C158.583 36.7101 158.365 36.7316 158.154 36.6898C157.944 36.6479 157.751 36.5447 157.599 36.3931C157.448 36.2415 157.345 36.0484 157.303 35.8381C157.261 35.6279 157.282 35.4099 157.364 35.2119C157.446 35.0138 157.585 34.8445 157.764 34.7254C157.942 34.6063 158.151 34.5427 158.366 34.5427C158.653 34.5427 158.929 34.6569 159.132 34.8602C159.336 35.0635 159.45 35.3392 159.45 35.6267Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M150.646 29.9506C150.646 30.165 150.582 30.3746 150.463 30.5528C150.344 30.7311 150.175 30.87 149.977 30.9521C149.779 31.0341 149.561 31.0556 149.35 31.0137C149.14 30.9719 148.947 30.8687 148.796 30.7171C148.644 30.5655 148.541 30.3724 148.499 30.1621C148.457 29.9518 148.479 29.7339 148.561 29.5358C148.643 29.3378 148.782 29.1685 148.96 29.0494C149.138 28.9303 149.348 28.8667 149.562 28.8667C149.849 28.8667 150.125 28.9809 150.328 29.1842C150.532 29.3875 150.646 29.6632 150.646 29.9506Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M163.604 25.8383C163.604 26.0527 163.54 26.2623 163.421 26.4405C163.302 26.6188 163.133 26.7577 162.935 26.8397C162.737 26.9218 162.519 26.9433 162.308 26.9014C162.098 26.8596 161.905 26.7564 161.753 26.6048C161.602 26.4532 161.499 26.2601 161.457 26.0498C161.415 25.8395 161.436 25.6216 161.518 25.4235C161.6 25.2255 161.739 25.0562 161.918 24.9371C162.096 24.818 162.305 24.7544 162.52 24.7544C162.807 24.7566 163.081 24.8715 163.284 25.0743C163.487 25.2771 163.602 25.5515 163.604 25.8383Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M168.419 36.0319C168.419 36.2463 168.356 36.4559 168.237 36.6341C168.117 36.8124 167.948 36.9513 167.75 37.0334C167.552 37.1154 167.334 37.1369 167.124 37.095C166.914 37.0532 166.72 36.95 166.569 36.7984C166.417 36.6468 166.314 36.4537 166.272 36.2434C166.23 36.0331 166.252 35.8152 166.334 35.6171C166.416 35.4191 166.555 35.2498 166.733 35.1307C166.911 35.0116 167.121 34.948 167.335 34.948C167.623 34.948 167.899 35.0622 168.102 35.2655C168.305 35.4688 168.419 35.7445 168.419 36.0319Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M172.589 59.5476C172.589 59.7619 172.526 59.9715 172.406 60.1498C172.287 60.328 172.118 60.4669 171.92 60.549C171.722 60.631 171.504 60.6525 171.294 60.6107C171.083 60.5688 170.89 60.4656 170.739 60.314C170.587 60.1624 170.484 59.9693 170.442 59.759C170.4 59.5488 170.422 59.3308 170.504 59.1328C170.586 58.9347 170.725 58.7654 170.903 58.6463C171.081 58.5272 171.291 58.4636 171.505 58.4636C171.793 58.4636 172.068 58.5778 172.272 58.7811C172.475 58.9844 172.589 59.2601 172.589 59.5476Z"
                fill="white"
              />
            </g>
            <g mix-blend-mode="overlay">
              <path
                d="M170.306 17.0839C170.306 17.2983 170.242 17.5079 170.123 17.6861C170.004 17.8644 169.835 18.0033 169.637 18.0854C169.439 18.1674 169.221 18.1889 169.01 18.147C168.8 18.1052 168.607 18.002 168.455 17.8504C168.304 17.6988 168.201 17.5057 168.159 17.2954C168.117 17.0851 168.138 16.8672 168.22 16.6691C168.302 16.4711 168.441 16.3018 168.62 16.1827C168.798 16.0636 169.007 16 169.222 16C169.509 16 169.785 16.1142 169.988 16.3175C170.192 16.5208 170.306 16.7965 170.306 17.0839Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M388 360.019C388 360.019 342 373.019 317 388.518C292 404.018 382 413.519 309.5 434.519C297.632 437.956 279.5 439.96 267.017 447.712"
            stroke="#262424"
            stroke-width="3"
          />
          <g clip-path="url(#clip51251)">
            <path
              d="M255.706 461.346L249.416 443.222C259.546 441.387 257.073 441.841 257.575 441.729C260.179 441.136 263.768 442.927 265.429 447.713C267.09 452.5 265.362 455.588 263.562 457.121C263.28 457.368 262.441 457.765 255.706 461.346Z"
              fill="#262424"
            />
            <path
              d="M268.787 449.248L267.317 445.011L260.907 446.744L262.681 451.858L268.787 449.248Z"
              fill="#262424"
            />
            <path
              d="M252.704 467.034C249.152 468.292 244.191 463.4 241.623 456.107C239.055 448.814 239.852 441.882 243.404 440.623C246.955 439.365 251.917 444.258 254.485 451.551C257.053 458.844 256.256 465.776 252.704 467.034Z"
              fill="#191919"
            />
            <path
              d="M247.571 449.605C247.613 449.026 247.532 448.429 247.336 447.864C247.139 447.298 246.833 446.78 246.442 446.351C245.692 445.597 244.98 445.845 244.858 446.901C244.817 447.48 244.897 448.077 245.094 448.642C245.29 449.207 245.596 449.726 245.987 450.155C246.733 450.897 247.441 450.652 247.571 449.605Z"
              fill="#343742"
            />
            <path
              d="M246.627 450.244L236.497 453.759C236.066 453.873 235.597 453.798 235.178 453.548C234.758 453.299 234.418 452.891 234.22 452.403C234.072 451.898 234.087 451.368 234.263 450.913C234.438 450.459 234.762 450.112 235.171 449.939L245.386 446.394C245.535 446.349 245.697 446.355 245.853 446.411C246.009 446.467 246.153 446.571 246.268 446.71C246.594 447.068 246.849 447.501 247.013 447.973C247.177 448.445 247.244 448.942 247.21 449.426C247.198 449.62 247.137 449.8 247.033 449.945C246.93 450.09 246.789 450.194 246.627 450.244Z"
              fill="#B0B7C9"
            />
            <path
              d="M246.627 450.244L236.497 453.759C236.066 453.873 235.597 453.798 235.178 453.548C234.759 453.299 234.418 452.891 234.22 452.403C234.179 452.281 234.148 452.156 234.127 452.031C234.368 452.423 234.701 452.732 235.082 452.916C235.463 453.101 235.873 453.151 236.254 453.059L246.047 449.661C246.25 449.612 246.434 449.502 246.58 449.342C246.726 449.183 246.829 448.979 246.881 448.75C246.905 448.338 246.836 447.915 246.68 447.521C246.524 447.126 246.286 446.774 245.988 446.496C246.09 446.557 246.185 446.633 246.271 446.721C246.598 447.079 246.853 447.512 247.017 447.984C247.181 448.456 247.248 448.953 247.214 449.437C247.207 449.633 247.147 449.815 247.042 449.96C246.937 450.104 246.792 450.203 246.627 450.244Z"
              fill="#868B9B"
            />
            <path
              d="M251.427 460.716C251.468 460.136 251.387 459.538 251.191 458.972C250.994 458.406 250.688 457.886 250.296 457.456C249.548 456.708 248.834 456.95 248.712 458.006C248.67 458.585 248.751 459.182 248.947 459.747C249.144 460.313 249.45 460.831 249.841 461.26C250.587 462.003 251.297 461.763 251.427 460.716Z"
              fill="#343742"
            />
            <path
              d="M250.481 461.349L240.351 464.864C239.92 464.976 239.452 464.9 239.033 464.65C238.614 464.401 238.273 463.995 238.074 463.508C237.925 463.004 237.94 462.473 238.116 462.02C238.292 461.566 238.617 461.22 239.027 461.05L249.242 457.505C249.391 457.462 249.553 457.469 249.709 457.525C249.864 457.581 250.008 457.683 250.123 457.821C250.45 458.18 250.706 458.614 250.87 459.086C251.034 459.559 251.102 460.058 251.068 460.542C251.055 460.736 250.993 460.915 250.889 461.058C250.785 461.202 250.643 461.303 250.481 461.349Z"
              fill="#B0B7C9"
            />
            <path
              d="M250.481 461.349L240.351 464.864C239.92 464.976 239.452 464.9 239.033 464.651C238.614 464.401 238.273 463.995 238.074 463.508C238.033 463.388 238.003 463.266 237.983 463.142C238.223 463.534 238.556 463.843 238.937 464.026C239.318 464.21 239.727 464.258 240.108 464.165L249.901 460.767C250.104 460.716 250.287 460.606 250.432 460.447C250.578 460.287 250.682 460.084 250.735 459.855C250.759 459.443 250.69 459.02 250.534 458.626C250.378 458.232 250.14 457.879 249.842 457.601C249.943 457.661 250.037 457.735 250.123 457.821C250.45 458.18 250.706 458.614 250.87 459.086C251.034 459.559 251.102 460.058 251.067 460.542C251.061 460.738 251.002 460.921 250.897 461.066C250.792 461.21 250.646 461.309 250.481 461.349Z"
              fill="#868B9B"
            />
            <path
              d="M253.01 466.911C254.482 466.219 255.55 464.424 255.901 461.621C256.609 455.608 253.687 447.234 249.357 442.913C247.26 440.823 245.222 440.08 243.592 440.559L246.625 439.506C246.737 439.467 246.85 439.428 246.962 439.389L247.211 439.303C248.794 438.977 250.737 439.747 252.732 441.742C257.056 446.046 259.973 454.422 259.252 460.439C258.915 463.238 257.851 465.031 256.36 465.729C256.145 465.829 255.921 465.907 255.69 465.962L253.01 466.911Z"
              fill="#262424"
            />
            <path
              d="M256.066 465.85C257.543 465.157 258.606 463.363 258.957 460.56C259.651 454.552 256.743 446.173 252.413 441.853C250.422 439.868 248.484 439.097 246.892 439.414C246.916 439.408 246.94 439.4 246.962 439.389L247.211 439.303C248.794 438.977 250.737 439.747 252.732 441.742C257.056 446.046 259.973 454.422 259.252 460.439C258.915 463.238 257.851 465.031 256.36 465.729C256.2 465.797 256.036 465.854 255.868 465.9C255.946 465.898 256.007 465.877 256.066 465.85Z"
              fill="#2C2F38"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint45121_linear"
            x1="185.323"
            y1="324.214"
            x2="185.979"
            y2="284.301"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9B5F1F" />
            <stop offset="1" stop-color="#824C1B" />
          </linearGradient>
          <linearGradient
            id="paint14512_linear"
            x1="243.947"
            y1="325.668"
            x2="243.947"
            y2="294.283"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C6803A" />
            <stop offset="1" stop-color="#A4601D" />
          </linearGradient>
          <linearGradient
            id="paint2451_linear"
            x1="197.306"
            y1="318.424"
            x2="179.941"
            y2="274.375"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EEC089" />
            <stop offset="1" stop-color="#C18B54" />
          </linearGradient>
          <linearGradient
            id="paint34512_linear"
            x1="133.526"
            y1="350.242"
            x2="134.182"
            y2="310.33"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9B5F1F" />
            <stop offset="1" stop-color="#824C1B" />
          </linearGradient>
          <linearGradient
            id="paint44512_linear"
            x1="192.149"
            y1="351.696"
            x2="192.149"
            y2="320.311"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C6803A" />
            <stop offset="1" stop-color="#A4601D" />
          </linearGradient>
          <linearGradient
            id="paint54512_linear"
            x1="145.506"
            y1="344.452"
            x2="128.141"
            y2="300.403"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EEC089" />
            <stop offset="1" stop-color="#C18B54" />
          </linearGradient>
          <linearGradient
            id="paint451448_linear"
            x1="64.8329"
            y1="275.71"
            x2="70.165"
            y2="101.11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9B5F1F" />
            <stop offset="1" stop-color="#824C1B" />
          </linearGradient>
          <linearGradient
            id="paint75124_linear"
            x1="280.174"
            y1="282.589"
            x2="280.174"
            y2="145.236"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C6803A" />
            <stop offset="1" stop-color="#A4601D" />
          </linearGradient>
          <linearGradient
            id="paint84512_linear"
            x1="94.1959"
            y1="250.886"
            x2="18.1983"
            y2="58.1065"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EEC089" />
            <stop offset="1" stop-color="#C18B54" />
          </linearGradient>
          <clipPath id="clip51251">
            <rect
              width="42.6886"
              height="55.6673"
              transform="matrix(0.403614 0.91493 0.91493 -0.403614 231 446.428)"
              fill="white"
            />
          </clipPath>
        </defs>
      </g>
    </svg>
    <div class="textBox">
      <h3>{{ $t('noConnection') }}</h3>
      <h4>{{ $t('checkConnection') }}</h4>
    </div>
    <div id="spinnerBars">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
      <div class="bar6"></div>
      <div class="bar7"></div>
      <div class="bar8"></div>
    </div>
  </section>
</template>

<script>
export default {
    name: "OfflinePage",
};
</script>

<style>
</style>