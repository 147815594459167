<template>
  <div class="payment-check__root">
    <div class="payment-check__final">
      <img src="@/assets/img/paper.svg" class="payment-check__paper" />

      <div v-if="isOnBuy" class="payment-check__final-item">
        <span>{{ productName }}</span>
        <b>${{ productPrice | numberRound }}</b>
      </div>
      <div
        v-if="pricesData.countryDelivery.Cost"
        class="payment-check__final-item"
      >
        <span>{{ $t("globalDeliveryPayPu") }}</span>
        <b>${{ pricesData.countryDelivery.Cost | numberRound }}</b>
      </div>
      <div v-if="totalService" class="payment-check__final-item">
        <span>{{ $t("cardAdditionalServiceName") }}</span>
        <b>${{ totalService | numberRound }}</b>
      </div>
      <div class="payment-check__final-divider"></div>
      <div v-if="bonusCount" class="payment-check__final-item">
        <span>{{ $t("cardCheckTotal") }}</span>
        <b>${{ totalCost | numberRound }}</b>
      </div>
      <div
        v-if="bonusCount"
        class="payment-check__final-item payment-check__final-item--bonus"
      >
        <span>{{ $t("newCheckBonuses") }}</span>
        <b v-if="totalCost < bonusCount">-${{ totalCost | numberRound }}</b>
        <b v-else>-${{ bonusCount | numberRound }}</b>
      </div>
      <div class="payment-check__final-item payment-check__final-item--pay">
        <span>{{ $t("debtOT") }}</span>
        <b>${{ toPayCount | numberRound }}</b>
      </div>

      <div class="payment-check__final-more" @click="isDetailOpened = true">
        <img src="@/assets/img/arr-p-down.svg" />
      </div>
    </div>
    <transition name="fast-fade">
      <div v-if="isDetailOpened" class="payment-check">
        <div class="payment-check__overlay" @click="onClose"></div>
        <div class="payment-check__wrapper">
          <div class="payment-check__content">
            <div class="payment-check__close" @click="onClose">
              <img src="@/assets/img/close-w.svg" />
            </div>
            <div class="payment-check__list">
              <!-- PRODUCT PRICES -->
              <div v-if="isOnBuy">
                <div class="payment-check__list-title">
                  {{ $t("newCheckShopper") }}
                </div>
                <div class="payment-check__list-item">
                  <span>{{ productName }}</span>
                  <div @click="isPay1InfoActive = true">
                    <img src="@/assets/img/parcel/Info.svg" />
                  </div>
                  <b :style="priceStyles">${{ productPrice | numberRound }}</b>
                </div>
                <div
                  v-if="pricesData.innerDelivery.Cost"
                  class="payment-check__list-item"
                >
                  <span>{{ $t("cardCheckInnerDelivery") }}</span>
                  <div @click="isPay2InfoActive = true">
                    <img src="@/assets/img/parcel/Info.svg" />
                  </div>
                  <b :style="priceStyles"
                    >${{ pricesData.innerDelivery.Cost | numberRound }}</b
                  >
                </div>
              </div>
              <!-- PRODUCT PRICES -->

              <!-- MAIN SERVICE -->
              <div
                v-if="
                  pricesData.buyout.Cost ||
                    pricesData.countryDelivery.Cost ||
                    pricesData.werehouse.Cost
                "
                class="payment-check__list-title"
              >
                {{ $t("paymentEasygetServices") }}
              </div>
              <div v-if="isOnBuy" class="payment-check__list-item">
                <span>{{ $t("cardCheckBuyoutService") }}</span>
                <div @click="isPay3InfoActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.buyout.Cost || 0 | numberRound }}</b
                >
              </div>
              <div
                v-if="pricesData.countryDelivery.Cost"
                class="payment-check__list-item"
              >
                <span>{{ $t("globalDeliveryPayPu") }}</span>
                <div @click="isGlobalDeliveryCostBalloonActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.countryDelivery.Cost || 0 | numberRound }}</b
                >
              </div>
              <div v-if="!isOnBuy" class="payment-check__list-item">
                <span>{{ $t("warehouseProcPayPu") }}</span>
                <div @click="isWarehouseCostBalloonActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.werehouse.Cost || 0 | numberRound }}</b
                >
              </div>
              <!-- MAIN SERVICE -->

              <!-- ADDITIONAL SERVICE -->
              <div
                v-if="additionalServiceCost"
                class="payment-check__list-title"
              >
                {{ $t("newCheckAddService") }}
              </div>
              <div
                v-if="pricesData.consult.Cost"
                class="payment-check__list-item"
              >
                <span>{{ $t("paymentConsultTitle") }}</span>
                <div @click="isPayConsultInfoActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.consult.Cost || 0 | numberRound }}</b
                >
              </div>
              <div
                v-if="pricesData.insurance.Cost"
                class="payment-check__list-item"
              >
                <span>{{ $t("ensurancePayPu") }}</span>
                <div @click="isEnsuranceCostBalloonActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.insurance.Cost || 0 | numberRound }}</b
                >
              </div>
              <div
                v-if="pricesData.divide.Cost"
                class="payment-check__list-item"
              >
                <span
                  v-html="
                    $t('dividePayPu') +
                      ` <span>${
                        pricesData.divide.Base > 1
                          ? 'x' + pricesData.divide.Base
                          : ''
                      }</span>`
                  "
                />
                <div @click="isDivideDetailActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.divide.Cost || 0 | numberRound }}</b
                >
              </div>
              <div
                v-if="pricesData.combine.Cost"
                class="payment-check__list-item"
              >
                <span
                  v-html="
                    $t('combinePayPu') +
                      ` <span>${
                        pricesData.combine.Base > 1
                          ? 'x' + pricesData.combine.Base
                          : ''
                      }</span>`
                  "
                />
                <div @click="isСombineDetailActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.combine.Cost || 0 | numberRound }}</b
                >
              </div>
              <div
                v-if="pricesData.additionalBox.Cost"
                class="payment-check__list-item"
              >
                <span>{{ $t("additionalBoxPayPu") }}</span>
                <div @click="isAdditionalBoxDetailActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.additionalBox.Cost || 0 | numberRound }}</b
                >
              </div>
              <div
                v-if="pricesData.additionalPhoto.Cost"
                class="payment-check__list-item"
              >
                <span>{{ $t("additionalPhotoPayPu") }}</span>
                <div @click="isAdditionalPhotoDetailActive = true">
                  <img src="@/assets/img/parcel/Info.svg" />
                </div>
                <b :style="priceStyles"
                  >${{ pricesData.additionalPhoto.Cost || 0 | numberRound }}</b
                >
              </div>
              <!-- ADDITIONAL SERVICE -->

              <!-- CUSTOMS SERVICE -->
              <div v-if="pricesData.productTax.Cost">
                <div class="payment-check__list-title">
                  {{ $t("newCheckCustoms") }}
                </div>
                <div class="payment-check__list-item">
                  <span>{{ $t("paymentProductTaxTitle") }}</span>
                  <div @click="isPayTaxInfoActive = true">
                    <img src="@/assets/img/parcel/Info.svg" />
                  </div>
                  <b :style="priceStyles"
                    >${{ pricesData.productTax.Cost || 0 | numberRound }}</b
                  >
                </div>
              </div>
              <!-- CUSTOMS SERVICE -->

              <!-- LOCAL DELIVERY -->
              <div v-if="pricesData.localDelivery.Cost">
                <div class="payment-check__list-title">
                  {{ $t("newCheckNP") }}
                </div>
                <div class="payment-check__list-item">
                  <span>{{ $t("addressDeliveryPayPu") }}</span>
                  <div @click="isAddressDeliveryCostBalloonActive = true">
                    <img src="@/assets/img/parcel/Info.svg" />
                  </div>
                  <b :style="priceStyles"
                    >${{ pricesData.localDelivery.Cost || 0 | numberRound }}</b
                  >
                </div>
              </div>
              <!-- LOCAL DELIVERY -->

              <!-- FINAL TOTAL AMOUNT -->
              <div class="payment-check__final-divider"></div>
              <div v-if="bonusCount" class="payment-check__final-item">
                <span>{{ $t("cardCheckTotal") }}</span>
                <b :style="priceStyles">${{ totalCost | numberRound }}</b>
              </div>
              <div
                v-if="bonusCount"
                class="
                  payment-check__final-item payment-check__final-item--bonus
                "
              >
                <span>{{ $t("newCheckBonuses") }}</span>
                <b :style="priceStyles" v-if="totalCost < bonusCount"
                  >-${{ totalCost | numberRound }}</b
                >
                <b :style="priceStyles" v-else
                  >-${{ bonusCount | numberRound }}</b
                >
              </div>
              <div
                class="payment-check__final-item payment-check__final-item--pay"
              >
                <span>{{ $t("debtOT") }}</span>
                <b :style="priceStyles">${{ toPayCount | numberRound }}</b>
              </div>
              <!-- FINAL TOTAL AMOUNT -->
            </div>
            <img src="@/assets/img/paper.svg" class="payment-check__paper" />
          </div>
        </div>
      </div>
    </transition>

    <balloon
      v-if="isPay1InfoActive"
      :text="$t('pay1InfoPayPu')"
      v-bind:isopen.sync="isPay1InfoActive"
    ></balloon>
    <balloon
      v-if="isPay2InfoActive"
      :text="$t('pay2InfoPayPu')"
      v-bind:isopen.sync="isPay2InfoActive"
    ></balloon>
    <balloon
      v-if="isPay3InfoActive"
      :text="$t('pay3InfoPayPu', { rate: buyoutPercent, minBuyoutService })"
      v-bind:isopen.sync="isPay3InfoActive"
    ></balloon>
    <balloon
      v-if="isPayConsultInfoActive"
      :text="$t('payConsultInfoPayPu', { rate: consultPrice })"
      v-bind:isopen.sync="isPayConsultInfoActive"
    ></balloon>
    <balloon
      v-if="isPayTaxInfoActive"
      :text="$t('payTaxInfoPayPu')"
      v-bind:isopen.sync="isPayTaxInfoActive"
    ></balloon>
    <balloon
      v-if="isBonusDetailActive"
      :text="$t('bonusInfoPayPu')"
      v-bind:isopen.sync="isBonusDetailActive"
    ></balloon>
    <balloon
      v-if="isСombineDetailActive"
      :text="$t('combineInfoPayPu')"
      v-bind:isopen.sync="isСombineDetailActive"
    ></balloon>
    <balloon
      v-if="isDivideDetailActive"
      :text="$t('divideInfoPayPu')"
      v-bind:isopen.sync="isDivideDetailActive"
    ></balloon>
    <balloon
      v-if="isAdditionalBoxDetailActive"
      :text="$t('additionalBoxInfoPayPu')"
      v-bind:isopen.sync="isAdditionalBoxDetailActive"
    ></balloon>
    <balloon
      v-if="isAdditionalPhotoDetailActive"
      :text="$t('additionalPhotoInfoPayPu')"
      v-bind:isopen.sync="isAdditionalPhotoDetailActive"
    ></balloon>
    <balloon
      v-if="isWarehouseCostBalloonActive"
      :text="$t('service2CostCargoNewPu')"
      v-bind:isopen.sync="isWarehouseCostBalloonActive"
    ></balloon>
    <balloon
      v-if="isGlobalDeliveryCostBalloonActive"
      v-bind:isopen.sync="isGlobalDeliveryCostBalloonActive"
    >
      <div v-if="globalDeliveryWeight && globalDeliveryTariff">
        <h4>{{ $t("procGlobalDeliveryCargoNewPu") }}</h4>
        <p>
          <span>{{ globalDeliveryCost }}</span> USD =
          <span>{{ globalDeliveryWeight }}</span> kg x
          <span>{{ globalDeliveryTariff }}</span> USD
        </p>
        <p>{{ $t("service3CostCargoNewPu") }}</p>
      </div>
      <p>{{ $t("service31CostCargoNewPu") }}</p>
    </balloon>
    <balloon
      v-if="isAddressDeliveryCostBalloonActive"
      :text="$t('service4CostCargoNewPu')"
      v-bind:isopen.sync="isAddressDeliveryCostBalloonActive"
    ></balloon>
    <balloon
      v-if="isEnsuranceCostBalloonActive"
      :text="$t('service5CostCargoNewPu')"
      v-bind:isopen.sync="isEnsuranceCostBalloonActive"
    ></balloon>
  </div>
</template>

<script>
import Balloon from "@/components/Balloon.vue";
import { mapGetters } from "vuex";

export default {
  name: "PaymentCheck",
  components: {
    Balloon,
  },
  props: {
    pricesData: {
      default: () => ({
        innerDelivery: {},
        werehouse: {},
        countryDelivery: {},
        insurance: {},
        localDelivery: {},
        combine: {},
        divide: {},
        productTax: {},
        additionalBox: {},
        additionalPhoto: {},
        buyout: {},
        consult: {},
      }),
    },
    bonusCount: {
      default: 0,
      type: Number,
    },
    toPayCount: {
      default: 0,
      type: Number,
    },
    totalCost: {
      default: 0,
      type: Number,
    },
    productName: String,
    productPrice: Number,
    step: Number,
  },
  data() {
    return {
      isDetailOpened: false,

      isPay1InfoActive: false,
      isPay2InfoActive: false,
      isPay3InfoActive: false,
      isPayConsultInfoActive: false,
      isPayTaxInfoActive: false,
      isBonusDetailActive: false,
      isСombineDetailActive: false,
      isDivideDetailActive: false,
      isAdditionalBoxDetailActive: false,
      isAdditionalPhotoDetailActive: false,
      isWarehouseCostBalloonActive: false,
      isGlobalDeliveryCostBalloonActive: false,
      isAddressDeliveryCostBalloonActive: false,
      isEnsuranceCostBalloonActive: false,
    };
  },
  methods: {
    onClose() {
      this.isDetailOpened = false;
    },
  },
  computed: {
    ...mapGetters("root", [
      "buyoutPercent",
      "minBuyoutService",
      "consultPrice",
    ]),
    totalService() {
      let price = 0;
      [...Object.keys(this.pricesData)].forEach((service) => {
        const cost = this.pricesData[service]?.Cost || 0;
        if (!["productPrice", "countryDelivery"].includes(service)) {
          price = price + cost;
        }
      });
      return price;
    },
    priceStyles() {
      const arr = [...Object.keys(this.pricesData)].map(
        (k) => `${this.pricesData[k].Cost || 0}`
      );
      const maxLength = arr.reduce((a, b) => (a.length > b.length ? a : b));
      return { minWidth: maxLength.length * 10 + 2 + "px" };
    },
    isOnBuy() {
      return [1, 2, 3].includes(this.step);
    },
    additionalServiceCost() {
      return (
        this.pricesData.consult.Cost ||
        this.pricesData.combine.Cost ||
        this.pricesData.divide.Cost ||
        this.pricesData.insurance.Cost ||
        this.pricesData.additionalBox.Cost ||
        this.pricesData.additionalPhoto.Cost
      );
    },
  },
};
</script>

<style lang="scss">
.payment-check__root {
  padding-top: 18px;
}
.payment-check {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(40, 40, 40, 0.8);
}
.payment-check__overlay {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.payment-check__wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.payment-check__content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 16px;
  background: #ffffff;
  position: relative;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}

.payment-check {
  font-family: "Roboto Mono";
  &__list-title {
    color: #696969;
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 5px;

    &:not(:first-child) {
      padding-top: 6px;
    }
  }
  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    letter-spacing: 0;

    & > div {
      padding-left: 6px;
      padding-right: 6px;

      img {
        display: block;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      flex: 1 auto;
    }

    b {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      text-align: right;
    }
  }
  &__final {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    padding-top: 24px;
    background: #ffffff;
    position: relative;
    max-width: 343px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    font-family: "Roboto Mono";
  }
  &__final-divider {
    border-top: 1px dashed #bfbfbf;
    height: 1px;
    margin-bottom: 14px;
  }
  &__final-item {
    display: flex;
    justify-content: space-between;

    &--bonus {
      color: #91c258;
    }

    &.payment-check__final-item--pay {
      b,
      span {
        font-weight: 500;
      }
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      letter-spacing: 0;
    }

    b {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      letter-spacing: 0;
      text-align: right;
    }
  }
  &__final-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0px;

    img {
      width: 20px;
    }
  }
}
.payment-check__close {
  background: #efb443;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.payment-check__close img {
  display: block;
}
.payment-check__paper {
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: -1;
  width: 100%;
}
.payment-check .img-star {
  display: inline-block;
  width: 6px;
  position: relative;
  top: -6px;
  margin-left: 1px;
  margin-right: 1px;
}
.payment-check__title {
  font-size: 16px;
  color: #282828;
  font-family: "Helvetica Neue Medium";
  margin-bottom: 10px;
}
.payment-check__total {
  border-top: 1px dashed #bfbfbf;
  padding-top: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
}
.payment-check__total span {
  color: #696969;
  font-size: 14px;
  font-weight: 500;
}
.payment-check__total b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
.payment-check__result-item {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
}
.payment-check__result-item span {
  color: #696969;
  font-size: 14px;
  font-style: italic;
}
.payment-check__result-item b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
.payment-check__helps {
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1.3;
  margin-top: 16px;
}
</style>
