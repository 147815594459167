<template>
  <section class="offlineBox">
    <svg
      class="svgParcelOffline svgParcelError"
      width="400"
      height="600"
      viewBox="0 0 375 530"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask441810045"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="375"
        height="530"
      >
        <rect width="375" height="530" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask441810045)">
        <path
          d="M68.1358 469.57C68.1358 469.57 25.5 444.57 68.1358 438.675C110.772 432.779 58.7242 406.675 2.72418 417.675C-53.2758 428.675 -62.2759 431.175 -93.2759 425.175C-124.276 419.175 -199 417.675 -199 417.675"
          stroke="#F2DDC2"
          stroke-width="3"
        />
        <path
          d="M55.8003 488.918V476.699H67.1008V481.616L77.8999 475.396C78.7535 474.915 79.7084 474.641 80.6876 474.595C81.6668 474.55 82.6431 474.734 83.5379 475.134L138.38 506.71V501.44H149.697V513.823C149.845 514.536 149.385 515.323 148.349 515.913L127.581 527.87C126.731 528.344 125.78 528.613 124.807 528.654C123.833 528.695 122.864 528.508 121.976 528.107L56.7454 490.549C56.4325 490.415 56.1714 490.184 56.0011 489.89C55.8308 489.596 55.7603 489.255 55.8003 488.918Z"
          fill="#E0CBB0"
        />
        <path
          d="M55.8002 476.699H67.1007V481.616L77.8998 475.396C78.7534 474.915 79.7083 474.641 80.6875 474.595C81.6667 474.55 82.643 474.734 83.5378 475.134L124.491 498.719V528.656C123.619 528.694 122.752 528.505 121.976 528.107L56.7453 490.549C56.431 490.414 56.1688 490.182 55.9984 489.886C55.8279 489.591 55.7584 489.248 55.8002 488.91V476.699Z"
          fill="#CEBBA1"
        />
        <path
          d="M121.975 515.544L56.7448 477.985C55.3066 477.166 55.4874 475.715 57.1476 474.757L77.9158 462.767C78.7725 462.299 79.7271 462.038 80.7031 462.004C81.6792 461.97 82.6498 462.164 83.5373 462.57L148.751 500.121C150.19 500.94 150.009 502.399 148.349 503.399L127.58 515.315C126.729 515.787 125.779 516.053 124.805 516.093C123.832 516.133 122.863 515.945 121.975 515.544Z"
          fill="#F7EED7"
        />
        <path
          d="M89.7428 481.616C85.1486 484.271 77.6615 484.255 73.0345 481.616C68.4075 478.977 68.3746 474.642 72.977 471.995C77.5793 469.348 85.0664 469.356 89.6935 471.995C94.3205 474.634 94.3452 479.002 89.7428 481.616Z"
          fill="#CEBBA1"
        />
        <path
          d="M92.5781 479.043C91.8739 480.132 90.8965 481.019 89.7427 481.616C85.1486 484.271 77.6615 484.255 73.0344 481.616C71.8447 480.982 70.846 480.043 70.1415 478.895C70.7778 477.522 71.865 476.406 73.2235 475.732C77.7354 473.192 85.0417 473.273 89.5373 475.912C90.8627 476.602 91.9293 477.7 92.5781 479.043Z"
          fill="#F2DDC2"
        />
        <path
          d="M79.6916 479.666H79.7738C79.8686 479.5 80.0081 479.363 80.1765 479.272C80.5418 479.087 80.9459 478.99 81.3559 478.99C81.7658 478.99 82.17 479.087 82.5352 479.272C82.7047 479.358 82.8448 479.492 82.9379 479.657L83.0283 479.698V480.051C82.9951 480.176 82.9344 480.293 82.8505 480.392C82.7667 480.491 82.6618 480.57 82.5434 480.624C82.1782 480.81 81.774 480.907 81.3641 480.907C80.9541 480.907 80.55 480.81 80.1847 480.624C80.0671 480.567 79.963 480.486 79.8794 480.386C79.7957 480.285 79.7345 480.168 79.6998 480.043L79.6916 479.666Z"
          fill="#5D6D89"
        />
        <path
          d="M75.607 476.429C76.0296 476.214 76.497 476.102 76.9712 476.102C77.4454 476.102 77.9129 476.214 78.3355 476.429C78.5062 476.478 78.6562 476.582 78.7629 476.724C78.8696 476.865 78.9272 477.038 78.9272 477.215C78.9272 477.393 78.8696 477.565 78.7629 477.707C78.6562 477.849 78.5062 477.952 78.3355 478.002C77.9127 478.216 77.4453 478.327 76.9712 478.327C76.4972 478.327 76.0298 478.216 75.607 478.002C74.8591 477.559 74.8509 476.855 75.607 476.429Z"
          fill="#494238"
        />
        <path
          d="M84.3588 481.477C84.7816 481.263 85.249 481.151 85.7231 481.151C86.1971 481.151 86.6645 481.263 87.0873 481.477C87.6298 481.788 87.7777 482.231 87.5394 482.624C86.7217 482.908 85.8838 483.129 85.0327 483.288C84.7997 483.24 84.5753 483.157 84.367 483.042C84.1992 482.991 84.0522 482.888 83.9475 482.747C83.8427 482.607 83.7857 482.437 83.7848 482.262C83.7839 482.087 83.8391 481.917 83.9424 481.775C84.0456 481.634 84.1916 481.529 84.3588 481.477Z"
          fill="#494238"
        />
        <path
          d="M79.9054 479.567C79.9313 479.457 79.9817 479.355 80.0529 479.267C80.124 479.179 80.214 479.108 80.3163 479.059C80.6392 478.894 80.997 478.808 81.3601 478.808C81.7231 478.808 82.0809 478.894 82.4038 479.059C82.5051 479.11 82.5946 479.182 82.6668 479.27C82.7389 479.357 82.7921 479.458 82.823 479.567C82.8311 479.63 82.8311 479.693 82.823 479.756C82.793 479.864 82.7411 479.965 82.6704 480.052C82.5997 480.14 82.5118 480.212 82.412 480.264C82.0885 480.427 81.731 480.513 81.3683 480.513C81.0056 480.513 80.648 480.427 80.3245 480.264C80.2207 480.216 80.129 480.146 80.0564 480.058C79.9839 479.97 79.9322 479.867 79.9054 479.756C79.8932 479.696 79.8932 479.635 79.9054 479.576V479.567Z"
          fill="#B5BDC6"
        />
        <path
          d="M82.823 479.567C82.8269 479.614 82.8269 479.66 82.823 479.707C82.823 479.707 82.823 479.707 82.823 479.756C82.8311 479.693 82.8311 479.63 82.823 479.567Z"
          fill="#B5BDC6"
        />
        <path
          d="M84.3596 481.477C84.7824 481.263 85.2498 481.151 85.7238 481.151C86.1979 481.151 86.6653 481.263 87.0881 481.477C87.2783 481.577 87.4374 481.727 87.5483 481.911C87.4821 481.853 87.4106 481.8 87.3347 481.755C86.9314 481.55 86.485 481.442 86.032 481.442C85.5791 481.442 85.1326 481.55 84.7294 481.755C84.0719 482.132 84.0308 482.722 84.5815 483.14L84.3678 483.042C84.2 482.991 84.053 482.888 83.9482 482.747C83.8435 482.607 83.7865 482.437 83.7856 482.262C83.7846 482.087 83.8399 481.917 83.9431 481.775C84.0464 481.634 84.1923 481.529 84.3596 481.477Z"
          fill="#CEBBA1"
        />
        <g style="mix-blend-mode: multiply" opacity="0.5">
          <path
            d="M83.0195 479.748C82.9944 479.876 82.9387 479.997 82.8572 480.099C82.7757 480.202 82.6708 480.284 82.5511 480.338C82.1844 480.523 81.7789 480.62 81.3676 480.62C80.9563 480.62 80.5508 480.523 80.1841 480.338C80.0651 480.282 79.9604 480.199 79.8779 480.097C79.7953 479.995 79.7371 479.875 79.7075 479.748H83.0195ZM80.3239 480.256C80.6474 480.419 81.0049 480.504 81.3676 480.504C81.7303 480.504 82.0878 480.419 82.4114 480.256C82.5111 480.204 82.5991 480.132 82.6697 480.044C82.7404 479.957 82.7923 479.856 82.8223 479.748H79.9047C79.931 479.859 79.9824 479.962 80.0551 480.05C80.1277 480.138 80.2197 480.209 80.3239 480.256Z"
            fill="#7C8189"
          />
        </g>
        <path
          d="M75.6067 476.429C76.0293 476.214 76.4968 476.102 76.971 476.102C77.4452 476.102 77.9127 476.214 78.3353 476.429C78.5112 476.501 78.6594 476.627 78.7584 476.79C78.8574 476.952 78.9021 477.141 78.8859 477.33C78.7909 477.122 78.6289 476.952 78.4257 476.846C78.0445 476.65 77.6218 476.548 77.1929 476.548C76.764 476.548 76.3413 476.65 75.9601 476.846C75.3848 477.174 75.3109 477.666 75.7136 478.059L75.5903 478.002C74.8588 477.559 74.8506 476.855 75.6067 476.429Z"
          fill="#CEBBA1"
        />
        <path
          d="M80.1847 480.305C80.5514 480.49 80.9569 480.587 81.3682 480.587C81.7795 480.587 82.185 480.49 82.5517 480.305C82.6739 480.251 82.7811 480.169 82.8642 480.064C82.9472 479.96 83.0036 479.837 83.0283 479.706C83.0086 479.843 82.9546 479.973 82.8713 480.083C82.7879 480.193 82.678 480.281 82.5517 480.337C82.185 480.523 81.7795 480.62 81.3682 480.62C80.9569 480.62 80.5514 480.523 80.1847 480.337C80.0572 480.278 79.9459 480.189 79.8601 480.078C79.7743 479.967 79.7165 479.836 79.6916 479.698C79.7224 479.83 79.7828 479.953 79.8682 480.058C79.9536 480.163 80.0618 480.247 80.1847 480.305Z"
          fill="#B5BDC6"
        />
        <g style="mix-blend-mode: multiply" opacity="0.4">
          <path
            d="M90.3918 481.247L90.5562 481.133L90.3918 481.247Z"
            fill="#C6AD89"
          />
        </g>
        <path
          d="M102.676 495.86C96.1495 495.825 90.8749 492.751 90.8953 488.994C90.9157 485.238 96.2233 482.221 102.75 482.256C109.277 482.292 114.552 485.365 114.531 489.122C114.511 492.879 109.203 495.895 102.676 495.86Z"
          fill="#CEBBA1"
        />
        <path
          d="M113.896 491.27C113.211 492.38 112.244 493.29 111.094 493.909C106.492 496.556 99.0127 496.548 94.3775 493.909C93.1885 493.277 92.1899 492.341 91.4845 491.196C92.1263 489.822 93.2154 488.704 94.5747 488.025C99.0867 485.484 106.393 485.566 110.889 488.213C112.188 488.892 113.241 489.961 113.896 491.27Z"
          fill="#F2DDC2"
        />
        <path
          d="M101.043 491.901H101.125C101.215 491.731 101.356 491.594 101.528 491.507C101.893 491.322 102.297 491.225 102.707 491.225C103.117 491.225 103.521 491.322 103.886 491.507C104.056 491.596 104.196 491.733 104.289 491.901H104.379C104.379 491.901 104.379 492.228 104.379 492.253C104.346 492.377 104.286 492.493 104.204 492.592C104.122 492.691 104.019 492.771 103.903 492.827C103.537 493.01 103.133 493.106 102.723 493.106C102.314 493.106 101.91 493.01 101.544 492.827C101.428 492.77 101.324 492.69 101.241 492.591C101.157 492.492 101.095 492.377 101.059 492.253L101.043 491.901Z"
          fill="#5D6D89"
        />
        <path
          d="M96.958 488.656C97.3808 488.442 97.8482 488.331 98.3223 488.331C98.7964 488.331 99.2638 488.442 99.6866 488.656C99.8541 488.708 100.001 488.812 100.105 488.952C100.209 489.093 100.265 489.264 100.265 489.438C100.265 489.613 100.209 489.784 100.105 489.924C100.001 490.065 99.8541 490.169 99.6866 490.221C99.2639 490.436 98.7965 490.547 98.3223 490.547C97.8481 490.547 97.3806 490.436 96.958 490.221C96.7905 490.169 96.6441 490.065 96.54 489.924C96.436 489.784 96.3799 489.613 96.3799 489.438C96.3799 489.264 96.436 489.093 96.54 488.952C96.6441 488.812 96.7905 488.708 96.958 488.656Z"
          fill="#494238"
        />
        <path
          d="M101.051 491.975C101.047 491.926 101.047 491.876 101.051 491.827C101.083 491.701 101.142 491.583 101.224 491.483C101.307 491.382 101.41 491.301 101.528 491.245C101.895 491.06 102.3 490.963 102.712 490.963C103.123 490.963 103.528 491.06 103.895 491.245C104.011 491.301 104.114 491.381 104.196 491.48C104.279 491.579 104.338 491.695 104.372 491.819C104.38 491.89 104.38 491.961 104.372 492.032C104.339 492.156 104.281 492.271 104.2 492.37C104.119 492.469 104.018 492.549 103.903 492.606C103.536 492.794 103.129 492.891 102.716 492.891C102.303 492.891 101.896 492.794 101.528 492.606C101.41 492.553 101.307 492.474 101.225 492.375C101.144 492.275 101.087 492.158 101.06 492.032C101.06 492.032 101.06 491.975 101.051 491.975Z"
          fill="#6B7E99"
        />
        <path
          d="M105.728 493.712C106.15 493.498 106.618 493.386 107.092 493.386C107.566 493.386 108.034 493.498 108.456 493.712C108.999 494.023 109.147 494.474 108.916 494.859C108.097 495.15 107.256 495.374 106.402 495.531C106.168 495.479 105.944 495.394 105.736 495.277C105.568 495.226 105.421 495.123 105.316 494.983C105.212 494.843 105.155 494.673 105.154 494.498C105.153 494.323 105.208 494.152 105.311 494.011C105.414 493.869 105.56 493.765 105.728 493.712Z"
          fill="#494238"
        />
        <path
          d="M101.249 491.794C101.276 491.684 101.328 491.581 101.4 491.493C101.473 491.405 101.564 491.334 101.668 491.286C101.991 491.123 102.349 491.038 102.712 491.038C103.074 491.038 103.432 491.123 103.755 491.286C103.857 491.337 103.946 491.409 104.018 491.496C104.09 491.584 104.144 491.685 104.174 491.794C104.183 491.854 104.183 491.915 104.174 491.975C104.147 492.085 104.095 492.188 104.023 492.276C103.95 492.364 103.859 492.435 103.755 492.483C103.431 492.644 103.074 492.728 102.712 492.728C102.349 492.728 101.992 492.644 101.668 492.483C101.566 492.431 101.476 492.358 101.403 492.269C101.331 492.18 101.279 492.077 101.249 491.966C101.24 491.907 101.24 491.846 101.249 491.786V491.794Z"
          fill="#B5BDC6"
        />
        <path
          d="M104.175 491.802C104.179 491.846 104.179 491.89 104.175 491.933C104.175 491.933 104.175 491.933 104.175 491.983C104.183 491.923 104.183 491.862 104.175 491.802Z"
          fill="#B5BDC6"
        />
        <path
          d="M105.728 493.712C106.15 493.498 106.618 493.386 107.092 493.386C107.566 493.386 108.034 493.498 108.456 493.712C108.649 493.815 108.811 493.968 108.925 494.155C108.859 494.092 108.784 494.039 108.703 493.999C108.301 493.794 107.856 493.688 107.404 493.688C106.953 493.688 106.508 493.794 106.106 493.999C105.448 494.376 105.399 494.974 105.958 495.384C105.881 495.358 105.807 495.325 105.736 495.285C105.565 495.237 105.415 495.134 105.307 494.993C105.2 494.851 105.141 494.679 105.14 494.502C105.139 494.324 105.196 494.152 105.302 494.009C105.408 493.867 105.557 493.763 105.728 493.712Z"
          fill="#CEBBA1"
        />
        <g style="mix-blend-mode: multiply" opacity="0.5">
          <path
            d="M104.372 491.974C104.348 492.104 104.293 492.227 104.211 492.331C104.129 492.435 104.024 492.518 103.903 492.573C103.536 492.76 103.129 492.858 102.716 492.858C102.303 492.858 101.896 492.76 101.528 492.573C101.408 492.519 101.303 492.437 101.222 492.334C101.14 492.232 101.085 492.111 101.06 491.983L104.372 491.974ZM101.668 492.491C101.992 492.652 102.349 492.736 102.711 492.736C103.074 492.736 103.431 492.652 103.755 492.491C103.859 492.443 103.95 492.372 104.023 492.284C104.095 492.196 104.147 492.093 104.174 491.983H101.257C101.287 492.091 101.339 492.192 101.409 492.279C101.48 492.367 101.568 492.439 101.668 492.491Z"
            fill="#7C8189"
          />
        </g>
        <path
          d="M96.9588 488.656C97.3816 488.442 97.849 488.331 98.3231 488.331C98.7971 488.331 99.2645 488.442 99.6873 488.656C99.8633 488.728 100.011 488.855 100.11 489.017C100.209 489.179 100.254 489.368 100.238 489.557C100.142 489.345 99.9771 489.172 99.7695 489.066C99.3888 488.868 98.9659 488.765 98.5367 488.765C98.1076 488.765 97.6847 488.868 97.304 489.066C96.7287 489.393 96.6547 489.885 97.0574 490.278L96.9341 490.221C96.7674 490.167 96.6225 490.06 96.5207 489.918C96.4188 489.776 96.3654 489.604 96.3681 489.429C96.3709 489.254 96.4297 489.085 96.536 488.946C96.6423 488.807 96.7904 488.705 96.9588 488.656Z"
          fill="#CEBBA1"
        />
        <path
          d="M101.528 492.532C101.895 492.72 102.302 492.818 102.715 492.818C103.128 492.818 103.535 492.72 103.903 492.532C104.024 492.474 104.129 492.39 104.212 492.284C104.295 492.179 104.352 492.056 104.379 491.926C104.361 492.064 104.308 492.195 104.224 492.307C104.141 492.419 104.03 492.507 103.903 492.565C103.535 492.752 103.128 492.85 102.715 492.85C102.302 492.85 101.895 492.752 101.528 492.565C101.399 492.509 101.286 492.42 101.201 492.308C101.116 492.196 101.062 492.065 101.043 491.926C101.071 492.057 101.13 492.18 101.214 492.285C101.298 492.39 101.406 492.475 101.528 492.532Z"
          fill="#B5BDC6"
        />
        <path
          d="M104.371 491.983H101.084C101.08 491.936 101.08 491.89 101.084 491.844H104.371C104.376 491.89 104.376 491.937 104.371 491.983Z"
          fill="#5D6D89"
        />
        <g style="mix-blend-mode: multiply" opacity="0.4">
          <path
            d="M111.743 493.474L111.899 493.368L111.743 493.474Z"
            fill="#C6AD89"
          />
        </g>
        <path
          d="M127.096 514.733L147.346 503.055C148.834 502.235 148.826 500.793 147.346 499.932L83.8993 463.415C83.0585 462.991 82.1295 462.77 81.1872 462.77C80.2449 462.77 79.3159 462.991 78.4751 463.415L58.1917 475.06C56.7042 475.88 56.7124 477.314 58.1917 478.182L121.639 514.725C122.484 515.154 123.418 515.378 124.366 515.379C125.315 515.381 126.25 515.159 127.096 514.733ZM147.1 500.064C148.464 500.85 148.464 502.104 147.116 502.924L126.833 514.602C126.067 514.983 125.223 515.181 124.367 515.181C123.512 515.181 122.668 514.983 121.902 514.602L58.4712 478.084C57.0987 477.265 57.0904 476.011 58.4712 475.224L78.7052 463.538C79.4704 463.155 80.3147 462.955 81.1708 462.955C82.0268 462.955 82.8711 463.155 83.6363 463.538L147.1 500.064Z"
          fill="#C6AD89"
        />
        <path
          d="M132.38 506.112C127.778 508.767 120.291 508.751 115.664 506.112C111.037 503.473 111.012 499.138 115.614 496.491C120.217 493.843 127.696 493.852 132.323 496.491C136.95 499.129 136.958 503.465 132.38 506.112Z"
          fill="#CEBBA1"
        />
        <path
          d="M135.183 503.505C134.491 504.601 133.525 505.499 132.381 506.111C127.778 508.767 120.291 508.75 115.664 506.111C114.476 505.474 113.478 504.536 112.771 503.39C113.413 502.021 114.499 500.906 115.853 500.227C120.373 497.687 127.68 497.769 132.175 500.407C133.482 501.095 134.536 502.18 135.183 503.505Z"
          fill="#F2DDC2"
        />
        <path
          d="M122.329 504.128H122.411C122.506 503.962 122.645 503.826 122.814 503.735C123.179 503.549 123.583 503.452 123.993 503.452C124.403 503.452 124.807 503.549 125.172 503.735C125.34 503.823 125.479 503.956 125.575 504.12L125.657 504.161C125.657 504.161 125.657 504.497 125.657 504.513C125.625 504.638 125.565 504.754 125.483 504.853C125.401 504.952 125.297 505.032 125.181 505.087C124.815 505.272 124.411 505.369 124.001 505.369C123.591 505.369 123.187 505.272 122.822 505.087C122.703 505.03 122.598 504.948 122.513 504.848C122.428 504.748 122.365 504.631 122.329 504.505V504.128Z"
          fill="#5D6D89"
        />
        <path
          d="M118.236 500.891C118.658 500.676 119.126 500.565 119.6 500.565C120.074 500.565 120.542 500.676 120.964 500.891C121.135 500.941 121.285 501.044 121.392 501.186C121.498 501.328 121.556 501.5 121.556 501.678C121.556 501.855 121.498 502.028 121.392 502.169C121.285 502.311 121.135 502.415 120.964 502.464C120.542 502.678 120.074 502.79 119.6 502.79C119.126 502.79 118.659 502.678 118.236 502.464C118.065 502.415 117.915 502.311 117.809 502.169C117.702 502.028 117.644 501.855 117.644 501.678C117.644 501.5 117.702 501.328 117.809 501.186C117.915 501.044 118.065 500.941 118.236 500.891Z"
          fill="#494238"
        />
        <path
          d="M122.337 504.202C122.329 504.151 122.329 504.098 122.337 504.046C122.365 503.921 122.422 503.803 122.503 503.704C122.584 503.604 122.688 503.525 122.806 503.473C123.172 503.287 123.578 503.19 123.989 503.19C124.4 503.19 124.806 503.287 125.173 503.473C125.29 503.528 125.394 503.61 125.477 503.71C125.559 503.811 125.618 503.928 125.649 504.055C125.666 504.122 125.666 504.192 125.649 504.259C125.62 504.385 125.562 504.502 125.481 504.603C125.4 504.704 125.297 504.785 125.181 504.841C124.814 505.025 124.408 505.121 123.997 505.121C123.586 505.121 123.181 505.025 122.814 504.841C122.698 504.784 122.597 504.702 122.516 504.601C122.435 504.501 122.377 504.384 122.345 504.259L122.337 504.202Z"
          fill="#6B7E99"
        />
        <path
          d="M127.014 505.939C127.436 505.725 127.904 505.613 128.378 505.613C128.852 505.613 129.32 505.725 129.742 505.939C130.285 506.251 130.433 506.701 130.203 507.087C129.382 507.37 128.541 507.592 127.688 507.75C127.455 507.701 127.231 507.618 127.022 507.505C126.854 507.454 126.707 507.35 126.602 507.21C126.498 507.07 126.441 506.9 126.44 506.725C126.439 506.55 126.494 506.379 126.597 506.238C126.701 506.097 126.847 505.992 127.014 505.939Z"
          fill="#494238"
        />
        <path
          d="M122.534 504.03C122.56 503.92 122.611 503.817 122.682 503.729C122.753 503.641 122.843 503.57 122.945 503.522C123.268 503.356 123.626 503.27 123.989 503.27C124.352 503.27 124.71 503.356 125.033 503.522C125.136 503.57 125.228 503.64 125.3 503.728C125.373 503.816 125.425 503.919 125.452 504.03C125.468 504.092 125.468 504.156 125.452 504.218C125.422 504.326 125.37 504.427 125.3 504.515C125.229 504.602 125.141 504.674 125.041 504.726C124.718 504.89 124.36 504.975 123.997 504.975C123.635 504.975 123.277 504.89 122.954 504.726C122.852 504.675 122.763 504.604 122.691 504.516C122.619 504.429 122.565 504.327 122.534 504.218C122.526 504.158 122.526 504.098 122.534 504.038V504.03Z"
          fill="#B5BDC6"
        />
        <path
          d="M125.452 504.03C125.46 504.076 125.46 504.123 125.452 504.169C125.456 504.186 125.456 504.202 125.452 504.219C125.468 504.157 125.468 504.092 125.452 504.03Z"
          fill="#B5BDC6"
        />
        <path
          d="M127.013 505.939C127.436 505.725 127.904 505.613 128.378 505.613C128.852 505.613 129.319 505.725 129.742 505.939C129.934 506.04 130.096 506.19 130.21 506.374C130.141 506.315 130.067 506.263 129.989 506.218C129.585 506.012 129.139 505.905 128.686 505.905C128.233 505.905 127.787 506.012 127.383 506.218C126.726 506.595 126.685 507.185 127.235 507.603L127.022 507.505C126.854 507.454 126.707 507.35 126.602 507.21C126.497 507.07 126.44 506.9 126.439 506.725C126.439 506.55 126.494 506.379 126.597 506.238C126.7 506.097 126.846 505.992 127.013 505.939Z"
          fill="#CEBBA1"
        />
        <g style="mix-blend-mode: multiply" opacity="0.5">
          <path
            d="M125.649 504.21C125.625 504.34 125.57 504.462 125.488 504.567C125.407 504.671 125.301 504.754 125.18 504.808C124.813 504.992 124.408 505.088 123.997 505.088C123.586 505.088 123.181 504.992 122.814 504.808C122.696 504.751 122.592 504.668 122.509 504.566C122.427 504.464 122.368 504.346 122.337 504.218L125.649 504.21ZM122.953 504.718C123.277 504.882 123.634 504.967 123.997 504.967C124.36 504.967 124.717 504.882 125.041 504.718C125.141 504.666 125.228 504.594 125.299 504.507C125.37 504.419 125.422 504.318 125.452 504.21H122.542C122.572 504.323 122.627 504.428 122.704 504.516C122.781 504.605 122.878 504.674 122.986 504.718H122.953Z"
            fill="#7C8189"
          />
        </g>
        <path
          d="M118.236 500.891C118.659 500.676 119.126 500.565 119.6 500.565C120.075 500.565 120.542 500.676 120.965 500.891C121.141 500.962 121.29 501.089 121.389 501.251C121.489 501.413 121.533 501.603 121.515 501.792C121.422 501.583 121.26 501.412 121.055 501.309C120.674 501.113 120.251 501.01 119.822 501.01C119.393 501.01 118.971 501.113 118.59 501.309C118.022 501.637 117.94 502.128 118.351 502.522L118.22 502.464C118.049 502.413 117.901 502.308 117.795 502.165C117.69 502.022 117.634 501.849 117.636 501.672C117.638 501.494 117.697 501.322 117.806 501.182C117.914 501.041 118.065 500.939 118.236 500.891Z"
          fill="#CEBBA1"
        />
        <path
          d="M122.813 504.767C123.18 504.953 123.585 505.05 123.997 505.05C124.408 505.05 124.813 504.953 125.18 504.767C125.304 504.715 125.413 504.633 125.497 504.529C125.582 504.424 125.639 504.301 125.665 504.169C125.646 504.308 125.591 504.44 125.506 504.552C125.421 504.664 125.309 504.752 125.18 504.808C124.813 504.992 124.408 505.088 123.997 505.088C123.586 505.088 123.18 504.992 122.813 504.808C122.687 504.749 122.577 504.659 122.492 504.548C122.408 504.437 122.352 504.307 122.328 504.169C122.359 504.299 122.419 504.42 122.503 504.523C122.587 504.627 122.693 504.71 122.813 504.767Z"
          fill="#B5BDC6"
        />
        <path
          d="M125.649 504.218H122.362C122.357 504.172 122.357 504.125 122.362 504.079H125.649C125.658 504.125 125.658 504.172 125.649 504.218Z"
          fill="#5D6D89"
        />
        <g style="mix-blend-mode: multiply" opacity="0.4">
          <path
            d="M133.022 505.709L133.186 505.595L133.022 505.709Z"
            fill="#C6AD89"
          />
        </g>
        <path
          opacity="0.1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60 318.01L186.5 392L313 318.01L186.5 244L60 318.01Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M258.179 263.512V326.122L186.629 361.892L115.079 326.122V263.512L186.629 227.732L258.179 263.512Z"
          fill="url(#paint424000_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M186.629 227.732V361.892L115.079 326.122V263.512L186.629 227.732Z"
          fill="url(#paint1110009_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M285.019 232.206L258.179 263.506L186.629 227.736L213.459 196.426L285.019 232.206Z"
          fill="url(#paint255800_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M88.2789 232.186L115.119 263.486L186.669 227.716L159.839 196.406L88.2789 232.186Z"
          fill="url(#paint3336800_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 257.565V272.192L186.688 306.112L119.516 272.353V257.565L186.65 223.777L253.784 257.565Z"
          fill="#262424"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 257.565L186.65 291.343L119.516 257.565L186.65 223.777L253.784 257.565Z"
          fill="#2E2E2E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 272.192L186.65 306.131V291.343L253.784 257.565V272.192Z"
          fill="#191919"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.686 293.615C181.097 294.346 181.566 295.448 181.566 296.52C181.566 297.594 180.635 298.02 179.686 297.578C178.296 296.929 177.804 295.598 177.804 294.526C177.804 293.452 178.755 293.131 179.686 293.615Z"
          fill="#6CB310"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 240.565V255.192L186.688 289.112L119.516 255.353V240.565L186.65 206.777L253.784 240.565Z"
          fill="#262424"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 240.565L186.65 274.343L119.516 240.565L186.65 206.777L253.784 240.565Z"
          fill="#2E2E2E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 255.192L186.65 289.131V274.343L253.784 240.565V255.192Z"
          fill="#191919"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.686 276.615C181.097 277.346 181.566 278.448 181.566 279.52C181.566 280.594 180.635 281.02 179.686 280.578C178.296 279.929 177.804 278.598 177.804 277.526C177.804 276.452 178.755 276.131 179.686 276.615Z"
          fill="#6CB310"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 223.788V238.415L186.688 272.336L119.516 238.576V223.788L186.65 190L253.784 223.788Z"
          fill="#262424"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 223.788L186.65 257.566L119.516 223.788L186.65 190L253.784 223.788Z"
          fill="#2E2E2E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M253.784 238.415L186.65 272.354V257.566L253.784 223.788V238.415Z"
          fill="#191919"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.686 260.269C181.097 261 181.566 262.102 181.566 263.174C181.566 264.247 180.635 264.674 179.686 264.232C178.296 263.583 177.804 262.252 177.804 261.18C177.804 260.106 178.755 259.784 179.686 260.269Z"
          fill="#6CB310"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M258.179 326.116L186.629 361.896V299.286L258.179 263.506V326.116Z"
          fill="url(#paint4445700_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.069 326.116L186.629 361.896V299.286L115.069 263.506V326.116Z"
          fill="url(#paint5553800_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M258.179 263.506L285.019 285.866L213.459 321.646L186.629 299.286L258.179 263.506Z"
          fill="url(#paint6547800_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.119 263.486L88.2789 285.846L159.839 321.626L186.669 299.266L115.119 263.486Z"
          fill="url(#paint774900_linear)"
        />
        <path
          d="M388 360.019C388 360.019 342 373.019 317 388.518C292 404.018 382 413.519 309.5 434.519C297.632 437.956 279.5 439.96 267.017 447.712"
          stroke="#262424"
          stroke-width="3"
        />
        <g clip-path="url(#clip584)">
          <path
            d="M255.705 461.346L249.416 443.222C259.546 441.387 257.072 441.841 257.574 441.729C260.179 441.136 263.767 442.927 265.428 447.713C267.089 452.5 265.362 455.588 263.562 457.121C263.28 457.368 262.441 457.765 255.705 461.346Z"
            fill="#262424"
          />
          <path
            d="M268.787 449.248L267.316 445.011L260.906 446.744L262.681 451.858L268.787 449.248Z"
            fill="#262424"
          />
          <path
            d="M252.704 467.034C249.152 468.292 244.191 463.4 241.623 456.107C239.054 448.814 239.852 441.882 243.403 440.623C246.955 439.365 251.916 444.258 254.485 451.551C257.053 458.844 256.256 465.776 252.704 467.034Z"
            fill="#191919"
          />
          <path
            d="M247.571 449.605C247.613 449.026 247.532 448.429 247.336 447.864C247.139 447.298 246.833 446.78 246.442 446.351C245.692 445.597 244.98 445.845 244.858 446.901C244.817 447.48 244.897 448.077 245.094 448.642C245.29 449.207 245.596 449.726 245.987 450.155C246.733 450.897 247.441 450.652 247.571 449.605Z"
            fill="#343742"
          />
          <path
            d="M246.627 450.244L236.497 453.759C236.066 453.873 235.597 453.798 235.178 453.548C234.758 453.299 234.418 452.891 234.22 452.403C234.072 451.898 234.087 451.368 234.263 450.913C234.438 450.459 234.762 450.112 235.171 449.939L245.386 446.394C245.535 446.349 245.697 446.355 245.853 446.411C246.009 446.467 246.153 446.571 246.267 446.71C246.594 447.068 246.849 447.501 247.013 447.973C247.177 448.445 247.244 448.942 247.21 449.426C247.198 449.62 247.136 449.8 247.033 449.945C246.93 450.09 246.789 450.194 246.627 450.244Z"
            fill="#B0B7C9"
          />
          <path
            d="M246.627 450.244L236.497 453.759C236.066 453.873 235.597 453.798 235.178 453.548C234.759 453.299 234.418 452.891 234.22 452.403C234.179 452.281 234.148 452.156 234.127 452.031C234.368 452.423 234.701 452.732 235.082 452.916C235.463 453.101 235.873 453.151 236.254 453.059L246.047 449.661C246.25 449.612 246.434 449.502 246.58 449.342C246.726 449.183 246.829 448.979 246.881 448.75C246.905 448.338 246.836 447.915 246.68 447.521C246.524 447.126 246.286 446.774 245.988 446.496C246.09 446.557 246.185 446.633 246.272 446.721C246.598 447.079 246.853 447.512 247.017 447.984C247.181 448.456 247.248 448.953 247.214 449.437C247.207 449.633 247.147 449.815 247.042 449.96C246.937 450.104 246.792 450.203 246.627 450.244Z"
            fill="#868B9B"
          />
          <path
            d="M251.427 460.716C251.468 460.136 251.387 459.538 251.19 458.972C250.994 458.406 250.688 457.886 250.296 457.456C249.548 456.708 248.833 456.95 248.712 458.006C248.67 458.585 248.751 459.182 248.947 459.747C249.144 460.313 249.45 460.831 249.841 461.26C250.587 462.003 251.297 461.763 251.427 460.716Z"
            fill="#343742"
          />
          <path
            d="M250.481 461.349L240.351 464.864C239.92 464.976 239.452 464.9 239.033 464.65C238.614 464.401 238.273 463.995 238.074 463.508C237.925 463.004 237.94 462.473 238.116 462.02C238.292 461.566 238.617 461.22 239.027 461.05L249.241 457.505C249.391 457.462 249.553 457.469 249.709 457.525C249.864 457.581 250.008 457.683 250.123 457.821C250.45 458.18 250.706 458.614 250.87 459.086C251.034 459.559 251.102 460.058 251.067 460.542C251.055 460.736 250.993 460.915 250.889 461.058C250.785 461.202 250.643 461.303 250.481 461.349Z"
            fill="#B0B7C9"
          />
          <path
            d="M250.481 461.349L240.351 464.864C239.92 464.976 239.452 464.9 239.033 464.651C238.614 464.401 238.273 463.995 238.073 463.508C238.033 463.388 238.003 463.266 237.983 463.142C238.223 463.534 238.556 463.843 238.937 464.026C239.318 464.21 239.727 464.258 240.108 464.165L249.9 460.767C250.103 460.716 250.286 460.606 250.432 460.447C250.578 460.287 250.682 460.084 250.734 459.855C250.759 459.443 250.69 459.02 250.534 458.626C250.377 458.232 250.139 457.879 249.842 457.601C249.943 457.661 250.037 457.735 250.123 457.821C250.45 458.18 250.706 458.614 250.87 459.086C251.034 459.559 251.101 460.058 251.067 460.542C251.061 460.738 251.002 460.921 250.897 461.066C250.792 461.21 250.646 461.309 250.481 461.349Z"
            fill="#868B9B"
          />
          <path
            d="M253.01 466.911C254.482 466.219 255.55 464.424 255.901 461.621C256.608 455.608 253.687 447.234 249.356 442.913C247.26 440.823 245.222 440.08 243.592 440.559L246.625 439.506C246.737 439.467 246.85 439.428 246.962 439.389L247.211 439.303C248.794 438.977 250.737 439.747 252.732 441.742C257.056 446.046 259.973 454.422 259.251 460.439C258.915 463.238 257.851 465.031 256.36 465.729C256.145 465.829 255.921 465.907 255.69 465.962L253.01 466.911Z"
            fill="#262424"
          />
          <path
            d="M256.066 465.85C257.543 465.157 258.606 463.363 258.957 460.56C259.651 454.552 256.743 446.173 252.413 441.853C250.422 439.868 248.484 439.097 246.892 439.414C246.916 439.408 246.94 439.4 246.962 439.389L247.211 439.303C248.794 438.977 250.737 439.747 252.731 441.742C257.056 446.046 259.973 454.422 259.251 460.439C258.915 463.238 257.851 465.031 256.36 465.729C256.2 465.797 256.036 465.854 255.868 465.9C255.946 465.898 256.007 465.877 256.066 465.85Z"
            fill="#2C2F38"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint424000_linear"
          x1="256.672"
          y1="360.333"
          x2="267.77"
          y2="243.779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6803A" />
          <stop offset="1" stop-color="#A4601D" />
        </linearGradient>
        <linearGradient
          id="paint1110009_linear"
          x1="197.091"
          y1="318.547"
          x2="137.811"
          y2="255.973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B5F1F" />
          <stop offset="1" stop-color="#824C1B" />
        </linearGradient>
        <linearGradient
          id="paint255800_linear"
          x1="254.642"
          y1="257.184"
          x2="279.826"
          y2="192.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEC089" />
          <stop offset="1" stop-color="#C18B54" />
        </linearGradient>
        <linearGradient
          id="paint3336800_linear"
          x1="157.655"
          y1="272.8"
          x2="129.923"
          y2="217.967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEC089" />
          <stop offset="1" stop-color="#C18B54" />
        </linearGradient>
        <linearGradient
          id="paint4445700_linear"
          x1="248.848"
          y1="338.679"
          x2="240.434"
          y2="278.866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B5F1F" />
          <stop offset="1" stop-color="#824C1B" />
        </linearGradient>
        <linearGradient
          id="paint5553800_linear"
          x1="123.858"
          y1="337.882"
          x2="133.486"
          y2="278.576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6803A" />
          <stop offset="1" stop-color="#A4601D" />
        </linearGradient>
        <linearGradient
          id="paint6547800_linear"
          x1="170.65"
          y1="292.797"
          x2="190.897"
          y2="339.224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEC089" />
          <stop offset="1" stop-color="#C18B54" />
        </linearGradient>
        <linearGradient
          id="paint7749_linear"
          x1="197.432"
          y1="294.428"
          x2="172.657"
          y2="365.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEC089" />
          <stop offset="1" stop-color="#C18B54" />
        </linearGradient>
        <clipPath id="clip584">
          <rect
            width="42.6886"
            height="55.6673"
            fill="white"
            transform="matrix(0.403614 0.91493 0.91493 -0.403614 231 446.428)"
          />
        </clipPath>
      </defs>
    </svg>
    <div class="textBox">
      <h3>{{ $t('connectionError') }}</h3>
      <h4>{{ $t('connectionErrorInfo') }}</h4>
    </div>
    <div id="spinnerBars">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
      <div class="bar6"></div>
      <div class="bar7"></div>
      <div class="bar8"></div>
    </div>
  </section>
</template>

<script>
export default {
    name: "ConnectionError"
};
</script>

<style>
</style>