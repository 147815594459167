<template>
  <div class="preloaderScreen">
    <svg
      class="preloaderLogo"
      width="200"
      height="66"
      viewBox="0 0 200 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M150.629 39.2605C150.629 42.4627 153.387 43.5573 155.266 43.5573C157.647 43.5573 158.691 43.1126 160.488 41.2471L165.836 43.8415C163.412 47.775 159.485 49.2724 154.724 49.2724C148.248 49.2724 142.942 44.3258 142.942 38.287C142.942 32.2063 148.248 27.2597 154.724 27.2597C161.242 27.2597 166.213 30.9882 166.213 38.287C166.213 38.5712 166.213 39.0159 166.172 39.2605H150.629ZM158.984 35.2874C158.65 33.0983 157.104 31.9221 154.93 31.9221C152.381 31.9221 150.96 33.3824 150.835 35.2874H158.984Z"
        fill="white"
      />
      <path
        d="M53.3558 49.2738C49.4141 49.2738 46.198 47.5738 43.7102 44.169C42.9692 43.156 42.4625 41.9897 42.2435 40.7642C41.3548 35.783 43.0278 31.8321 47.2675 28.9116C48.3166 28.1876 49.5286 27.7033 50.7942 27.4859C55.0135 26.7644 58.5402 27.7651 61.3768 30.483C63.5922 32.6327 64.6973 35.227 64.6973 38.8073V49.2713C64.6973 49.2738 54.7054 49.2738 53.3558 49.2738ZM53.3558 33.159C52.6327 33.159 51.9579 33.2924 51.3315 33.5568C50.7051 33.8236 50.1627 34.1819 49.7043 34.6316C49.2435 35.0813 48.8793 35.6125 48.612 36.2179C48.342 36.8257 48.2096 37.4706 48.2096 38.1525C48.2096 38.8345 48.3446 39.4818 48.612 40.0872C48.8819 40.695 49.246 41.2238 49.7043 41.6735C50.1627 42.1232 50.7076 42.4814 51.3315 42.7483C51.9579 43.0151 52.6327 43.1461 53.3558 43.1461C54.0688 43.1461 54.7385 43.0127 55.3649 42.7483C55.9913 42.4839 56.5362 42.1232 56.9997 41.666C57.4631 41.2114 57.8298 40.6827 58.0997 40.0798C58.3696 39.4769 58.502 38.8345 58.502 38.1525C58.502 37.4706 58.367 36.8257 58.0997 36.2253C57.8298 35.6224 57.4631 35.0936 56.9997 34.639C56.5362 34.1844 55.9888 33.8236 55.3649 33.5568C54.7385 33.2924 54.0688 33.159 53.3558 33.159Z"
        fill="white"
      />
      <path
        d="M85.631 28.8825L83.2068 33.5845C83.2068 33.5845 80.7012 32.2874 78.4452 32.2874C76.8155 32.2874 76.1484 32.5715 76.1484 33.545C76.1484 34.6396 77.6532 34.9633 79.4917 35.5316C82.3334 36.384 86.0104 37.9233 86.0104 42.1806C86.0104 48.3009 80.2454 49.2744 75.8581 49.2744C70.6788 49.2744 67.585 46.4774 67.585 46.4774L70.5922 41.5728C70.5922 41.5728 73.5154 43.9645 75.8988 43.9645C76.9428 43.9645 78.1956 43.8435 78.1956 42.4647C78.1956 40.9649 75.8555 40.8439 73.4339 39.6258C71.2619 38.5312 69.0899 37.0734 69.0899 33.9502C69.0899 29.693 72.8075 27.2617 78.4477 27.2617C82.5804 27.2617 85.631 28.8825 85.631 28.8825Z"
        fill="white"
      />
      <path
        d="M114.456 27.8695L97.6191 59.2047H89.0965L96.4911 45.4223L86.3387 27.8695H94.8614L100.835 38.5312L105.93 27.8695H114.456Z"
        fill="white"
      />
      <path
        d="M121.219 50.8952C122.013 52.1529 123.809 53.5712 127.025 53.5712C129.072 53.5712 132.247 52.395 132.247 48.7061V47.1667C130.661 48.8692 128.153 49.2744 125.229 49.2744C118.963 49.2744 114.575 44.3698 114.575 38.289C114.575 32.2083 118.963 27.2617 125.229 27.2617C128.071 27.2617 130.368 28.1536 132.122 29.735H132.247V27.8695H139.642V49.2324C139.642 56.2051 134.335 59.8125 127.025 59.8125C122.556 59.8125 117.415 58.839 115.161 53.7713L121.219 50.8952ZM127.445 43.1516C130.327 43.1516 132.415 41.1255 132.415 38.2865C132.415 35.408 130.202 33.3819 127.445 33.3819C124.646 33.3819 122.431 35.408 122.431 38.2865C122.431 41.1255 124.437 43.1516 127.445 43.1516Z"
        fill="white"
      />
      <path
        d="M24.1823 39.2605C24.1823 42.4627 26.94 43.5573 28.8192 43.5573C31.2001 43.5573 32.2466 43.1126 34.0418 41.2471L39.3891 43.8415C36.965 47.775 33.0385 49.2724 28.2769 49.2724C21.8015 49.2724 16.4949 44.3258 16.4949 38.287C16.4949 32.2063 21.8015 27.2597 28.2769 27.2597C34.7955 27.2597 39.766 30.9882 39.766 38.287C39.766 38.5712 39.766 39.0159 39.7253 39.2605H24.1823ZM32.5369 35.2874C32.2033 33.0983 30.6577 31.9221 28.4831 31.9221C25.9342 31.9221 24.5133 33.3824 24.3886 35.2874H32.5369Z"
        fill="white"
      />
      <path
        d="M170.763 20.1254H178.158V27.869H182.67V33.3419H178.158V39.949C178.158 41.896 178.66 42.8275 179.828 42.8275C180.788 42.8275 181.667 42.1776 181.667 42.1776L183.505 47.9742C183.505 47.9742 181.124 49.2714 178.074 49.2714C172.184 49.2714 170.763 45.0142 170.763 41.3252V33.3395H167.672V27.8665H170.763V20.1254Z"
        fill="white"
      />
      <path
        d="M178.158 18.0055H146.746V6.1875H173.253C175.963 6.1875 178.158 8.31737 178.158 10.9463V18.0055Z"
        fill="#282828"
      />
    </svg>
    <div class="spinnerArc"></div>
  </div>
</template>

<script>
export default {
    name: "PreloaderScreen"
};
</script>

<style>
</style>