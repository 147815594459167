




























































































































































































































































/* eslint-disable */
import Vue from "vue";
import { Prop } from "vue/types/options";

import {
  getConvertedPayment,
  addFunds,
  fetchObjectsList,
} from "../../services/api-connector";
import eventsRegister from "@/services/events-register";
import { isPhoneCorrect } from "../../utils/form-validators";
import InputText from "../controls/InputText.vue";
import { logEventFA, logEventFB } from "../../utils/utils";

// Stripe pay
const Stripe: any = window["Stripe"];
let stripe: any;
let paymentIntent: any;
let card: any;

/* ------- Post-payment helpers ------- */
/* Shows a success / error message when the payment is complete */
function orderComplete(clientSecret: any) {
  // Just for the purpose of the sample, show the PaymentIntent response object
  stripe.retrievePaymentIntent(clientSecret).then(function(result: any) {
    let paymentIntent: any = result.paymentIntent;
    let paymentIntentJson: any = JSON.stringify(paymentIntent, null, 2);

    let srPaymentForm: any = document.querySelector(".sr-payment-form");
    srPaymentForm.classList.add("hidden");
    let srResult: any = document.querySelector(".sr-result");
    let srPaymentFormPre: any = srResult.querySelector("pre");
    srPaymentFormPre.textContent = paymentIntentJson;

    srResult.classList.remove("hidden");
    setTimeout(function() {
      srResult.classList.add("expand");
    }, 200);

    changeLoadingState(false);
  });
}

function showError(errorMsgText: any) {
  changeLoadingState(false);
  let errorMsg: any = document.querySelector(".sr-field-error");
  errorMsg.textContent = errorMsgText;
  setTimeout(() => {
    errorMsg.textContent = "";
  }, 4000);
}

// Show a spinner on payment submission
function changeLoadingState(isLoading: any) {
  let submitBtn: any = document.querySelector("#submit");
  let spinner: any = document.querySelector("#spinner");
  let buttonText: any = document.querySelector("#button-text");
  if (isLoading) {
    submitBtn.disabled = true;
    spinner.classList.remove("hidden");
    buttonText.classList.add("hidden");
  } else {
    submitBtn.disabled = false;
    spinner.classList.add("hidden");
    buttonText.classList.remove("hidden");
  }
}

export default Vue.extend({
  components: { InputText },
  name: "PopupAddFunds",
  props: {
    popupbody: Object as Prop<any>,
    appdata: Object as Prop<any>,
    appstate: Object as Prop<any>,
  },
  data() {
    return {
      eventsRegister,

      activeCardCountry: 1, // 0 - global, 1 - ua
      activeForm: "prePay", // or cardForm or payBank
      isBankLinkVisible: false, // iOs only
      bankLink: "", // iOs only

      isPaySuccessVisible: false,
      isBalanceChanged: false,
      isGooglePayActive: false, // googlPay

      amountConverted: 0,
      bankFee: 0,
      isConvertedPaymentLoading: false,
      isConvertedPaymentError: false,
      curConvertedPaymentTimeStamp: 0, // prevent case when old requestResponse override value of new requestResponse (async requests)
      exchangeRate: -1, // -1 is default value

      moneyAmountStr: "",
      maxMoneyAmount: 800,

      payBtnText: this.$t("pay"),
      isPaynBtnStatusWait: false,

      regexDigitsAndDot: /^[0-9]+\.?[0-9]*$/,
      // Form verification
      isFormChecked: false,
    };
  },

  created() {
    // check for pay value prop (only positive)
    if (this.popupbody["payValue"] && this.popupbody["payValue"] > 0)
      this.moneyAmountStr = this.popupbody["payValue"].toFixed(2);

    // check google pay
    // if (this.appstate.platform !== 'ios') {
    // if (this.appstate.platform === 'android') {
    //     const sgap: any = window['sgap']
    //     let self = this
    //     if (sgap) {
    //         // get stripe key
    //         fetchObjectsList(
    //             {
    //                 "ClassList":[{"Class_id":-21104,"TypeLoad":0,"needLinkObject":0}],
    //                 "Filter":[{"Class_id":-21104,"Filter_id":1,"Node_id":1,"Property_id":-619,"Operator_id":"=","Value": 'Stripe'}],
    //                 "PropertyList":[{"Class_id":-21104,"Property_id":-259}]
    //             },
    //             (responseData: any)=>{ // N1_21104__1: [{Object_id:2826296,State_id:-5001,CalcName:"Stripe", pr_259:"pk_test_v8vRfUdH9fdgfd"}]
    //                 if (responseData['N1_21104__1'] && responseData['N1_21104__1'].length) {
    //                     // check google pay
    //                     let stripeDBObj = responseData['N1_21104__1'][0]
    //                     sgap.setKey( 'pk_test_v8vRfUdH9fdgfd' ).then((output: any)=>{
    //                         sgap.isReadyToPay().then(()=>{
    //                             self.isGooglePayActive = true
    //                         }).catch((err: any)=>{
    //                             console.warn(err)
    //                             this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: err })
    //                         })
    //                     }).catch((err: any)=>{
    //                         console.warn(err)
    //                         this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: err })
    //                     })
    //                 } else {
    //                     this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: 'Error data response Stripe key' })
    //                     console.log('Error data response Stripe key: ', responseData)
    //                 }
    //             }, ()=>{ // not auth callback
    //                 this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: 'Error get Stripe key' })
    //                 console.warn('Error get Stripe key')
    //             }, ()=>{ // server error callback
    //                 this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: 'Error get Stripe key' })
    //                 console.warn('Error get Stripe key')
    //             }, ()=>{ // error connection
    //                 this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: 'Error get Stripe key' })
    //                 console.warn('Error get Stripe key')
    //             }
    //         )
    //     } else {
    //         this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: 'No Google Pay' })
    //         console.warn('No Google Pay')
    //     }
    // }
  },

  // Cleanup stripe - remove iframes
  destroyed() {
    // https://stackoverflow.com/questions/55119026/how-to-remove-stripes-iframes
    // https://github.com/stripe/react-stripe-elements/issues/99
    // document.querySelectorAll('[name^=__privateStripeMetricsController]'), // metrics have been added once on page loading
    // document.querySelectorAll('[name^=__privateStripeController]'), // iframes have been added many times on new payment form mounted
    // iframe.parentNode.removeChild(iframe);
    // let iframeEls: NodeList = document.querySelectorAll('[name^=__privateStripe]')
    let iframeEls: NodeList = document.querySelectorAll(
      "[name^=__privateStripeController]"
    );
    iframeEls.forEach((el: any) => {
      // HTMLIFrameElement
      el.remove();
    });
  },

  computed: {
    moneyAmount(): number {
      let amountParsed = parseFloat(this.moneyAmountStr);
      if (amountParsed) return amountParsed;
      else return 0;
    },

    isMoneyAmountCorrect(): boolean {
      // replace coma to dot
      if (this.moneyAmountStr.indexOf(",") >= 0)
        this.moneyAmountStr = this.moneyAmountStr.replace(",", ".");
      if (!this.moneyAmountStr.match(this.regexDigitsAndDot)) return false;
      if (this.moneyAmountStr.match(/^[0-9]+\.[0-9]{3}/)) return false; // check max 2 digits after coma/dot. If > 2 digits after coma - incorrect
      if (parseFloat(this.moneyAmountStr) > 1) return true;
      // check min amount
      else return false;
    },

    isMoneyUAMaxAmount(): boolean {
      if (this.activeCardCountry === 0) return false; // Only for uaPay. Exit if global pay
      if (
        this.isMoneyAmountCorrect &&
        parseFloat(this.moneyAmountStr) > this.maxMoneyAmount
      )
        return true;
      else return false;
    },
  },

  methods: {
    payBtnHandler() {
      this.isFormChecked = true;
      if (this.isMoneyAmountCorrect) {
        this.payBtnText = this.$t("processing");
        this.isPaynBtnStatusWait = true;

        // let dataForSend = {
        //     "ClientCode": this.appdata.sm_customer.pr236,
        //     "Amount": this.moneyAmountStr,
        //     "ClientCellPhone": this.appdata.sm_customer.st_11010 || '',
        //     // "ClientEmail": this.appdata.sm_customer.st_11237,
        //     "ClientEmail": 'qa.utec@gmail.com',
        //     "PayCountryId": 0 // 0 - global, 1 - ua
        // }
        let dataForSend = {
          Amount: this.moneyAmountStr,
          ClientCellPhone: this.appdata.sm_customer.st_11010 || "",
          ClientCode: this.appdata.sm_customer.pr236,
          // "ClientEmail": 'qa.utec@gmail.com',
          PayCountryId: 3,
          SiteId: 1,
        };
        addFunds(
          dataForSend,
          (responseData: any) => {
            // Success callback function
            // {stripe: { paymentIntent: {clientSecret: "pi_1GBhInK...", publishableKey: "pk_test_nX..."} } }
            if (responseData["stripe"]["paymentIntent"]) {
              this.activeForm = "cardForm";
              // setupElements( responseData['stripe']['paymentIntent'] )
              this.setupElements(responseData["stripe"]["paymentIntent"]);
              // if (this.appstate.platform === 'ios') { // ios
              //     this.isBankLinkVisible = true
              //     this.bankLink = responseData.response.checkout_url
              // } else // android and web
              //     this.activeForm = 'payBank'
            } else {
              this.$root.$emit(eventsRegister.addMessage, {
                type: "error",
                message: responseData.response.error_message,
              });
            }

            this.payBtnText = this.$t("pay");
            this.isPaynBtnStatusWait = false;
          },
          () => {
            // server error callback
            this.payBtnText = this.$t("pay");
            this.isPaynBtnStatusWait = false;
            this.$root.$emit(eventsRegister.addMessage, {
              type: "error",
              message: this.$t("serverError"),
            });
          }
        );
      }
    },

    moneyInputFocused(e: any) {
      // remove cent (.00) from moneyAmount
      if (this.moneyAmount > 0)
        this.moneyAmountStr = "" + parseInt(this.moneyAmountStr);
      // else e.target.select() // or select 0.00
    },

    // Set up Stripe.js and Elements to use in checkout form
    setupElements(data: any): any {
      paymentIntent = data;
      stripe = Stripe(data.publishableKey);
      let elements = stripe.elements();
      let style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };

      card = elements.create("card", { style: style });
      card.mount("#card-element");

      // return {
      //     stripe: stripe,
      //     card: card,
      //     clientSecret: data.clientSecret
      // }
      // let btn: any =  document.querySelector("button")
      // btn.disabled = false

      // Handle form submission.
      // let form: any = document.getElementById("payment-form");
      // form.addEventListener("submit", (event: any)=>{
      //     event.preventDefault();
      //     // Initiate payment when the submit button is clicked
      //     this.pay(stripe, card, data.clientSecret)
      // })
    },

    /*
     * Calls stripe.confirmCardPayment which creates a pop-up modal to
     * prompt the user to enter extra authentication details without leaving your page
     */
    pay(e: any) {
      e.preventDefault();
      changeLoadingState(true);

      // Initiate the payment.
      // If authentication is required, confirmCardPayment will automatically display a modal
      stripe
        .confirmCardPayment(paymentIntent["clientSecret"], {
          payment_method: {
            card: card,
          },
        })
        .then((result: any) => {
          // { "id": "pi_1GiXz9KgqPPTg1lhl3EdP5VM","object": "payment_intent","amount": 1000,  "created": 1589427831, ..., "currency": "usd","status": "succeeded"}
          if (result.error) {
            // Show error to your customer
            showError(result.error.message);
          } else {
            // The payment has been processed!
            orderComplete(paymentIntent["clientSecret"]);
            this.isPaySuccessVisible = true;
            // Add global event handler on balance changed. Close popup and return to previous page
            this.$root.$once(eventsRegister.balanceChanged, () => {
              this.isBalanceChanged = true;
            });
            logEventFA("purchase", {
              currency: "USD",
              value: this.moneyAmount,
              customer_id: this.appdata.sm_customer.ID,
            });
            logEventFB(
              "EVENT_NAME_VIEWED_CONTENT",
              { CURRENCY: "USD" },
              this.moneyAmount
            );
          }
        });
    },

    payGooglePay() {
      this.isFormChecked = true;
      if (this.isMoneyAmountCorrect) {
        const sgap: any = window["sgap"];
        sgap
          .requestPayment(this.moneyAmount, "USD")
          .then((token: any) => {
            this.$root.$emit(eventsRegister.addMessage, {
              type: "success",
              message: "gPay result: " + token,
            });

            let dataForSend = {
              Amount: this.moneyAmountStr,
              ClientCellPhone: this.appdata.sm_customer.st_11010 || "",
              ClientCode: this.appdata.sm_customer.pr236,
              // "ClientEmail": 'qa.utec@gmail.com',
              PayCountryId: 3,
              SiteId: 1,
              CardToken: token,
            };
            addFunds(
              dataForSend,
              (responseData: any) => {
                // Success callback function
                // {stripe: { paymentIntent: {clientSecret: "pi_1GBhInK...", publishableKey: "pk_test_nX..."} } }
                console.log("GooglePay Stripe token result: " + responseData);
                this.$root.$emit(eventsRegister.addMessage, {
                  type: "success",
                  message: "Stripe token result: " + responseData,
                });

                this.isPaySuccessVisible = true;
                // Add global event handler on balance changed. Close popup and return to previous page
                this.$root.$once(eventsRegister.balanceChanged, () => {
                  this.isBalanceChanged = true;
                });
              },
              () => {
                // server error callback
                this.$root.$emit(eventsRegister.addMessage, {
                  type: "error",
                  message: this.$t("serverError"),
                });
              }
            );
          })
          .catch((err: any) => {
            console.warn(err);
            this.$root.$emit(eventsRegister.addMessage, {
              type: "error",
              message: err,
            });
          });
      }
    },
  },
});
