var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"popupWrap"},[_c('div',{staticClass:"popupMain",class:[
      _vm.activePopupContent,
      {
        active: _vm.isPopupActive,
        lastMessageAnimate:
          !_vm.isPopupActive &&
          _vm.appstate.isPopupMessageLast &&
          _vm.activePopupContent === 'message',
      } ]},[(_vm.activePopupContent !== 'message')?_c('div',{staticClass:"popupHeader"},[_c('span',[_vm._v(_vm._s(_vm.popupTitle))]),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"popupBody",class:{ popupBodyMessage: _vm.activePopupContent === 'message' }},[(_vm.isPopupActive)?_c(_vm.popupContent[_vm.activePopupContent],{tag:"component",staticClass:"contentCard contentCardPadding",attrs:{"appdata":_vm.appdata,"appstate":_vm.appstate,"popupbody":_vm.popupbody},on:{"closePopup":_vm.closePopup}}):_vm._e()],1)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isPopupActive)?_c('div',{staticClass:"popupOverlay",on:{"click":_vm.closePopupClicked}}):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btnClosePopup",domProps:{"innerHTML":_vm._s(_vm.leftArrowSVG)},on:{"click":_vm.closePopup}})}]

export { render, staticRenderFns }