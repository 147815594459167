












































import Vue from 'vue'
import { Prop } from 'vue/types/options'

export default Vue.extend({
    name: 'PopupTerms',
    props: {
        popupbody: Object as Prop<any>,
        appdata: Object as Prop<any>,
        appstate: Object as Prop<any>
    },
})
