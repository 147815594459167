











import Vue from "vue";

export default Vue.extend({
  name: "PaymentSuccess",
  props: {
    onClose: {
      type: Function,
      default: () => true,
    },
  },
});
