






















import Vue from "vue";
import { mapGetters } from "vuex";
import { openStore } from "../utils/utils";
// eslint-disable-next-line
const compareVersions = require("compare-versions");

const repeatDuration = 8 * 60 * 60 * 1000;

export default Vue.extend({
  name: "UpdateVersionPopup",
  data() {
    return {
      isOpened: false,
      setupTime: localStorage.getItem("setupTime"),
    };
  },
  props: {
    lastVersion: String,
  },
  computed: {
    ...mapGetters("root", ["appVersion"]),
  },
  created() {
    if (!document.body.classList.contains("web")) {
      const isOld = compareVersions.compare(
        this.lastVersion,
        this.appVersion,
        ">"
      );

      if (isOld) {
        const setupTime = localStorage.getItem("setupTime");

        if (
          setupTime == null ||
          new Date().getTime() - +setupTime > repeatDuration
        ) {
          this.isOpened = true;
        }
      }
    }
  },
  methods: {
    onUpdate() {
      openStore();
      this.isOpened = false;
    },
    onLater() {
      localStorage.setItem("setupTime", new Date().getTime().toString());
      this.isOpened = false;
    },
  },
});
