















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable */
/* tslint:disable */
import Vue from "vue";
import { Prop } from "vue/types/options";

import eventsRegister from "@/services/events-register";
import { leftArrowSVG, resultBoxSVG, resultErrorBoxSVG } from "@/services/svg";
import { createRequest } from "../services/api-connector";
import { getObjInAr, logEventFA, logEventFB } from "../utils/utils";

import Balloon from "./Balloon.vue";
import PaymentSuccess from "./PaymentSuccess.vue";
import PaymentCheck from "@/components/PaymentCheck.vue";
import {
  BaseTarrif,
  getPaymentMetaData,
  setSecureCardId,
  tarrifsTypes,
} from "../services/requests-list";
import { mapActions, mapGetters } from "vuex";

export const checkTitleList = {
  innerDelivery: "cardCheckInnerDelivery",
  werehouse: "warehouseProcPayPu",
  countryDelivery: "globalDeliveryPayPu",
  insurance: "ensurancePayPu",
  localDelivery: "addressDeliveryPayPu",
  combine: "combinePayPu",
  divide: "dividePayPu",
  productTax: "paymentProductTaxTitle",
  additionalBox: "additionalBoxPayPu",
  additionalPhoto: "additionalPhotoPayPu",
  consult: "paymentConsultTitle",
  buyout: "cardCheckBuyoutService",
};

// Stripe pay
const Stripe: any = window["Stripe"];
let stripe: any;
let cardNumber: any;
let paymentIntent: any; // newCard paymentIntent {clientSecret: "pi_1GBhInK...", publishableKey: "pk_test_nX..."}
let autoCloseTimer: any;
let setupIntent: any; // {clientSecret: "pi_1GBhInK...", publishableKey: "pk_test_nX..."}

/* ------- Post-payment helpers ------- */
/* Shows a success / error message when the payment is complete */
function orderComplete(clientSecret: any) {
  // Just for the purpose of the sample, show the PaymentIntent response object
  // stripe.retrievePaymentIntent(clientSecret).then(function(result: any) {
  // let paymentIntent: any = result.paymentIntent
  // let paymentIntentJson: any = JSON.stringify(paymentIntent, null, 2)
  // let srPaymentForm: any = document.querySelector(".sr-payment-form")
  // srPaymentForm.classList.add("hidden")
  // let srResult: any = document.querySelector(".sr-result")
  // let srPaymentFormPre: any = srResult.querySelector("pre")
  // srPaymentFormPre.textContent = paymentIntentJson;
  // srResult.classList.remove("hidden")
  // setTimeout(function() {
  //   srResult.classList.add("expand")
  // }, 200);
  // changeLoadingState(false)
  // })
}

function showError(errorMsgText: any) {
  changeLoadingState(false);
  let errorMsg: any = document.querySelector(".sr-field-error");
  errorMsg.textContent = errorMsgText;
  setTimeout(() => {
    errorMsg.textContent = "";
  }, 4000);
}

// Show a spinner on payment submission
function changeLoadingState(isLoading: any) {
  // let submitBtn: any = document.querySelector("#submit")
  // let spinner: any = document.querySelector("#spinner")
  // let buttonText: any = document.querySelector("#button-text")
  // if (isLoading) {
  //     submitBtn.disabled = true
  //     spinner.classList.remove("hidden")
  //     buttonText.classList.add("hidden")
  // } else {
  //    submitBtn.disabled = false
  //     spinner.classList.add("hidden")
  //     buttonText.classList.remove("hidden")
  // }
}

let elementStyles = {
  base: {
    color: "#282828",
    fontWeight: 300,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: "16px",
    fontSmoothing: "antialiased",

    ":focus": {
      color: "#424770",
    },

    "::placeholder": {
      color: "#696969",
    },

    ":focus::placeholder": {
      color: "#696969",
    },
  },
  invalid: {
    color: "#FA755A",
    ":focus": {
      color: "#FA755A",
    },
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

var elementClasses = {
  focus: "focus",
  empty: "empty",
  invalid: "invalid",
};

let DefaultParcel = {
  AddressDelivery: null,
  CurrencyS: null,
  DT_Cancel: null,
  DT_Delivery: null,
  DT_Repack: null,
  LocalState: null,
  N: null,
  NameCustomer: null,
  Order_id: null,
  Package_id: null,
  PathToPhoto: null,
  STATE: "",
  State_id: 0,
  Status_id: null,
  StepN: 1,
  Total: null,
  TotalCost: null,
  iParcel_id: 0,
  isAllowDivide: null,
  p_CurrencyS: null,
  p_STATE: null,
  p_State_id: null,
  p_StepN: null,
  p_pr_235: null,
  p_pr_290: null,
  p_pr_296: null,
  pr201_Name: null,
  pr249: null,
  pr256: 1,
  pr260: null,
  pr265_Name: null,
  pr275: 0,
  pr275_Name: "",
  pr276: 0,
  pr276_Name: "",
  pr280: null,
  pr282: null,
  pr294: null,
  pr299: null,
  pr302: null,
  pr325: null,
  pr326: null,
  pr327: null,
  pr331: null,
  pr395: null,
  pr399: 0,
  pr435: null,
  pr436: null,
  pr438: null,
  pr444: null,
  pr445: null,
  pr446: null,
  pr451: "",
  pr473: null,
  pr474: "",
  pr476: null,
  pr476_Name: null,
  pr478: null,
  pr486: null,
  pr487: null,
  pr492: "",
  pr492_2: "",
  pr513: null,
  pr520: "",
  pr521: null,
  pr522: null,
  pr523: null,
  pr586: null,
  pr_235: null,
  pr_290: "",
  pr_296: "",
  pr_587: null,
  pr_600: null,
  pr_601: null,
};

export default Vue.extend({
  components: { Balloon, PaymentSuccess, PaymentCheck },
  props: {
    popupbody: [Object] as any,
    appstate: Object as Prop<any>,
  },
  data() {
    return {
      eventsRegister,
      leftArrowSVG,
      resultBoxSVG,
      resultErrorBoxSVG,

      isPopupActive: false,
      type: "shopping", // or parcel
      parcel: DefaultParcel as any,
      activeForm: "savedCards", // or newCard

      isLoading: false,
      isProcessing: false,
      isSuccessNewYear: false,

      isSaveCard: true,

      totalCost: 0,
      toPay: 0,
      balance: 0,

      // shopping cost detail
      productCost: 0, //   {"TypeCost_id": 11672, "Cost": 0.00 },
      deliveryCost: 0, //    {"TypeCost_id": 11671, "Cost": 2.52 },
      //    {"TypeCost_id": 11670, "Cost": 36.00 },
      //    {"TypeCost_id": 11518, "Cost": 0.00 }
      isPay1InfoActive: false,
      isPay2InfoActive: false,
      isPay3InfoActive: false,
      isPayConsultInfoActive: false,
      isPayTaxInfoActive: false,
      isBonusDetailActive: false,
      isСombineDetailActive: false,
      isDivideDetailActive: false,
      isAdditionalBoxDetailActive: false,
      isAdditionalPhotoDetailActive: false,
      isWarehouseCostBalloonActive: false,
      isGlobalDeliveryCostBalloonActive: false,
      isAddressDeliveryCostBalloonActive: false,
      isEnsuranceCostBalloonActive: false,
      costForWorker: 0,

      // parcel cost detail
      isCostLoading: true,
      warehouseProcessCost: 0,
      globalDeliveryCost: 0,
      globalDeliveryWeight: 0,
      globalDeliveryTariff: 0,
      addressDeliveryCost: 0,
      insuranceCost: 0,
      activeAddressId: 0,
      isInsuranceChecked: false,
      isPackageDataLoaded: false,
      isCanMakePaymentsApple: false,
      isCanMakePaymentsGoogle: false,

      // Saved cards
      // savedCards: [], // [ { "TypeId": 11639, "CardId": "card_1GuezcIXOKJSgckPqIZwUio7", "BrandLdm": "MasterCard", "BrandOriginal": "MasterCard", "Last4": "7218", "ExpMonth": 9, "ExpYear": 2029, "IsDefault": true }]
      activeCard: {
        TypeId: 0,
        CardId: "",
        BrandLdm: "",
        BrandOriginal: "",
        Last4: "",
        ExpMonth: 0,
        ExpYear: 0,
        IsDefault: false,
      },
      isCardListActive: false,

      isPaySuccessVisible: false,
      isPayErrorVisible: false,

      pricesData: {
        innerDelivery: {} as BaseTarrif,
        werehouse: {} as BaseTarrif,
        countryDelivery: {} as BaseTarrif,
        productTax: {} as BaseTarrif,
        insurance: {} as BaseTarrif,
        localDelivery: {} as BaseTarrif,
        combine: {} as BaseTarrif,
        divide: {} as BaseTarrif,
        additionalBox: {} as BaseTarrif,
        additionalPhoto: {} as BaseTarrif,
        consult: {} as BaseTarrif,
        buyout: {} as BaseTarrif,
      },
      paymentIntentApple: {
        clientSecret: "",
      },
    };
  },

  created() {
    // Example usage:  $root.$emit('showPayment', parcel)
    // Example usage:  $root.$emit(eventsRegister.showPayment, {type: 'shopping', value: 11.25})
    // this.$root.$on(eventsRegister.showPayment, (params: any)=>{

    this.$root.$on("showPayment", (params: any) => {
      // TODO: move to eventRegister
      this.type = params.type;
      this.parcel = params.parcel;
      if (params.type === "shopping") {
        // check productCost and deliveryCost
        console.log(
          "params",
          params.param2,
          this.parcel.pr399,
          this.parcel.pr260
        );
        if (params.param1) this.productCost = params.param1;
        else {
          let cost = this.parcel.Total / this.parcel.pr260; // local product cost / local currency exchange
          this.productCost = cost ? parseFloat(cost.toFixed(2)) : 0;
        }
        if (params.param2) this.deliveryCost = params.param2;
        else {
          let cost = this.parcel.pr399 / this.parcel.pr260; // local product cost / local currency exchange
          this.deliveryCost = cost ? parseFloat(cost.toFixed(2)) : 0;
        }
        // this.costForWorker = params.param3
        this.getShoppingCost();
      } else if (params.type == "parcel") {
        if (params.param1) this.activeAddressId = params.param1;
        else this.fetchPackageData();
        if (params.param2) this.isInsuranceChecked = params.param2;
        else this.fetchPackageData();

        this.getParcelDeliveryCost();
      }
      this.isPopupActive = true;
    });
  },

  computed: {
    ...mapGetters("user", ["user", "userId"]),
    ...mapGetters("cards", ["cardList"]),
  },
  methods: {
    ...mapActions("root", ["updateBaseData"]),
    ...mapActions("parcels", ["loadParcelList"]),
    initApplePay() {
      const provider = (window as any).AppleSpayPlugin || {};
      console.log("initApplePay", !provider.canMakePayments);
      if (!provider.canMakePayments) return false;
      provider
        .canMakePayments()
        .then((res: any) => {
          if (typeof res === "string") {
            this.getNativeIntent("apple");
            console.log("You can use apple pay", res);
          }
          if (typeof res === "boolean") {
            this.getNativeIntent("google");
            console.log("You can use google pay", res);
          }
        })
        .catch((error: any) => {
          console.log("Cant use applee pay", error);
        });
    },
    async close(isUpdateData: string) {
      // warn: do not use boolean. Reason: method can receive Event (click from el).
      this.isPopupActive = false;
      this.activeForm = "savedCards";

      // reset data
      this.parcel = DefaultParcel;
      this.isLoading = false;
      this.isProcessing = false;

      this.isSaveCard = false;

      // shopping
      this.productCost = 0;
      this.deliveryCost = 0;
      this.isPay1InfoActive = false;
      this.isPay2InfoActive = false;
      this.isPay3InfoActive = false;
      this.isBonusDetailActive = false;
      this.isWarehouseCostBalloonActive = false;
      this.isGlobalDeliveryCostBalloonActive = false;
      this.isAddressDeliveryCostBalloonActive = false;
      this.isEnsuranceCostBalloonActive = false;
      this.costForWorker = 0;

      // Parcel
      this.totalCost = 0;
      this.toPay = 0;
      this.balance = 0;
      this.isCostLoading = true;
      this.warehouseProcessCost = 0;
      this.globalDeliveryCost = 0;
      this.globalDeliveryWeight = 0;
      this.globalDeliveryTariff = 0;
      this.addressDeliveryCost = 0;
      this.insuranceCost = 0;
      this.activeAddressId = 0;
      this.isInsuranceChecked = false;
      this.isPackageDataLoaded = false;

      // Saved cards
      this.activeCard = {
        TypeId: 0,
        CardId: "",
        BrandLdm: "",
        BrandOriginal: "",
        Last4: "",
        ExpMonth: 0,
        ExpYear: 0,
        IsDefault: true,
      };
      this.isCardListActive = false;

      this.isPaySuccessVisible = false;
      this.isPayErrorVisible = false;
      this.isSuccessNewYear = false;

      if (paymentIntent) this.cancelPayment();
      if (setupIntent) this.cancelSetupIntent();
      if (isUpdateData) {
        await this.updateBaseData();
        this.$root.$emit(eventsRegister.updateUserData);
        //@ts-ignore
        this.loadParcelList();
      }
      this.$root.$emit(eventsRegister.closePopup);

      // Cleanup stripe - remove iframes
      // https://stackoverflow.com/questions/55119026/how-to-remove-stripes-iframes
      // https://github.com/stripe/react-stripe-elements/issues/99
      let iframeEls: NodeList = document.querySelectorAll(
        "[name^=__privateStripeController]"
      );
      iframeEls.forEach((el: any) => {
        // HTMLIFrameElement
        el.remove();
      });
    },

    getNativeIntent(type: "google" | "apple") {
      const types = {
        google: 11850,
        apple: 11851,
      };
      let dataForSend = {
        Amount: this.toPay,
        DocumentId: this.parcel.id,
        ClientCellPhone: this.user.st_11010 || "",
        ClientCode: this.user.pr236,
        PayCountryId: 3,
        SiteId: 1,
        PaymentMethodType: types[type],
      };
      this.isProcessing = true;
      createRequest(
        "createPayment",
        dataForSend,
        (responseData: any) => {
          console.log("createNativePayment", this.toPay, responseData);
          if (responseData["stripe"]["paymentIntent"]) {
            if (types[type] === 11851) {
              this.paymentIntentApple = responseData["stripe"]["paymentIntent"];
              this.isCanMakePaymentsApple = true;
            }
            if (types[type] === 11850) {
              this.paymentIntentApple = responseData["stripe"]["paymentIntent"];
              this.isCanMakePaymentsGoogle = true;
            }
          }
          this.isProcessing = false;
        },
        () => {
          this.isProcessing = false;
        }
      );
    },

    getPaymentIntent() {
      if (paymentIntent) return false; // create paymentIntent once

      this.isLoading = true;
      // Get payment intend
      let dataForSend = {
        Amount: this.toPay,
        DocumentId: this.parcel.id,
        ClientCellPhone: this.user.st_11010 || "",
        ClientCode: this.user.pr236,
        // "ClientEmail": 'qa.utec@gmail.com',
        PayCountryId: 3,
        SiteId: 1,
        PaymentMethodType: 11165,
      };
      createRequest(
        "createPayment",
        dataForSend,
        (responseData: any) => {
          console.log("createPayment", this.toPay, responseData);
          // Success callback function
          // {stripe: { paymentIntent: {clientSecret: "pi_1GBhInK...", publishableKey: "pk_test_nX..."} } }
          if (responseData["stripe"]["paymentIntent"]) {
            // setupElements - create stripe form
            paymentIntent = responseData["stripe"]["paymentIntent"];
            stripe = Stripe(paymentIntent.publishableKey);

            var paymentRequest = stripe.paymentRequest({
              country: "US",
              currency: "usd",
              total: {
                label: `${this.$t("titlePayPu")} ${this.parcel.pr474 ||
                  this.parcel.pr331}`,
                amount: Math.round(this.toPay * 100),
              },
              requestPayerName: true,
              requestPayerEmail: true,
            });
            console.log("paymentRequest", paymentRequest);

            let elements = stripe.elements();

            // let card = elements.create("card", { style: style })
            // card.mount("#card-element")

            cardNumber = elements.create("cardNumber", {
              showIcon: true, // https://stackoverflow.com/questions/42262887/enabling-brand-icon-in-cardnumber-type-element-in-stripe
              style: elementStyles,
              classes: elementClasses,
              placeholder: this.$t("cardNumberPuPay"),
            });
            cardNumber.mount("#input-card-number");

            var cardExpiry = elements.create("cardExpiry", {
              style: elementStyles,
              classes: elementClasses,
              placeholder: this.$t("expireDatePuPay"),
            });
            cardExpiry.mount("#input-card-expiry");

            var cardCvc = elements.create("cardCvc", {
              style: elementStyles,
              classes: elementClasses,
            });
            cardCvc.mount("#input-card-cvc");
          } else this.isPayErrorVisible = true;

          this.isLoading = false;
        },
        () => {
          // server error callback
          this.isPayErrorVisible = true;
          this.isLoading = false;
        }
      );
    },

    cancelPayment() {
      if ("clientSecret" in paymentIntent) {
        // get paymentId from clientSecret string pi_1H1qYuIXOKJSgckPrdSo3qut_asfgsfaaadgd34df
        let strAr = paymentIntent["clientSecret"].split("_"); // ["pi", "1H1qYuIXOKJSgckPrdSo3qut", "asfgsfaaadgd34df"]
        let payId = strAr[0] + "_" + strAr[1];
        let requestData = {
          // {"PaymentId": "pi_1H1qYuIXOKJSgckPrdSo3qut", "CancellationReasons": "Test cancel"}
          PaymentId: payId,
          CancellationReasons: "",
        };
        createRequest(
          "cancelPayment",
          requestData, // {ResultCode: 0}
          (responseData: any) => {
            // Success callback function
            paymentIntent = null;
          },
          () => {} // server error callback
        );
      }
    },

    getSetuptIntent() {
      this.isLoading = true;

      createRequest(
        "newCardIntent",
        { ClientCode: this.userId },
        (responseData: any) => {
          // Success callback function
          // {"stripe": {"setupIntent": {"id":"seti_1H584KIXOKJSgckPGm7NZveJ","clientSecret": "seti_1H584KIXOKJSgckPGm7NZveJ_secret_HeQwW7IqnLPBKb3zLWiJBn0qCves3w3", "publishableKey": "rk_live_bc7kMHHe1c61sIG3sRrjMtat00YV85NxQw"}}}
          if (responseData["stripe"]["setupIntent"]) {
            // https://stripe.com/docs/payments/save-and-reuse
            // setupElements - create stripe form
            setupIntent = responseData["stripe"]["setupIntent"];
            // create stripe form
            stripe = Stripe(setupIntent.publishableKey);
            const elements = stripe.elements();

            cardNumber = elements.create("cardNumber", {
              showIcon: true, // https://stackoverflow.com/questions/42262887/enabling-brand-icon-in-cardnumber-type-element-in-stripe
              style: elementStyles,
              classes: elementClasses,
              placeholder: this.$t("cardNumberPuPay"),
            });
            cardNumber.mount("#input-card-number");

            var cardExpiry = elements.create("cardExpiry", {
              style: elementStyles,
              classes: elementClasses,
              placeholder: this.$t("expireDatePuPay"),
            });
            cardExpiry.mount("#input-card-expiry");

            var cardCvc = elements.create("cardCvc", {
              style: elementStyles,
              classes: elementClasses,
            });
            cardCvc.mount("#input-card-cvc");
          } else this.isPayErrorVisible = true;

          this.isLoading = false;
        },
        () => {
          // server error callback
          this.isLoading = false;
        }
      );
    },

    cancelSetupIntent() {
      if ("clientSecret" in setupIntent) {
        // get paymentId from clientSecret string pi_1H1qYuIXOKJSgckPrdSo3qut_asfgsfaaadgd34df
        createRequest(
          "cancelNewCardIntent",
          {
            // {"ClientCode": "2805956","StripeSetupIntent": "seti_1H584KIXOKJSgckPGm7NZveJ"}
            ClientCode: this.userId,
            StripeSetupIntent: setupIntent["id"],
          },
          (responseData: any) => {
            // Success callback function {"ResultCode": 0}
            setupIntent = null;
          },
          () => {} // server error callback
        );
      }
    },

    /*
     * Calls stripe.confirmCardPayment which creates a pop-up modal to
     * prompt the user to enter extra authentication details without leaving your page
     */
    async payCard() {
      if (this.isProcessing) return false;

      this.isProcessing = true;

      this.icParcelPayableAsync(async (isTrue: boolean) => {
        if (!isTrue) {
          this.close("update");
          this.isProcessing = false;
        } // parcel already paid
        else {
          // pay for parcel
          changeLoadingState(true);
          // Initiate the payment.
          // If authentication is required, confirmCardPayment will automatically display a modal
          // let saveCard = this.isSaveCard ? 'on_session' : ''
          // let saveCard = this.isSaveCard ? 'on_session' : 'off_session'
          const setSecure = await setSecureCardId({
            PaymentId: paymentIntent["clientSecret"],
          }).catch((err) => console.log("setSeccure Err", err));
          let saveCard = this.isSaveCard ? "on_session" : "";
          stripe
            .confirmCardPayment(paymentIntent["clientSecret"], {
              payment_method: {
                card: cardNumber,
              },
              setup_future_usage: saveCard,
            })
            .then((result: any) => {
              // { "id": "pi_1GiXz9KgqPPTg1lhl3EdP5VM","object": "payment_intent","amount": 1000,  "created": 1589427831, ..., "currency": "usd","status": "succeeded"}
              if (result.error) {
                // Show error to your customer
                showError(result.error.message);
                this.isPayErrorVisible = true;
                this.isProcessing = false;
              } else {
                setTimeout(() => {
                  this.$root.$emit(eventsRegister.updateUserData);
                  this.isPaySuccessVisible = true;
                  this.isProcessing = false;
                  //@ts-ignore
                  this.loadParcelList();
                }, 6000);
                // Add global event handler on balance changed. Close popup and return to previous page
                // this.$root.$once(eventsRegister.balanceChanged, () => {
                //   this.isPaySuccessVisible = true;
                //   this.isProcessing = false;
                //   clearTimeout(autoCloseTimer);
                // });
                // // set autoCloseTimer. close processing screen if balanceChanged event not received
                // autoCloseTimer = setTimeout(() => {
                //   this.$root.$emit(eventsRegister.updateParcels, () => {
                //     this.close("update");
                //   });
                // }, 30000); // ms, 30s

                paymentIntent = null; // delete new card paymentIntent

                if (this.type === "shopping") {
                  logEventFA("spend_virtual_currency", {
                    item_name: "shopping",
                    currency: "USD",
                    value: this.totalCost,
                    customer_id: this.userId,
                  });
                  logEventFB(
                    "EVENT_NAME_SPENT_CREDITS",
                    { CONTENT_TYPE: "shopping", CURRENCY: "USD" },
                    this.totalCost
                  );
                } else if (this.type === "parcel") {
                  logEventFA("ecommerce_purchase", {
                    currency: "USD",
                    value: this.totalCost,
                    customer_id: this.userId,
                    shipping: this.globalDeliveryCost,
                  });
                  logEventFB(
                    "EVENT_NAME_PURCHASED",
                    { CONTENT_TYPE: "delivery", CURRENCY: "USD" },
                    this.totalCost
                  );
                }
                // logEventFA('purchase', {currency: 'USD', value: this.totalCost, customer_id: this.userId})
                // logEventFB('EVENT_NAME_VIEWED_CONTENT', {CURRENCY: 'USD'}, this.totalCost)
              }
            });
        }
      });
    },

    payByApplePay() {
      const provider = (window as any).AppleSpayPlugin || {};
      if (!provider.makePaymentRequest) return false;
      const isOnBuy = [1, 2, 3].includes(this.parcel.StepN);
      const checkItems = [];
      if (isOnBuy) {
        checkItems.push({
          label: this.parcel.pr474 || this.parcel.pr331,
          amount: this.productCost,
        });
      } else {
        checkItems.push({
          label: this.$t("globalDeliveryPayPu"),
          amount: this.pricesData.countryDelivery.Cost,
        });
      }
      [...Object.keys(this.pricesData)].forEach((service) => {
        const cost = this.pricesData[service]?.Cost || 0;
        if (cost && !["productPrice", "countryDelivery"].includes(service)) {
          checkItems.push({
            label: this.$t(checkTitleList[service]),
            amount: cost,
          });
        }
      });
      if (this.balance) {
        checkItems.push({
          label: this.$t("newCheckBonuses"),
          amount: -this.balance,
        });
      }
      const reqData = {
        items: [
          ...checkItems,
          {
            label: "UTEC USA LLC",
            amount: this.toPay,
          },
        ],
        amount: this.toPay,
        countryCode: "UA",
        currencyCode: "USD",
        billingAddressRequirement: "none",
        shippingAddressRequirement: "none",
        clientSecret: this.paymentIntentApple.clientSecret,
      } as any;
      provider
        .makePaymentRequest(reqData)
        .then(async (res: any) => {
          this.isProcessing = true;

          setTimeout(() => {
            this.isPaySuccessVisible = true;
            this.isProcessing = false;
          }, 8000);
        })
        .catch((err: any) => {
          console.log("error apple pay", err);
          // provider.completeLastTransaction("failure");
        });
    },

    async paySavedCard() {
      if (this.isProcessing) return false;
      this.isProcessing = true;

      this.icParcelPayableAsync(async (isTrue: boolean) => {
        if (!isTrue) {
          this.isProcessing = false;
          this.close("update");
        } // parcel already paid
        else {
          // pay for parcel
          let dataForSend = {
            Amount: this.toPay,
            DocumentId: this.parcel.id,
            ClientCellPhone: this.user.st_11010 || "",
            ClientCode: this.user.pr236,
            // "ClientEmail": 'qa.utec@gmail.com',
            PayCountryId: 3,
            StripeCardId: this.activeCard["CardId"],
            SiteId: 1,
          };
          // const metaData = await getPaymentMetaData({
          //   userId: this.userId,
          //   lastFour: this.activeCard.Last4,
          //   expMonth: this.activeCard.ExpMonth,
          //   expYear: this.activeCard.ExpYear,
          // });

          createRequest(
            "createPayment",
            dataForSend,
            async (responseData: any) => {
              // Success callback function
              // {stripe: { paymentIntent: {clientSecret: "pi_1GBhInK...", publishableKey: "pk_test_nX..."} } }
              if (responseData["stripe"]["paymentIntent"]) {
                let intent: any = responseData["stripe"]["paymentIntent"];
                const setSecure = await setSecureCardId({
                  PaymentId: intent.clientSecret,
                  StripeCardId: this.activeCard["CardId"],
                }).catch((err) => console.log("setSeccure Err", err));
                // send payment to stripe
                stripe = Stripe(intent.publishableKey);
                stripe
                  .confirmCardPayment(intent.clientSecret, {
                    payment_method: this.activeCard["CardId"],
                  })
                  .then((result: any) => {
                    // { "id": "pi_1GiXz9KgqPPTg1lhl3EdP5VM","object": "payment_intent","amount": 1000,  "created": 1589427831, ..., "currency": "usd","status": "succeeded"}
                    if (result.error) {
                      // Show error to your customer
                      showError(result.error.message);
                      this.isPayErrorVisible = true;
                      this.isProcessing = false;
                    } else {
                      setTimeout(() => {
                        this.$root.$emit(eventsRegister.updateUserData);
                        this.isPaySuccessVisible = true;
                        this.isProcessing = false;
                        //@ts-ignore
                        this.loadParcelList();
                      }, 6000);
                      // Add global event handler on balance changed. Close popup and return to previous page
                      // this.$root.$once(eventsRegister.balanceChanged, () => {
                      //   this.isPaySuccessVisible = true;
                      //   this.isProcessing = false;
                      //   clearTimeout(autoCloseTimer);
                      // });
                      // // set autoCloseTimer. close processing screen if balanceChanged event not received
                      // autoCloseTimer = setTimeout(() => {
                      //   this.$root.$emit(eventsRegister.updateParcels, () => {
                      //     this.close("update");
                      //   });
                      // }, 30000); // ms, 30s

                      if (this.type === "shopping") {
                        logEventFA("spend_virtual_currency", {
                          item_name: "shopping",
                          currency: "USD",
                          value: this.totalCost,
                          customer_id: this.userId,
                        });
                        logEventFB(
                          "EVENT_NAME_SPENT_CREDITS",
                          { CONTENT_TYPE: "shopping", CURRENCY: "USD" },
                          this.totalCost
                        );
                      } else if (this.type === "parcel") {
                        logEventFA("ecommerce_purchase", {
                          currency: "USD",
                          value: this.totalCost,
                          customer_id: this.userId,
                          shipping: this.globalDeliveryCost,
                        });
                        logEventFB(
                          "EVENT_NAME_PURCHASED",
                          { CONTENT_TYPE: "delivery", CURRENCY: "USD" },
                          this.totalCost
                        );
                      }
                      // logEventFA('purchase', {currency: 'USD', value: this.totalCost, customer_id: this.userId})
                      // logEventFB('EVENT_NAME_VIEWED_CONTENT', {CURRENCY: 'USD'}, this.totalCost)
                    }
                  });
              } else {
                this.isPayErrorVisible = true;
                this.isProcessing = false;
              }
            },
            () => {
              // server error callback
              this.isPayErrorVisible = true;
              this.isProcessing = false;
            }
          );
        }
      });
    },

    payShoppingWait() {
      if (this.isProcessing) return false;
      // change status for shopping
      // primary action
      this.isProcessing = true;
      const requestData: any = {
        tmp_object_list: [
          {
            OBJECT_ID: this.parcel.id,
            // "Log_id": (this.propdata['objectBuyout'])['Log_id'],
            StateOld_id: this.parcel["State_id"],
            StateNew_id: 5021, // approved
          },
        ],
      };
      createRequest(
        "objState",
        requestData,
        (responseData: any) => {
          // callbackSucces
          this.$root.$emit(eventsRegister.updateUserData);
          this.$root.$emit(eventsRegister.updateParcels);
          this.isProcessing = false;
          this.isPaySuccessVisible = true;
          logEventFA("spend_virtual_currency", {
            item_name: "shopping",
            currency: "USD",
            value: this.totalCost,
            customer_id: this.userId,
          });
          logEventFB(
            "EVENT_NAME_SPENT_CREDITS",
            { CONTENT_TYPE: "shopping", CURRENCY: "USD" },
            this.totalCost
          );
        },
        () => {
          // serverErrorCallback
          this.isPayErrorVisible = true;
          this.isProcessing = false;
        }
      );

      this.saveCard(); // optional action
    },

    payNewCargo() {
      if (this.isProcessing) return false;

      // primary action
      this.isProcessing = true;
      createRequest(
        "packageDeliveryCost",
        {
          IsConfirm: 1,
          PackageId: this.parcel.id,
          Pr302: 11102, // avia
          ToCountryId: null,
          AddressDetId: this.activeAddressId,
          is11521: this.isInsuranceChecked,
        },
        (responseData: any) => {
          // Success callback
          // update user info (balance) and packagelist
          // this.$root.$emit(eventsRegister.addMessage, { type: 'success', message: 'Відправлено', autoClose: true })
          this.$root.$emit(eventsRegister.updateUserData);
          this.$root.$emit(eventsRegister.updateParcels);
          this.isProcessing = false;
          this.isPaySuccessVisible = true;
          logEventFA("ecommerce_purchase", {
            currency: "USD",
            value: this.totalCost,
            customer_id: this.userId,
            shipping: this.globalDeliveryCost,
          });
          logEventFB(
            "EVENT_NAME_PURCHASED",
            { CONTENT_TYPE: "delivery", CURRENCY: "USD" },
            this.totalCost
          );
        },
        () => {
          // error callback
          this.isPayErrorVisible = true;
          this.isProcessing = false;
        }
      );

      this.saveCard(); // optional action
    },

    saveCard() {
      stripe
        .confirmCardSetup(setupIntent["clientSecret"], {
          payment_method: { card: cardNumber },
        })
        .then((result: any) => {
          if (result.error) {
            // Display error.message in your UI.
            console.log("Error save new card: " + result.error.message);
          } else {
            // The setup has succeeded. Display a success message.
            setupIntent = null;
          }
        });
    },

    getShoppingCost() {
      this.isCostLoading = true;
      createRequest(
        "shoppingCost",
        { DocId: this.parcel.id },
        (responseData: any) => {
          // Success callback {"sm_sel_cost_itrade_doc_total":[{Balance:569.31,ToPay: 0,TotalCost: 2.29,ServiceCostDraft: 0.02}]}
          if ("sm_sel_cost_itrade_doc_total" in responseData) {
            this.totalCost =
              responseData["sm_sel_cost_itrade_doc_total"][0]["TotalCost"];
            this.toPay =
              responseData["sm_sel_cost_itrade_doc_total"][0]["ToPay"];
            this.balance =
              responseData["sm_sel_cost_itrade_doc_total"][0]["Balance"];

            const res = {
              innerDelivery: {} as BaseTarrif,
              werehouse: {} as BaseTarrif,
              countryDelivery: {} as BaseTarrif,
              insurance: {} as BaseTarrif,
              localDelivery: {} as BaseTarrif,
              combine: {} as BaseTarrif,
              divide: {} as BaseTarrif,
              productTax: {} as BaseTarrif,
              additionalBox: {} as BaseTarrif,
              additionalPhoto: {} as BaseTarrif,
              consult: {} as BaseTarrif,
              buyout: {} as BaseTarrif,
            };
            responseData["sm_sel_cost_itrade_doc"].map(
              (tarrif: any) => (res[tarrifsTypes[tarrif.TypeCost_id]] = tarrif)
            );
            this.pricesData = { ...res };
            console.log("getShoppingCost payment prices", this.pricesData);
            if (this.user.pr512)
              // check free shopping for workers
              this.costForWorker =
                responseData["sm_sel_cost_itrade_doc_total"][0][
                  "ServiceCostDraft"
                ];
          } else console.error("Incorrect response data Shopping cost");
          this.isCostLoading = false;
          this.setCards();
          this.initApplePay();
        },
        () => {
          this.isCostLoading = false;
        } // error callback
      );
    },

    getParcelDeliveryCost() {
      this.isCostLoading = true;
      createRequest(
        "packageDeliveryCost",
        {
          IsConfirm: 0,
          PackageId: this.parcel.id,
          Pr302: 11102, // avia
          ToCountryId: null,
          AddressDetId: this.activeAddressId || null,
          is11521: this.isInsuranceChecked,
        },
        (responseData: any) => {
          // Success callback {"result":[{Balance: 569.31,Base: null,Cost: 0,Tariff: null,ToPay: 0,TotalCost: 3.64,TypeCost_id: 11518},{"TypeCost_id":11519,"Cost":2.00,"TotalCost":9.66},{"TypeCost_id":11521,"Cost":0.58,"TotalCost":9.66},{"TypeCost_id":11522,"Cost":0.24,"TotalCost":9.66}]}
          if ("result" in responseData) {
            // get delivery cost
            this.totalCost = responseData["result"][0]["TotalCost"];
            this.toPay = responseData["result"][0]["ToPay"];
            this.balance = responseData["result"][0]["Balance"];

            const res = {
              innerDelivery: {} as BaseTarrif,
              werehouse: {} as BaseTarrif,
              countryDelivery: {} as BaseTarrif,
              insurance: {} as BaseTarrif,
              localDelivery: {} as BaseTarrif,
              combine: {} as BaseTarrif,
              divide: {} as BaseTarrif,
              productTax: {} as BaseTarrif,
              additionalBox: {} as BaseTarrif,
              additionalPhoto: {} as BaseTarrif,
              consult: {} as BaseTarrif,
              buyout: {} as BaseTarrif,
            };
            responseData["result"].map(
              (tarrif: any) => (res[tarrifsTypes[tarrif.TypeCost_id]] = tarrif)
            );
            this.pricesData = { ...res };
            console.log(
              "getParcelDeliveryCost payment prices",
              this.pricesData
            );
            // Set cost services
            let warehouseProcessCostFound = getObjInAr(
              responseData["result"],
              "TypeCost_id",
              11519,
              false
            ); // 11519 Складская обработка
            if (warehouseProcessCostFound)
              this.warehouseProcessCost =
                warehouseProcessCostFound["Cost"] || 0;
            let globalDeliveryCostFound = getObjInAr(
              responseData["result"],
              "TypeCost_id",
              11520,
              false
            ); // 11520  Доставка международная
            if (globalDeliveryCostFound) {
              this.globalDeliveryCost = globalDeliveryCostFound["Cost"] || 0;
              this.globalDeliveryWeight = globalDeliveryCostFound["Base"] || 0;
              this.globalDeliveryTariff =
                globalDeliveryCostFound["Tariff"] || 0;
            }
            let addressDeliveryCostFound = getObjInAr(
              responseData["result"],
              "TypeCost_id",
              11555,
              false
            ); // 11555  Адресная доставка получателю
            if (addressDeliveryCostFound)
              this.addressDeliveryCost = addressDeliveryCostFound["Cost"] || 0;
            let insuranceCostFound = getObjInAr(
              responseData["result"],
              "TypeCost_id",
              11521,
              false
            ); // 11521  Страховка
            if (insuranceCostFound)
              this.insuranceCost = insuranceCostFound["Cost"] || 0;
          } else console.error("Incorrect response format Delivery cost");
          this.isCostLoading = false;
          this.setCards();
          this.initApplePay();
        },
        () => {
          // error callback
          this.isCostLoading = false;
        }
      );
    },

    setCards() {
      // set saved cards or create paymentIntent for new card
      if (this.cardList.length) {
        // cards exist
        // set activeCard (defaultCard)
        for (var i = 0; i < this.cardList.length; i++) {
          if (this.cardList[i]["IsDefault"]) this.activeCard = this.cardList[i];
          break;
        }
        // set active card if default card not exist - set last saved card
        if (!this.activeCard["CardId"])
          this.activeCard = this.cardList[this.cardList.length - 1];
        this.activeForm = "savedCards";
      } // cards not exist. get paymentIntent for newCards
      else this.openNewCardForm();
    },

    openNewCardForm() {
      console.log("openNewCardForm", this.toPay);
      if (this.toPay > 0) this.getPaymentIntent();
      else this.getSetuptIntent();
      this.activeForm = "newCard";
    },

    // Fetch package detail
    fetchPackageData(): any {
      if (this.isPackageDataLoaded) return true; // load once

      // Get package details by packageId
      let requestData = {
        ClassList: [{ Class_id: 24800, TypeLoad: 1, needLinkObject: 1 }],
        PropertyList: [
          { Class_id: 24800, Property_id: 489 }, //  Ссылка на список адресов клиента [Целое 32]
          { Class_id: 24800, Property_id: 595 }, //  страховка [Логическое] is11521
        ],
        Filter: [
          {
            Class_id: 24800,
            Filter_id: 1,
            Node_id: 1,
            Property_id: -1,
            Operator_id: "=",
            Value: this.parcel["Package_id"],
          },
        ],
      };
      createRequest(
        "objList",
        requestData,
        (responseData: any) => {
          // Success callback
          if ("N1024800__1" in responseData) {
            // N1024800__1:[{"Object_id":2442628,"State_id":-5001,"CalcName":"2442628","pr_296":"111114","pr_244":177,"pr_235":null,"pr299":"22942154262FFF","pr325":null,"pr399":null,"pr475":2442529}]
            let packageData = responseData["N1024800__1"][0];
            this.activeAddressId = packageData["pr489"];
            this.isInsuranceChecked = packageData["pr595"];
            this.isPackageDataLoaded = true;
          }
        },
        () => {} // server error callback
      );
    },

    // check parcel stays. Reason: prevent double payment (payment for already payed parcel)
    icParcelPayableAsync(callback: any) {
      let classId = 24640; // shopping
      if (this.type === "parcel") classId = 24800;
      let requestData = {
        ClassList: [{ Class_id: classId, TypeLoad: 0, needLinkObject: 0 }],
        Filter: [
          {
            Class_id: classId,
            Filter_id: 1,
            Node_id: 1,
            Property_id: -1,
            Operator_id: "=",
            Value: this.parcel.id,
          },
        ],
      };
      createRequest(
        "objList",
        requestData,
        (responseData: any) => {
          // Success callback
          if ("N1024640__1" in responseData) {
            // { N1024640__1: [{CalcName:"EZ802905281", Object_id:2905284, State_id:5020}] }
            let shoppingData = responseData["N1024640__1"][0];
            if (shoppingData["State_id"] === 5020) callback(true);
            else callback(false);
          } else if ("N1024800__1" in responseData) {
            // { N1024800__1: [{CalcName:"EZ802905281", Object_id:2905284, State_id:5020}] }
            let parcelData = responseData["N1024800__1"][0];
            if (parcelData["State_id"] === 5020) callback(true);
            else callback(false);
          } else console.warn("Parcel data not found: " + this.parcel.id);
        },
        () => {
          // server error callback
          // this.isPackageLoaded = true
        }
      );
    },
  },
  watch: {
    cardList() {
      if (this.isPopupActive) this.setCards();
    },
  },
});
