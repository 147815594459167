



































































































































/* eslint-disable */
import Vue from 'vue'
import { Prop } from 'vue/types/options'

import { isPhoneCorrect } from '../../utils/form-validators'


export default Vue.extend({
    props: {
        label: String,
        value: [String,Number],
        iscorrect: Boolean,
        min: Number,
        max: Number,
        type: {type: String, default: 'text'} as any,
        disabled: Boolean,
        isPassVisible: {type: Boolean, default: false} as any,
        autocomplete: String
    },
    data() {
        return { 
            isFocused: false,
            isEyeOpen: false,
            isPhoneCorrect,

            isValCorrect: true
        }
    },

    methods: {
        onInputNumberChanged(e: any): any {
            // check is value correct (min/max) and reset incorrect value
            let valStr = e.target.value
            let val = parseFloat(valStr)
            // -?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?       
            // if ( /^[\d.]+$/.test(valStr) ) {  // check value is correct (numbers only)
            if ( val || val === 0 ) { // check value is correct (numbers only)
                this.$emit('update:value', val) // return correct actual value
                if (this.min && val < this.min || this.min === 0 && val < this.min) // 0 is correct
                    this.isValCorrect = false // value incorrect
                else if (val > this.max) this.isValCorrect = false // value incorrect
                else this.isValCorrect = true
            } else {
                this.$emit('update:value', valStr)
                this.isValCorrect = false
            }
        }
    }
})
