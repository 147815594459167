






























































































































































import Vue from "vue";
import { Prop } from "vue/types/options";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import Balloon from "@/components/Balloon.vue";
//@ts-ignore
import ICountUp from "vue-countup-v2";

import eventsRegister from "@/services/events-register";
import { getMediaLink, sortedArObjsDate } from "../../utils/utils";
import { combineParcel, createRequest } from "@/services/api-connector";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "PopupCombine",
  props: {
    popupbody: Object as Prop<any>,
  },
  components: { EasyBottomSheet, EasyInput, Balloon, ICountUp },
  data() {
    return {
      readyParcelList: [] as any,
      selectedParcels: [] as any,
      combinedName: "",
      isNameSubmited: false,
      isCombineInfoOpened: false,
      isCombineNameOpened: false,
      isCustomsServiceActive: false,

      maxWeight: 35,
      maxCount: 8,
      dutyFreeLimit: 168,
      freeTaxLimit: 112,
      customsService: 5,

      isCombineInfoAccepted:
        window.localStorage.getItem("isCombineInfoAccepted") || false,
    };
  },
  computed: {
    ...mapGetters("parcels", ["parcelList"]),
    ...mapGetters("root", ["combinePrice"]),
    ...mapGetters("user", ["userCountryCode"]),
    isFilled(): boolean {
      return this.selectedParcels.length >= this.maxCount;
    },
    isValid(): boolean {
      return this.selectedParcels.length >= 2;
    },
    selectedreadyParcelList(): any[] {
      return [
        ...this.readyParcelList.filter((p: any) =>
          this.selectedParcels.includes(p.Package_id)
        ),
      ];
    },
    totalPrice(): number {
      return this.selectedreadyParcelList.reduce(
        (prev, cur) => prev + (cur.price || 0),
        0
      );
    },
    btnPriceText(): string {
      const { length } = this.selectedreadyParcelList;
      const isValid = length >= 2;

      if (!isValid) return `${this.$t("btnCombine")}`;
      return `${this.$t("btnCombine")} ${this.$t("for")} $${this.combinePrice}`;
    },
    totalWeight(): number {
      return this.selectedreadyParcelList.reduce(
        (prev, cur) => prev + (cur.weight || 0),
        0
      );
    },
    dutyTax(): number {
      if (this.totalPrice - this.dutyFreeLimit > 0)
        return (this.totalPrice - this.dutyFreeLimit) * 0.1;
      else return 0;
    },
    additionalTax(): number {
      if (this.totalPrice - this.freeTaxLimit > 0)
        return (this.totalPrice - this.freeTaxLimit + this.dutyTax) * 0.2;
      else return 0;
    },
    customsServiceResult(): number {
      if (this.dutyTax + this.additionalTax > 0)
        return this.dutyTax + this.additionalTax + this.customsService;
      else return 0;
    },
  },
  created() {
    this.getCountryToRates();
    if (this.popupbody) {
      this.selectedParcels.push(this.popupbody.Package_id);
    }
    if (!this.isCombineInfoAccepted) {
      this.isCombineInfoOpened = true;
    }
  },
  mounted() {
    this.$watch(
      "parcelList",
      (newItems: any) => {
        let newParcels = sortedArObjsDate(
          newItems.filter(
            (p: any) => 5020 === +p.p_State_id || 5020 === +p.State_id
          ),
          "pr_290"
        );
        if (this.popupbody) {
          newParcels = [
            this.popupbody,
            ...newParcels.filter(
              (p: any) =>
                p.Package_id !== this.popupbody.Package_id &&
                p.pr_587 === this.popupbody.pr_587
            ),
          ];
        }
        this.readyParcelList = newParcels.map((p: any) => ({
          name: p.pr331,
          price: p.pr249,
          count: p.pr256 || 1,
          weight: p.pr325,
          image: p.PathToPhoto,
          imageSrc: getMediaLink(p.PathToPhoto),
          ...p,
        }));
      },
      { immediate: true }
    );
  },
  methods: {
    onClose() {
      this.$root.$emit(eventsRegister.closePopup);
      this.isNameSubmited = false;
    },
    onCloseName() {
      this.isNameSubmited = false;
    },
    combinePackages() {
      this.isNameSubmited = true;

      if (this.combinedName) {
        const data = {
          filters: [
            ...this.selectedParcels.map((id: any) => ({
              sKey: "Package_in",
              condition: `${id}`,
            })),
            { sKey: "Description", condition: this.combinedName },
          ],
        };

        combineParcel(
          data,
          (responseData: any) => {
            this.$root.$emit(eventsRegister.updateParcels, () => {
              const parcelId = responseData.res.Package_id;
              this.$router.push({
                name: "ParcelInfo",
                params: { parcelId },
              });
              this.$root.$emit(eventsRegister.closePopup);
              this.$root.$emit(eventsRegister.addMessage, {
                type: "success",
                message: this.$t("combineSuccess"),
              });
            });
            this.isNameSubmited = false;
          },
          () => (this.isNameSubmited = false)
        );
      }
    },
    toggleParcel(item: any) {
      if (this.selectedParcels.includes(item.Package_id)) {
        this.selectedParcels = this.selectedParcels.filter(
          (p: any) => p !== item.Package_id
        );
      } else {
        if (this.isFilled) {
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("combineErrorLimit"),
          });
        } else if (this.totalWeight + item.weight > this.maxWeight) {
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("combineErrorWeight"),
          });
        } else {
          this.selectedParcels = [...this.selectedParcels, item.Package_id];
        }
      }
    },
    parcelIsDisabled(item: any): boolean {
      return (
        (this.totalWeight + item.weight > this.maxWeight || this.isFilled) &&
        !this.selectedParcels.includes(item.Package_id)
      );
    },
    createName() {
      if (this.isValid) {
        this.isCombineNameOpened = true;
      } else {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("combineErrorMin"),
        });
      }
    },
    getCountryToRates() {
      createRequest(
        "objList",
        {
          // requestData
          ClassList: [{ Class_id: 21100, TypeLoad: 0, needLinkObject: 0 }],
          PropertyList: [
            { Class_id: 21100, Property_id: 503 }, // Безпошлинный лимит
            { Class_id: 21100, Property_id: 398 }, // Не облагается НДС
            { Class_id: 21100, Property_id: 504 }, // Услуга таможенного оформления
          ],
          Filter: [
            {
              Class_id: 21100,
              Filter_id: 1,
              Node_id: 1,
              Property_id: -1,
              Operator_id: "=",
              Value: 285,
            }, // Ukraine
          ],
        },
        (responseData: any) => {
          // Success callback
          // { N1021100__1: [{ CalcName: "Ukraine", Object_id: 285, State_id: -5001, pr398: "112",pr503: "168", pr504:"5" }] }
          if ("N1021100__1" in responseData) {
            const countryData = responseData["N1021100__1"][0];
            this.dutyFreeLimit = parseFloat(countryData.pr503);
            this.freeTaxLimit = parseFloat(countryData.pr398);
            this.customsService = parseFloat(countryData.pr504);
          }
        },
        () => true // server error callback
      );
    },
    onAccept() {
      window.localStorage.setItem("isCombineInfoAccepted", "true");
      this.isCombineInfoOpened = false;
    },
  },
});
