





































































































































































































































/* eslint-disable */
// @ts-nocheck
import Vue from "vue";
import { Prop } from "vue/types/options";

import eventsRegister from "@/services/events-register";
import { createRequest } from "@/services/api-connector";

import InputText from "../controls/InputText.vue";
import Balloon from "@/components/Balloon.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: { InputText, Balloon, EasyInput },
  props: {
    popupbody: Object as Prop<any>,
  },
  data() {
    return {
      eventsRegister,

      parcelDivideMode: 1, // 1 - price, 2 - qty
      parcelDividePrice: 150,
      productsQty: 1, // products in one parcel
      isDivideType1InfoVisible: false,
      isDivideType2InfoVisible: false,
      isParcelProcessing: false,
      isDivideParcelsConfirmActive: false,
      isDivideParcelsSuccessActive: false,
      isDivideParcelsFailActive: false,

      productsListAllLen: 0,
    };
  },

  created() {
    // set productListLen
    if (this.popupbody.productsListAllLen)
      this.productsListAllLen = this.popupbody.productsListAllLen;
    else this.fetchPackageData(this.popupbody["Package_id"]); // load package productList

    if (this.userIsFromUSA) {
      this.parcelDividePrice = 800;
    }
  },

  computed: {
    ...mapGetters("user", ["user"]),
    userIsFromUSA() {
      // @ts-ignore
      return this.user.pr201 === 284;
    },
    parcelsCountFuture(): number {
      let parcel = this.popupbody;
      let qtyFuture = 0;
      if (this.parcelDivideMode === 1) {
        // price
        if (!this.parcelDividePrice) return 0;
        if (parcel.pr249 / this.parcelDividePrice <= 1) return 0;
        if (parcel.pr249 % this.parcelDividePrice)
          qtyFuture = Math.floor(parcel.pr249 / this.parcelDividePrice) + 1;
        else qtyFuture = parcel.pr249 / this.parcelDividePrice;
      } else {
        // products per parcel
        if (!this.productsQty) return 0;
        if (this.productsListAllLen / this.productsQty <= 1) return 0;
        if (this.productsListAllLen % this.productsQty)
          qtyFuture =
            Math.floor(this.productsListAllLen / this.productsQty) + 1;
        else qtyFuture = this.productsListAllLen / this.productsQty;
      }
      return qtyFuture ? qtyFuture : 0;
    },
  },

  methods: {
    divideParcelClicked() {
      let requestDivideValue: any;
      if (this.parcelDivideMode === 1)
        requestDivideValue = this.parcelDividePrice;
      else requestDivideValue = this.productsQty;
      // @ts-ignore
      this.isParcelProcessing = true;
      createRequest(
        "divideParcel",
        {
          filters: [
            { sKey: "Package_id", condition: this.popupbody["Package_id"] },
            // @ts-ignore
            { sKey: "Mode", condition: this.parcelDivideMode },
            { sKey: "N", condition: requestDivideValue },
          ],
        },
        (responseData: any) => {
          // Success callback {"res": {"resQty": 2} }
          if ("res" in responseData && responseData["res"]["resQty"] > 1) {
            this.$root.$emit(eventsRegister.updateParcels);
            // @ts-ignore
            this.isDivideParcelsSuccessActive = true;
            // @ts-ignore
          } else this.isDivideParcelsFailActive = true;
          // @ts-ignore
          this.isParcelProcessing = false;
        },
        () => {
          // server error callback
          // @ts-ignore
          this.isParcelProcessing = false;
          // this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: lcl.serverError })
        }
      );
    },

    fetchPackageData(packageId: number) {
      // Get package details by packageId
      let requestData = {
        ClassList: [{ Class_id: 24800, TypeLoad: 1, needLinkObject: 1 }],
        PropertyList: [{ Class_id: 24800, Property_id: 477 }], // content
        PropertyDetailList: [
          { Class_id: 24800, PropertyMaster_id: 477, Property_id: -309 }, // id
          { Class_id: 24800, PropertyMaster_id: 477, Property_id: 256 }, // qty
        ],
        Filter: [
          {
            Class_id: 24800,
            Filter_id: 1,
            Node_id: 1,
            Property_id: -1,
            Operator_id: "=",
            Value: packageId,
          },
        ],
      };
      createRequest(
        "objList",
        requestData,
        (responseData: any) => {
          // Success callback
          if ("N3024800_0477" in responseData) {
            responseData["N3024800_0477"].forEach((contentItem: any) => {
              // @ts-ignore
              this.productsListAllLen += contentItem.pr256;
            });
          } else console.error("Package " + packageId + " not found");
          // this.isPackageLoaded = true
        },
        () => {} // error callback
      );
    },
  },
});
