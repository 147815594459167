





























































































/* eslint-disable */
import Vue from 'vue'

import eventsRegister from '@/services/events-register'

interface DialogI {
    'message': string
    'callback': any
}

export default Vue.extend({
    name: 'DialoqBox',
    data() {
        return {
            message: '',
            callbackFunc: null as any,

            isDialoqBoxShow: false
        }
    },

    created() {
        this.$root.$on(eventsRegister.showDialoq, (params: DialogI)=>{
            this.message = params.message  
            if (params.callback)
                this.callbackFunc = params.callback 
            this.isDialoqBoxShow = true         
        })
    },

    methods: {
        dialoqResult(isTrue: boolean) {
            if (this.callbackFunc)
                this.callbackFunc(isTrue)
            this.closeDialoqBox()
        },

        closeDialoqBox() {
            this.isDialoqBoxShow = false
            // Reset dialoqBox state
            this.message = ''
            this.callbackFunc = null
        }
    }
})
