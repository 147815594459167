





























































































































































































/* eslint-disable */
import Vue from "vue";
import { Prop } from "vue/types/options";

import eventsRegister from "@/services/events-register";
import { setAppState } from "../../services/data-mutator";
import { leftArrowSVG } from "@/services/svg";

import PopupBase from "./Popup-base.vue";
import PopupAddFunds from "./Popup-add-funds.vue";
import PopupParcelDivide from "./Popup-parcel-divide.vue";
import PopupTerms from "./Popup-terms.vue";
import PopupMessage from "./Popup-message.vue";
import PopupBonus from "./Popup-bonus.vue";
import PopupCombine from "./Popup-combine.vue";
import PopupInfo from './Popup-info.vue';
import { mapGetters, mapMutations } from "vuex";

interface PopupData {
  type: string;
  header: string;
  body: any;
  isModal: boolean;
}

export default Vue.extend({
  props: {
    appdata: Object as Prop<any>,
    appstate: Object as Prop<any>,
  },
  data() {
    return {
      leftArrowSVG,

      activePopupContent: "base",
      popupContent: {
        base: PopupBase,
        parcelDivide: PopupParcelDivide,
        addFunds: PopupAddFunds,
        terms: PopupTerms,
        message: PopupMessage,
        bonus: PopupBonus,
        combine: PopupCombine,
        info: PopupInfo,
        //'carrierNew': PopupCarrierNew
      } as any,

      popupsList: [] as Array<PopupData>, // array of delayed popups. Show popups one after one

      popupTitle: "",
      popupbody: {} as any,
      isModal: false,
      isPopupShow: false,

      paddingForSafari: false,
    };
  },
  computed: {
    ...mapGetters("root", ["isPopupActive"]),
  },

  created() {
    // Example usage:  $root.$emit(eventsRegister.showPopup, {header: 'Cargo track', body: cargoItem.pr294, type: 'track'})
    this.$root.$on(eventsRegister.showPopup, (popupDataObj: PopupData) => {
      this.popupsList.push(popupDataObj);
      if (this.isPopupActive)
        // current popup is visible. Add popup to list (query), close current popup and show new popup
        this.onNextPopupAdded();
      // current popup is empty. Show new popup
      else this.setActivePopup(popupDataObj);
    });

    // Close popup global event
    this.$root.$on(eventsRegister.closePopup, this.closePopup);
  },

  methods: {
    ...mapMutations("root", ["setPopupActive"]),
    setActivePopup(popupDataObj: PopupData) {
      this.popupTitle = popupDataObj.header;
      this.popupbody = popupDataObj.body;
      this.activePopupContent = popupDataObj.type;
      this.isModal = popupDataObj.isModal ? true : false;
      this.setPopupActive(true);
    },

    closePopup() {
      this.setPopupActive(false);
      let self = this;
      // Reset popup content by timeout. Prevent empty popup state if popup animation is playing
      setTimeout(() => {
        // self.activePopupContent = 'base'
        let lastIdx = self.popupsList.length - 1;
        self.popupsList.splice(lastIdx, 1);
        if (this.popupsList.length)
          self.setActivePopup(self.popupsList[lastIdx - 1]);
        // setAppState('isPopupActive', false)
      }, 500);
    },

    onNextPopupAdded() {
      // this.isPopupShow = false
      this.setPopupActive(false);
      let self = this;
      setTimeout(() => {
        // self.activePopupContent = 'base'
        let lastIdx = self.popupsList.length - 1;
        self.setActivePopup(self.popupsList[lastIdx]);
        // this.isPopupShow = true
        this.setPopupActive(true);
      }, 700);
    },

    closePopupClicked() {
      if (this.isModal) return false;
      this.closePopup();
    },
  },
});
