












































import Vue from "vue";
import { Prop } from "vue/types/options";
import eventsRegister from "@/services/events-register";
import appState from "../../store/appstate";

export default Vue.extend({
  name: "PopupBonus",
  props: {
    popupbody: Object as Prop<any>,
  },
  data() {
    return {
      appState,
    };
  },
  methods: {
    onClose() {
      this.$root.$emit(eventsRegister.closePopup);
    },
  },
});
