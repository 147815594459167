






























































































































































































































































































/* eslint-disable */
import Vue from 'vue'
import { Prop } from 'vue/types/options'
import { setRoute } from "../../services/route"
import { setAppState } from '../../services/data-mutator'
import eventsRegister from '@/services/events-register'
import { createRequest, archiveFiles } from "../../services/api-connector"
import appState from '../../store/appstate'
import { openExternal } from '../../utils/utils'
import { mapGetters, mapMutations } from 'vuex'

const apiURL = process.env.VUE_APP_API_URL // https://dev-wp.utec-l.com:9005
const imgFolderPath = apiURL + '/media/'
const docFolderPath = apiURL + '/ForUserDocs/'

enum TypeMessage {
    Info = 11631,
    Question = 11632,
    YesNo = 11633
}

let appEl: any 
let curOffsetY = 0
let prevTouchY = 0
let curTouchY = 0
let _startY: any

export default Vue.extend({
    name: 'PopupMessage',
    
    props: {
        popupbody: Object as Prop<any>
    },

    data() {
        return {
            TypeMessage,imgFolderPath,docFolderPath,eventsRegister,
            appState,
            messageObj: {},
            orderDesc: '',
            fileList: [] as Array<object>,
            galleryPhoto: [] as Array<object>,
            clientText: '',
            isClientTextShow: false,
            isAnswerYes: null as null | number, // 0 | 1
            isUnderstand: null as null | number, // 0 | 1,
            isDataReady: false,
            isSmallScreen: false,
            isBtnsFixed: false,
            isSliderFullScreen: false,
            swiperFullScreen: {} as any,
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 16,
                centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination'
                },
            // Some Swiper option/callback...
            },
            swiperOptionsFull: {
                slidesPerView: 'auto', 
                spaceBetween: 20,
                centeredSlides: true
            }
        }
    },

    computed: {
        ...mapGetters("root", ["openMessages"]),
        isFieldsEmpty(): boolean { 
            if(this.clientText || this.fileList.length) return false
            return true
        }
    },

    created() {
        this.fetchMessageData()
    },

    mounted() {
        // not show box img if small screen
        if(screen.width < 375 && screen.height < 667) this.isSmallScreen = true 

        appEl = document.getElementById('popupMessageWrap')
        appEl.addEventListener('touchstart', this.onTouchStart, {passive: true})
        appEl.addEventListener('touchmove', this.onTouchMove, {passive: true} )
        appEl.addEventListener('touchend', this.onTouchEnd, {passive: true})

        setAppState('isPopupMessageLast', false)
        if(this.openMessages.length === 1) setAppState('isPopupMessageLast', true)
    },

    destroyed() {
        appEl.removeEventListener('touchstart', this.onTouchStart)
        appEl.removeEventListener('touchmove', this.onTouchMove)
        appEl.removeEventListener('touchend', this.onTouchEnd)
    },


    methods: {
        ...mapMutations("root", ["removeOpenMessage"]),
        onOpenLink(link: string) {
            openExternal(link);
        },
        onTouchStart(e: any): any {
            if(!this.isSliderFullScreen) return false

            if (window.scrollY <=0 ){               
                _startY = e.touches[0].clientY
                prevTouchY = _startY
            }
        },

        onTouchMove(e: any): any {
            if(!this.isSliderFullScreen) return false

            curTouchY = e.touches[0].clientY
            curOffsetY = curTouchY -_startY

            if(curOffsetY > 80) this.closeSlide()
        },

        onTouchEnd(e: any): any {
            if(!this.isSliderFullScreen) return false
        },

        onSlideFullScreen(index: number, reallyIndex: number | null): void {
            this.isSliderFullScreen = true
            this.swiperFullScreen.slideTo(index++, 1000, false)
        },

        onSwiperFullReady(swiperFull: any) {
            this.swiperFullScreen = swiperFull
        },

        closeSlide(): void {
            this.isSliderFullScreen = false
        },

        saveClientMessage(): void {
            this.chekContentHeight()
            this.isClientTextShow = false
            this.scrollElementToEnd('.popupMessage')
        },

        scrollElementToEnd(element: string, delay: number = 20): void {
            setTimeout(() => {
                let chatHistoryHtml: any = document.querySelector(element)
                chatHistoryHtml.scrollTop = chatHistoryHtml.scrollHeight
            }, delay)
        },

        chekContentHeight(): void {
            setTimeout(() => {
                let el = document.querySelector('.popupMessageWrap') as HTMLElement
                let contentHeight = el.clientHeight
                if(contentHeight <= screen.height) this.isBtnsFixed = false
                else this.isBtnsFixed = true // long content
                    
                // set padding if long content and btn fixed
                let scrollEl = document.querySelector('.popupMessage') as HTMLElement
                let divEl = this.$refs.btnGroup as any
                if(divEl) {
                    if(!this.isBtnsFixed) scrollEl.style.paddingBottom = '0'
                    else scrollEl.style.paddingBottom = divEl.clientHeight + 'px'
                }
            }, 100)
        },

        addMessage(): void {
            this.isClientTextShow = true
            let el = document.querySelector('.popupMessageClientText') as HTMLElement
            setTimeout(() => {
                el.focus()
            },400) // focus after animation. Fix EASY-625
            // set cursor position
            if(this.clientText.length) this.placeCaretAtEnd(el)
        },

        placeCaretAtEnd(el: HTMLElement): void {
            let bodyEl = document.body as any
            if (typeof window.getSelection != "undefined"
                    && typeof document.createRange != "undefined") {
                var range = document.createRange()
                range.selectNodeContents(el)
                range.collapse(false)
                var sel = window.getSelection() as any
                sel.removeAllRanges()
                sel.addRange(range)
            } else if (typeof bodyEl.createTextRange != "undefined") {
                var textRange = bodyEl.createTextRange()
                textRange.moveToElementText(el)
                textRange.collapse(false)
                textRange.select()
            }
        },

        onEditText(e: any): void {
            let val = e.target.innerText
            this.clientText = val
        },

        parseLineBreakAsHtml(str = ''): string {
            if(str.length) return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
            else return ''
        },
        
        onYesNoClick(answer: number): void {
            this.isAnswerYes = answer
            this.sendMessage()
        },

        onUnderstandClick(): void {
            this.isUnderstand = 1
            this.sendMessage()
        },

        substrText(text: string, maxLength: number = 26): string {
            if(text.length > maxLength) return text.substr(0, maxLength) + '...'
            return text
        },

        getCorrectLink(url: string): string { // change domain.com to https://doamin.com
            // check transfer protocol in ulr
            if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) return url
            else return 'https://'+ url
        },

        closeMessagePopup(): void {
            this.removeOpenMessage(this.messageObj['Object_id'])
            this.$root.$emit(eventsRegister.closePopup)
        },

        addFile(): void {
            let input = document.createElement("INPUT")
            input.setAttribute("type", "file")
            input.setAttribute("id", `inputFile${this.fileList.length + 1}`)
            input.setAttribute("name", `-${this.fileList.length + 1}`)
            input.setAttribute("accept", "image/jpeg,image/jpg,image/png,image/gif,image/ico")
            input.setAttribute("hidden", "true")
            input.addEventListener('change', (e: any) => {
                let file = e.target.files[0]
                file.id = this.fileList.length + 1
                this.fileList.push(file)
                this.scrollElementToEnd('.popupMessage', 100)
            })
            let form = document.getElementById('filesForm') as HTMLElement
            form.appendChild(input)
            input.click()
        },

        resetFile(index: number): void {
            let input = document.getElementById(`inputFile${index + 1}`) as any
            input.value = ""
            input.remove()
            this.fileList.splice(index, 1)
        },

        saveFiles(objectId: number): void {
            let queryParams = {
                propertyId: 575,
                objectId: objectId,
                classId: 24520
            }

            let filesForm: any = this.$refs.filesForm
            let form = new FormData( filesForm )

            archiveFiles(queryParams, form,
                (responseData: any)=>{ // Success callback
                    this.fileList = []
                    this.succesSaveMassege(objectId)                
                },
                (responseData: any)=>{ // server error callback
                    this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: responseData, autoClose: false })
                }, ()=>{ // error connection
                    this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: "error connection", autoClose: false })
                }
            )
        },

        succesSaveMassege(objectId: number): void {
            this.removeOpenMessage(objectId)
            this.$root.$emit(eventsRegister.closePopup)
            this.$root.$emit(eventsRegister.updateLastUpdates)
            setRoute('home')
        },

        sendViewDate(): void {
            let objectId = this.messageObj['Object_id']
            let requestData = {
                "tmp_object_list": [{
                    "OBJECT_ID": objectId,
                    "Class_id": 24520,
                    "ObjectDB_id": objectId
                }],
                "tmp_object_property_scalar": [] as Array<any>
            }

            requestData.tmp_object_property_scalar.push({OBJECT_ID: objectId, Property_id: 576, VALUE: new Date().toISOString()})

            createRequest(
                'objEdit',
                requestData,
                (responseData: any)=>{}, // Success callback
                ()=>{}
            ) 
        },

        sendMessage(): void {
            let objectId = this.messageObj['Object_id']
            let requestData = {
                "tmp_object_list": [{
                    "OBJECT_ID": objectId,
                    "Class_id": 24520,
                    "ObjectDB_id": objectId
                }],
                "tmp_object_property_binary": [] as Array<any>,
                "tmp_object_property_scalar": [] as Array<any>
            }
            if(this.messageObj['pr570'] === TypeMessage.Info && !this.isFieldsEmpty) this.isUnderstand = 1

            let objJson: object | string = {
                "isUnderstand": this.isUnderstand,
                "isAnswerYes": this.isAnswerYes,
                "text": this.clientText
            }
            objJson = JSON.stringify(objJson)
            requestData.tmp_object_property_binary.push({OBJECT_ID: objectId, Property_id: 574, TextVALUE: objJson}) 
            // if(this.viewTimeUtc) requestData.tmp_object_property_scalar.push({OBJECT_ID: objectId, Property_id: 576, VALUE: this.viewTimeUtc})
            if(this.fileList.length){
                let file: any = this.fileList[0]
                requestData.tmp_object_property_scalar.push({OBJECT_ID: objectId, Property_id: 575, VALUE: file.name})
            }

            createRequest(
                'objEdit',
                requestData,
                (responseData: any)=>{ // Success callback
                    if(this.fileList.length) this.saveFiles(objectId)
                    else this.succesSaveMassege(objectId)
                },
                ()=>{}
            )            
        },

        fetchMessageData(): void {
            let requestData = {
                "ClassList": [{"Class_id": 24520,"TypeLoad":31, "needLinkObject":1}],
                "Filter": [{"Class_id":24520, "Filter_id":1, "Node_id":1, "Property_id":-1, "Operator_id":"=", "Value": this.popupbody.objId}]
            }
            createRequest(
                'objList',
                requestData,
                (responseData: any)=>{ // Success callback
                    if('N1024520__1' in responseData){
                        this.messageObj = Object.assign({}, responseData['N1024520__1'][0])
                        if(this.messageObj['pr574']) this.messageObj['pr574'] = JSON.parse(this.messageObj['pr574'])
                        if(this.popupbody['type'] === "shopping") this.fetchShoppingData()
                        else if(this.popupbody['type'] === "packages") this.fetchPackageData()
                        if(!this.messageObj['pr576']) this.sendViewDate()        
                    }
                    if('N3024520__599' in responseData) this.galleryPhoto = responseData['N3024520__599']
                },
                ()=>{} // error callback 
            )
        },

        fetchShoppingData(): void {
            let requestData = {
                "ClassList": [{"Class_id": 24640,"TypeLoad":0, "needLinkObject":0}],
                "PropertyList": [{"Class_id": 24640, "Property_id": 474} ], // Описание таможенной категории товара
                "Filter": [
                    {"Class_id":24640, "Filter_id":1, "Node_id":1, "Property_id": -1, "Operator_id":"=", "Value": this.messageObj['pr585']}
                ]
            }
             createRequest(
                'objList',
                requestData,
                (responseData: any)=>{ // Success callback
                    if('N1024640__1' in responseData) this.orderDesc = responseData['N1024640__1'][0]['pr474'] || ''
                    this.isDataReady = true
                    this.chekContentHeight()
                },
                ()=>{} // error callback    
            )

        },

        fetchPackageData(): void {
            let requestData = {
                "ClassList": [{"Class_id": 24800,"TypeLoad":0, "needLinkObject":0}],
                "PropertyList": [{"Class_id": 24800, "Property_id": 331} ], // Описание товара
                "Filter": [
                    {"Class_id":24800, "Filter_id":1, "Node_id":1, "Property_id": -1, "Operator_id":"=", "Value": this.messageObj['pr585']}
                ]
            }
             createRequest(
                'objList',
                requestData,
                (responseData: any)=>{ // Success callback
                    if('N1024800__1' in responseData) this.orderDesc = responseData['N1024800__1'][0]['pr331'] || ''
                    this.isDataReady = true
                    this.chekContentHeight()
                },
                ()=>{} // not auth callback
            )
        }

    }
})
